
<script>
import { Line } from 'vue-chartjs';
export default {
    extends: Line,
    props:["chartData"],
    data(){
        return{
            option:{
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true
                  },
                  gridLines: {
                    display: true
                  }
                }],
                xAxes: [ {
                  gridLines: {
                    display: false
                  }
                }]
              },
              legend: {
                display: true
              },
        }
    }
    },
    mounted() {
        console.table(this.chartData)
        let months = new Array();
         let Labels = new Array();
         let counter = new Array();
        // this.chartdata.forEach(element => {
        //        months.push(element.keys);
        //        counter.push(element.values);
        //        });
        // :Object.keys(this.chartData)
        this.renderChart({
               labels:['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
               datasets: [{
                  label: 'Property Enquiries',
                  backgroundColor: '#031d6b',
                  borderColor: '#0168fa',
                  data:Object.values(this.chartData)
            }]
        },{responsive: true, maintainAspectRatio: false})
    }
}
</script>

<style lang="css" scoped>
</style>
