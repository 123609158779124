import { ApiSource } from "../services/apiService";
const api = new ApiSource();
const state = {
  info:{
		total_properties:'',
		total_plots:'',
		total_requests:'',
		total_enquiries:'',
        enquiries_chart:'',
        request_chart:''
	},
};
const mutations = {

  GET_DASHBOARD_INFO(state, payload) {
    // console.log(payload['total_properties'])
    state.info.total_properties = payload["total_properties"];
    state.info.total_plots = payload["total_plots"];
    state.info.total_requests = payload["total_requests"];
    state.info.total_enquiries = payload["total_enquiries"];
    state.info.enquiries_chart = payload["enquiries_chart"];
    state.info.request_chart = payload["request_chart"];
  }
};
const actions = {
  dashboardInfoAction({ commit }) {
    api.developer_dashboard().then(res => {
      let count = res.data.data;
      // console.log(res.data.data['total_properties']);
      commit("GET_DASHBOARD_INFO", count);
    });
    //
  },

};
const getters = {
  get_dashboard_info(state) {
    // console.log('general : '+state.country_list)
    return state.info;
  }
};
const dashboardmodule = {
  state,
  mutations,
  actions,
  getters
};
export default dashboardmodule;
