<template lang="html">
    <div class="">
        <div
            class="modal fade"
            :id="id"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel6"
            aria-hidden="true">
            <div  class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content tx-14">
                    <div class="modal-header">
                        <h6 class="modal-title text-center" id="exampleModalLabel6" >{{header}}</h6>

                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <slot name="modal-body"></slot>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:["id","header"]
}
</script>

<style lang="css" scoped>
</style>
