<template>
	<div>
			<!-- view and edit -->
		<div >
			<div class="container pd-x-0">
				<div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
					<div>
						<breadcrum first="Properties" second="View Plot"></breadcrum>
						<h4 class="mg-b-0 tx-spacing--1"></h4>
					</div>
					<div class="d-none d-md-block">
						<router-link tag="button" :to="{name:'plottable'}"  class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
							<i  class="fa fa-eye wd-10 mg-r-5"></i> View List
						</router-link>

						<button class="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5" v-if="vieworedit" @click.prevent="toggleView()"><i  class="fa fa-eye wd-10 mg-r-5"></i> View {{plot.plot_name}}</button>
						<button class="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5" v-if="!vieworedit" @click.prevent="toggleView()"><i  class="fa fa-eye wd-10 mg-r-5"></i> Edit {{plot.plot_name}}</button>
					</div>
				</div>
				<div v-if="!vieworedit">
					<div class="card card-body">
						<div class="row">
							<div class="col-md-2 "></div>
							<div class="col-md-8 ">
								<lingallery :iid.sync="currentId" :width="width" :height="height" :items="photoitem"/>
							</div>
							<div class="col-md-2 "></div>
						</div>
						<div style="height:30px"></div>
						<hr>
						<div class="row" >

								<div class="col-md-6">
									<div class="nh-single-page-property-name">{{plot.plot_name}}</div>
									<div class="nh-single-page-property-address mt-1"> <i class="fa fa-location"></i> {{plot.plot_address}}
									</div>
									<div class="nh-single-page-property-address mt-1"> <i class="fa fa-location"></i>
										{{plot.plot_size}} SQM
									</div>

								</div>
								<div class="col-md-6">
									<div class="nh-single-page-price position-relative"><span id="nh-pick-price"> {{plot.plot_price_raw | currency}}</span></div>
									<!-- {{property.currency_symbol}} -->
								</div>


						</div>

						<!-- proeprty description -->
						<div class="row">
							<div class=" nh-property-description-container" style="max-height:300px;width:100%; overflow-y:scroll;padding:20px;">
								<div class="nh-property-description-title">Description</div>
								<div class="nh-property-description-content">
									<div v-html="plot.plot_description"></div>
								</div>
							</div>
						</div>
						<!-- property Amenities -->
						<div class="row">
							<section id="nh-property-amenities mt-2">
								<div class="nh-property-amenities-container">
									<div class="tab-content" id="nh-property-amenities-TabContent">
										<div class="tab-pane fade show active" id="nh-amenities" role="tabpanel" aria-labelledby="nh-amenities-tab">
											<div class="nh-amenities-title" >Amenities</div>
											<div class="nh-amenities-lists-container">
												<ul class="list-unstyled d-flex flex-wrap">
													<li v-for="(a, i) in currentAmenity" :key="i">
														<img class="nh-amenities-icon" :src="imageUrl+a.amenity_icon" :alt="a.amenity_name">{{a.amenity_name}}
													</li>
												</ul>
											</div>
										</div>
									</div>
							</div>
							</section>
						</div>

					</div>
				</div>
				<div v-if="vieworedit">
					<ul class="nav nav-line nav-justified nav-line" id="myTab" role="tablist">
						<li class="nav-item">
							<a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Plot Info</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Amenities</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" id="gallery-tab" data-toggle="tab" href="#gallery" role="tab" aria-controls="gallery" aria-selected="false">Gallery</a>
						</li>
					</ul>
					<div class="tab-content bd bd-gray-300 bd-t-0 pd-20" id="myTabContent">
						<div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
							<form @submit.prevent="updateInfo()" method="post">
								<div class="row">
										<div class="d-sm-flex align-items-center  mg-b-20 mg-lg-b-25 float-right">
											<button type="submit" class="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5 "><i  class="fa fa-cloud wd-10 mg-r-5"></i> Update info</button>
										</div>
								</div>
								<div class="row">
									<div class="col-md-4">
										<div class="form-group">
											<label for="name">Plot Name</label>
											<input type="text" class="form-control" placeholder="property name" v-model="plot.plot_name"
											:class="{ 'is-invalid': $v.form.plot_name.$error }">
												<div v-if="!$v.form.plot_name.required" class="invalid-feedback">Plot name is required</div>
										</div>
									</div>
									<div class="col-md-4">
										<div class="form-group">
											<label for="t">select currency</label>
											<div class="input-group">
												<div class="input-group-prepend">

												</div>
												<select name id="currencyid" class="form-control" v-model="plot.base_currency" @change="updateSymbol()" :class="{ 'is-invalid': $v.form.base_currency.$error }" >
														<option :value="plot.base_currency" selected>{{plot.base_currency}}</option>
														<option :value="c.currency_code+'+'+c.currency_symbol" v-for="(c , index) in currency" :key="index"  >{{c.currency_code}}</option>
													</select>
												<input type="text"  v-model="plot.currency_symbol" class="form-control" disabled id="currency_symbol" :class="{ 'is-invalid': $v.form.base_currency.$error }"/>
												<div v-if="!$v.form.base_currency.required" class="invalid-feedback">select plot base currency</div>
											</div>
										</div>
									</div>
									<div class="col-md-4">
										<div class="form-group">
										<label for="name">Price</label>
										<input type="text" class="form-control" placeholder="property square metre" v-model="plot.plot_price_raw"
											:class="{ 'is-invalid': $v.form.plot_price_raw.$error }">
										<div v-if="!$v.form.plot_price_raw.required" class="invalid-feedback">Price is required</div>
											</div>
									</div>
									<div class="col-md-4">
											<div class="form-group">
													<label for="country" class="form-control-label">Country <span class="tx-danger">*</span></label>
										<select class="form-control  cloneme" name="property_country" id="country"  v-model="plot.plot_country" 	:class="{ 'is-invalid': $v.form.plot_country.$error }">
											<option label="Choose one"></option>
											<option v-for="(c, index) in country" :key="index" :value="c.name">{{c.name}}</option>
										</select>
										<div v-if="!$v.form.plot_country.required" class="invalid-feedback">plot country is required</div>
											</div>
									</div>
									<div  class="col-md-4">
										<div class="form-group">
											<label class="form-control-label">Plot Full Address: <span class="tx-danger">*</span></label>
										<input id="autocomplete" class="form-control" name="property_address" placeholder="Enter plot address"  type="text" required v-model="plot.plot_address" :class="{ 'is-invalid': $v.form.plot_address.$error }">
										<div v-if="!$v.form.plot_address.required" class="invalid-feedback">plot full address is required</div>
										</div>

									</div>
									<div class="col-md-4">
										<div class="form-group">
											<label class="form-control-label">Property City<span class="tx-danger">*</span></label>
										<input class="form-control" name="property_city" type="text" id="locality" placeholder="Enter property city" required v-model="plot.plot_city" :class="{ 'is-invalid': $v.form.plot_city.$error }">
										<div v-if="!$v.form.plot_city.required" class="invalid-feedback">plot city is required</div>
										</div>
									</div>
									<div class="col-md-4">
											<div class="form-group">
													<label class="form-control-label">Plot State<span class="tx-danger">*</span></label>
										<input class="form-control" name="property_state" type="text" id="administrative_area_level_1" placeholder="Enter property state" required v-model="plot.plot_state" :class="{ 'is-invalid': $v.form.plot_state.$error }">
										<div v-if="!$v.form.plot_state.required" class="invalid-feedback">plot state is required</div>
											</div>
									</div>
									<div class="col-md-4">
										<div class="form-group">
													<label class="form-control-label">Plot Neighborhood <span class="tx-danger">*</span></label>
										<input class="form-control" name="property_neighborhood" placeholder="eg VI" type="text" v-model="plot.plot_neigborhood" :class="{ 'is-invalid': $v.form.plot_neigborhood.$error }" >
										<div v-if="!$v.form.plot_neigborhood.required" class="invalid-feedback">property neighborhood is required</div>

										</div>

									</div>
									<div class="col-md-4">
											<div class="form-group">
											<label for="name">SQM</label>
											<input type="text" class="form-control" placeholder="property square metre" v-model="plot.plot_size"
												:class="{ 'is-invalid': $v.form.plot_size.$error }">
											<div v-if="!$v.form.plot_size.required" class="invalid-feedback">Plot size is required</div>
											</div>
									</div>
									<div class="col-md-4">
										<div class="form-group">
											<label class="form-control-label">Payment plan<span class="tx-danger">*</span></label>
											<select class="form-control"  name=" payment_option" required v-model="plot.payment_option"
											:class="{ 'is-invalid':$v.form.payment_option.$error }">
												<option label="Choose one" ></option>
												<option value="1 year" >One year</option>
												<option value="6 months" >Six Month</option>

											</select>
											<div v-if="!$v.form.payment_option.required " class="invalid-feedback">select property finance option</div>
										</div>
									</div>
									<div class="col-md-4">
										<div class="form-group">
											<label class="form-control-label">Building Permit<span class="tx-danger">*</span></label>
											<select class="form-control" name="building_permit" required v-model="plot.building_permit"
												:class="{ 'is-invalid': $v.form.building_permit.$error }">
												<!-- <option :value ></option> -->
												<option value="1">Yes</option>
												<option value="0">No</option>
											</select>
												<div v-if="!$v.form.building_permit.required" class="invalid-feedback">selection is required</div>
										</div>
									</div>
									<div class="col-md-4">
										<div class="form-group">
											<label class="form-control-label">Sales Status<span class="tx-danger">*</span></label>
											<select class="form-control" name="building_permit" required v-model="plot.plot_issold"
												:class="{ 'is-invalid': $v.form.plot_issold.$error }">
												<option value="1">Sold</option>
												<option value="0">Available</option>
											</select>
												<div v-if="!$v.form.plot_issold.required" class="invalid-feedback">selection is required</div>
										</div>
									</div>
									<div class="col-md-12">
											<label class="form-control-label">Property documents<span class="tx-danger">*</span></label>
											<div class="row">
												<div class="col-md-4" v-for="(a, index) in titledoc" :key="index">
														<div class="custom-control custom-radio">
															<input type="radio" :id="a.title" name="customRadio" class="custom-control-input" v-model="plot.title_docs" :value="a.title" :class="{ 'is-invalid': $v.form.title_docs.$error }"
															:checked="plot.title_docs==a.title">
															<label class="custom-control-label" :for="a.title" >{{a.title}}</label>
														</div>
														<div v-if="!$v.form.title_docs.required" class="invalid-feedback">selection is required</div>
												</div>
											</div>
									</div>
								</div>
							</form>
						</div>

						<div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
							<div class="row">
									<div class=" col-md-3" v-for="(a, index) in amenity" :key="index">
											<div class="form-group">
												<div class="custom-control custom-checkbox" >
														<input v-for="(b, i) in plot.amenities" :key="i" :checked="b.amenityid==a.id" type="checkbox" class="custom-control-input" name="amenities[]" :id="a.id" :value="a.id" v-model="plot.plot_amenity"
														@change="onAmenityChange(b.amenityid, $event)"  >
														<label class="custom-control-label" :for="a.id">{{a.name}}</label>
												</div>
											</div>
									</div>
							</div>

						</div>
						<div class="tab-pane fade" id="gallery" role="tabpanel" aria-labelledby="gallery-tab">
							<div class="row">
							 <gallery :images="plot.plot_photo" :index="index" @close="index = null"></gallery>
								<div
									class="image"
									v-for="(image, imageIndex) in plot.plot_photo"
									:key="imageIndex"
									@mouseover="mouseOver"
									:style="{ backgroundImage: 'url(' +imageUrl+ image.filename + ')', width: '150px', height: '150px' }"
								>
								<i class="fa fa-trash padme" @click="deletePropertyImage(image.id)" ></i>
									<!-- <i class="fa fa-trash padme" v-if="showDeleteIcon" ></i> -->
								</div>
								<div>
									<form>
									<vue-upload-multiple-image
										@upload-success="uploadImageSuccess"
										@before-remove="beforeRemove"
										@edit-image="editImage"
										dragText="Click to add images"
										:browseText="browseText"
										primaryText="Active"
									popupText="Your Total gallery files should not exceed 6"
										markIsPrimaryText="File"
										accept="image/jpeg,image/png,image/jpg"
										:max-image="maxImage"

										@limit-exceeded="exceedImageLimit"></vue-upload-multiple-image>

									<button type="submit" class="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-10 mg-r-10 mg-t-10 " @click.prevent="addPlotImage"><i  class="fa fa-cloud wd-10 mg-r-5"></i> Upload Files</button>
									</form>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>
<script>
 import { required, email, minLength, sameAs,requiredUnless,requiredIf } from "vuelidate/lib/validators";
	import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
	import {propertyMixin} from '../../../mixins/properties'
	import Lingallery from 'lingallery';
export default {
	inject: [ "eventBus", "restApi","imgUrl"],
		mixins:[propertyMixin],
		components:{Lingallery},
	data() {
		return {
		width: 600,
		height: 300,
		 currentId: null,
		 imageitem:[],
			 editor: ClassicEditor,
			editorConfig: { },
			slug:this.$route.params.slug,
			selected_currency:'',
			editHometype:false,
			index: null,
			vieworedit:false,
			amenities:[],
			maxImage:parseInt(6),
				images:[],
			selectedFile:'',
			browseText:'Maximum of 6 files',
			showDeleteIcon:false,
			plotid:'',
			form:{
				base_currency:'',
				currency_symbol:'',
				plot_name:'',
				plot_size:'',
				plot_country:'',
				plot_state:'',
				plot_city:'',
				plot_neigborhood:'',
				plot_address:'',
				plot_description:'',
				plot_price_raw:'',
				plot_issold:'',
				title_docs:'',
				payment_option:'',
				building_permit:'',
			},
				currentAmenity:[],
		};
	},
	watch:{
		'$route'(to, from){
			this.slug = to.params.slug

		},
	},
	computed:{
		imageUrl(){
			console.log(this.imgUrl)
			return this.imgUrl;
		},
		plot(){
			let info = this.$store.getters.get_plot_details
			this.plotid = info.id;
			this.form.base_currency=info.base_currency;
			this.form.currency_symbol=info.currency_symbol;
			this.form.is_estate=info.is_estate;
			this.form.plot_name=info.plot_name;
			this.form.plot_size=info.plot_size;
			this.form.plot_price_raw=info.plot_price_raw;
			this.form.plot_country=info.plot_country;
			this.form.plot_state=info.plot_state;
			this.form.plot_city=info.plot_city;
			this.form.plot_neigborhood=info.plot_neigborhood;
			this.form.plot_address=info.plot_address;
			this.form.plot_description=info.plot_description;
			this.form.payment_option=info.payment_option;
			this.form.building_permit=info.building_permit;
			this.form.plot_issold = info.plot_issold;
			this.form.title_docs=info.title_docs
			this.currentAmenity = info.plot_amenity
			this.imageitem = info.plot_photo;
			return info;
		},
		photoitem(){
			let i =[];
			let url = this.imgUrl;
			// console.log(url)
			this.imageitem.map(function(k){
				console.log(url+k.filename)
				let p =  {
				src:url+k.filename,
				thumbnail: url+k.filename,
				caption: '',
				id: ''
				}
				i.push(p);
			})
			return i;
		},
		rules(){
				return{
						base_currency:{ required },
						plot_name:{ required },
						plot_size:{ required },
						plot_country: { required },
						plot_price_raw: { required },
						plot_state: { required },
						plot_city: { required },
						plot_neigborhood: { required },
						plot_address: { required },
						plot_description: { required },
						payment_option:{ required },
						building_permit:{ required },
						title_docs:{ required },
						plot_issold:{required}
				}
			}
		},
	validations() {
		return{
					form:this.rules,
		}
	},
	methods: {
		toggleView(){
			this.vieworedit = !this.vieworedit;
		},
		mouseOver(){
			this.showDeleteIcon = !this.showDeleteIcon
		},
		updateSymbol(){
			let s = this.plot.base_currency.split("+");
			this.plot.base_currency = s[0];
			this.plot.currency_symbol=s[1];

		},
		showPlot(slug){
			 this.$store.dispatch("showPlotBySlugAction",slug);
		},
		updateInfo(){
			console.log("üpdate activated")
			let data = {
				form:this.form,
				slug:this.slug
			}
			this.$v.form.$touch();
			  // if its still pending or an error is returned do not submit
			  if (this.$v.form.$pending || this.$v.form.$error){
				return;
				}
			this.$store.dispatch("updatePlotInfoAction", data).then((res)=>{
				this.showPlot(this.slug);
					this.$swal({
						title: 'Success',
						text: res.message,
						type: 'success',
						confirmButtonColor: '#10b759',
					}).then((result) => {
						if (result.value) {
								this.vieworedit = false;
						}
					})
			}).catch((err)=>{
							this.$swal({
						title: 'Oops',
						text: err,
						type: 'error',
					});
			})
		},
		onAmenityChange(value, $event){
		    if (!this.plot.amenities)
		      this.plot.amenities = []

		    const index = this.plot.amenities.findIndex(v => v == value)
		    const checked = $event.target.checked

		    if (checked && index < 0)
		      this.plot.amenities.push(value)
		    if (!checked && index >= 0)
		      this.plot.amenities.splice(index, 1)
  		},
		uploadImageSuccess(formData, index, fileList) {
   			fileList.forEach((r)=>{
   				this.images.push(r);
   			});
				this.images.pop();
       	},
        beforeRemove (index, done, fileList) {
         console.log('index', index, fileList)
         var r = confirm("remove image")
	         if (r == true) {
	           done()
	         } else {
         	}
       },
        editImage (formData, index, fileList) {
         console.log('edit data', formData, index, fileList)
   		},
   		exceedImageLimit(amount){
   			alert("you selected "+amount+"files which exceed the limit")
   		},
		addPlotImage(){
			if(this.images.length > 0){
				let plot_photo = {
					photo:this.images,
					plotid:this.plotid
				}
					this.$store.dispatch("addPlotImageAction", plot_photo).then((res)=>{
				this.showPlot(this.slug);
					this.$swal({
						title: 'Success',
						text: res.message,
						type: 'success',
						confirmButtonColor: '#10b759',
					}).then((result) => {
						if (result.value) {
								// this.vieworedit = false;
						}
					})
			}).catch((err)=>{
							this.$swal({
						title: 'Oops',
						text: err,
						type: 'error',
					});
			})
			}
				console.log(this.images)

		},
		deletePropertyImage(id){
			this.$swal({
				title: 'Confirmation',
				text: "Are you sure ?",
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch("deletePlotImage",id).then((res)=>{
							this.showPlot(this.slug);
							this.$swal(
						'Deleted!',res,
						'success'
					)

					})

				}
			})
		},
	},
	created() {
		this.showPlot(this.slug);
		//  window.addEventListener('beforeunload', this.showPlot(this.slug))
	},
  //       },
};
</script>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.padme{
	z-index: 200;
	color:red;
	position:relative;
	top:10;
	left:10;
	width:20px;
	height: 20px;
	margin-left:10px;
	margin-top:10px;
	cursor: pointer;
}
</style>
