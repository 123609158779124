import Router from 'vue-router'
import Vue from 'vue'
import multiguard from 'vue-router-multiguard';
import NProgress from "nprogress";
import {TokenService} from './services/tokenService'
import store from './store'

import guest from './middlewares/guest'
import auth from './middlewares/auth'
import verify from './middlewares/verify'
import middlewarePipeline from './middlewares/middlewarePipeline'

import Auth from './views/auth/Auth'
import Register from './views/auth/Register'
import Verify from "./views/auth/Verify";
import Dashboard from "./views/Dashboard";
import Home from "./views/properties/homes/Home";
import Hometable from "./views/properties/homes/Hometable";
import Homeview from "./views/properties/homes/Homeview";
import Homenew from "./views/properties/homes/Homenew";
import Plot from "./views/properties/plots/Plot";
import Plottable from "./views/properties/plots/Plottable";
import Plotview from "./views/properties/plots/Plotview";
import Plotnew from "./views/properties/plots/Plotnew";
import Profile from "./views/profile/Profile";
import Account from "./views/profile/Account";
import Page404 from "./views/Page404";
import Forgot from "./views/Forgot";
import Request from "./views/properties/leads/Request";
import Enquiry from "./views/properties/leads/Enquiry";
import Firstsubscription from "./views/subscription/Firstsubscription";
Vue.use(Router)


const router = new Router({
  mode: "history",
  linkActiveClass: "active",
  linkExactActiveClass: "active",
  routes: [
    {
      path: "/",
      name: "login",
      meta: {
        layout: "frontend",
        public: true,
        onlylogout: true,
        middleware: [ auth]
      },
      component: Auth
    },
    {
      path: "/register",
      name: "register",
      meta: {
        layout: "frontend",
        public: true,
        onlylogout: true,
        middleware: [ auth]
      },
      component: Register
    },
    {
      path: "/verify",
      name: "verify",
      meta: {
        layout: "frontend",
        public: true, // Allow access to even if not logged in
        onlyWhenLoggedOut: true,
        middleware: [ guest, verify]
      },
      component: Verify,
    },
    {
      path: "/firstsubscribe",
      name: "firstsubscribe",
      meta: {
        layout: "frontend",
        middleware: [guest,verify]
      },
      component: Firstsubscription
    },
    {
      path: "/homes",
      name: "homelist",
      meta: {
        layout: "backend",
        middleware: [ guest, verify]
      },
      component: Home,
      // beforeEnter: multiguard([emailVerify]),
      children: [
        {
          path: "table",
          name: "hometable",
          component: Hometable,
          meta: { layout: "backend",middleware: [ guest] }
        },
        {
          path: "view/:slug",
          name: "homeview",
          component: Homeview,
          meta: { layout: "backend",middleware: [ guest] }
        },
        {
          path: "new",
          name: "homenew",
          component: Homenew,
          meta: { layout: "backend",middleware: [ guest] }
        },
        {
          path: "",
          redirect: "table",
          meta: { layout: "backend",middleware: [ guest] }
        }
    ]
    },
    {
      path: "/dashboard",
      name: "dashboard",
      meta: {
        layout: "backend",
        isAuthrequired: true,
        middleware: [ guest,verify]
      },
      component: Dashboard
    },
    {
      path: "/plot",
      name: "plotlist",
      meta: {
        layout: "backend",
        isAuthrequired: true,
       middleware: [ guest]
      },
      component: Plot,
      children: [
        {
          path: "table",
          name: "plottable",
          component: Plottable,
          meta: { layout: "backend",middleware: [ guest] }
        },
        {
          path: "view/:slug",
          name: "plotview",
          component: Plotview,
          meta: { layout: "backend",middleware: [ guest] }
        },
        {
          path: "new",
          name: "plotnew",
          component: Plotnew,
          meta: { layout: "backend",middleware: [ guest] }
        },
        {
          path: "",
          redirect: "table",
          meta: { layout: "backend",middleware: [ guest] }
        }
      ]
    },
    {
      path: "/leads/requests",
      name: "request",
      meta: {
        layout: "backend",
        isAuthrequired: true,
        middleware: [ guest]
      },
      component: Request
    },
    {
      path: "/leads/enquiry",
      name: "enquiry",
      meta: {
        layout: "backend",
        isAuthrequired: true,
        middleware: [ guest]
      },
      component: Enquiry
    },
    {
      path: "/profile",
      name: "profile",
      meta: {
        layout: "backend",
        isAuthrequired: true,
       middleware: [ guest]
      },
      component: Profile
    },
    {
      path: "/forgot",
      name: "forgot",
      meta: {
        layout: "frontend",
        public: true,
        onlylogout: true
      },
      component: Forgot
    },
    {
      path: "/account",
      name: "account",
      meta: {
        layout: "backend",
   middleware: [ guest]
      },
      component: Account
    },

    {
      path: "*",
      component: {
        Page404
    },
    meta: {
      layout: "special"
    },
    }
  ]
});
router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start();
  }
  next();
});

router.afterEach((to, from) => {
  NProgress.done();
});

router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
            return next()
   }
    const middleware = to.meta.middleware

    const context = { to,  from,  next, store }
    return middleware[0]({
      ...context, next: middlewarePipeline(context, middleware, 1)
     })
     return next();
})
export default router;
