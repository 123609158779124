export const plotMixin = {
		data() {
			return {
				step: 1,
				totalStep: 4,
				inValidStep:false
			};
		},

		methods: {
			goToStep(step){

				this.step = step;
			},
			nextBtn() {
				if (this.step == this.totalStep) {
				}else{
					this.$v.form.$touch();
		          // if its still pending or an error is returned do not submit
		          if (this.$v.form.$pending || this.$v.form.$error){

								return;
							}
							this.step++;
							console.table(this.property_type_form);
						}
					},
			prevBtn() {
				if (this.step == 1) {
					this.step = 1;
				} else {
					this.step--;
				}
			},
			validateStep(){
					this.$v.$touch();
          if (this.$v.$invalid) {
						this.inValidStep = true;
            return false;
          }
						this.inValidStep = false;
						return true;



			}
		}
};
