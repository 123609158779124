<template>
  <div class="container pd-x-0">
          <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
             <breadcrum first="Home" second="Dashboard"></breadcrum>
              <h4 class="mg-b-0 tx-spacing--1">Welcome {{username}}</h4>
            </div>
            <div class="d-none d-md-block">
              <!-- <button class="btn btn-sm pd-x-15 btn-white btn-uppercase"><i data-feather="mail" class="wd-10 mg-r-5"></i> Email</button>
              <button class="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5"><i data-feather="printer" class="wd-10 mg-r-5"></i> Print</button>
              <button class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><i data-feather="user" class="wd-10 mg-r-5"></i> 0 Contacts</button> -->
            </div>
          </div>
          <div class="row row-xs" >


                <div class="col-md-3 mg-t-5 subscription-content-wrapper touch" @click="homeProperty()">
                  <div class="subscription-content">
                    <div class="row chart-content">
                      <div class="col-md-12 count">{{counter.total_properties}}</div>
                      <div class="col-md-12 count-description mg-t-5">Listed Homes</div>
                    </div>
                  </div>
                </div>
            <div class="col-md-3 mg-t-5 subscription-content-wrapper touch" @click="plotProperty()">
              <div class="subscription-content">
                <div class="row chart-content">
                  <div class="col-md-12 count">{{counter.total_plots}}</div>
                  <div class="col-md-12 count-description mg-t-5">Serviced Plots</div>
                </div>
              </div>
            </div>
            <div class="col-md-3 mg-t-5 subscription-content-wrapper touch" @click="requestList()">
                  <div class="subscription-content">
                    <div class="row chart-content">
                      <div class="col-md-12 count">{{counter.total_enquiries}}</div>
                      <div class="col-md-12 count-description mg-t-5">Property Enquiries</div>
                    </div>
                  </div>
            </div>
            <div class="col-md-3 mg-t-5 subscription-content-wrapper touch" @click="enquiryList()">
              <div class="subscription-content">
                <div class="row chart-content">
                  <div class="col-md-12 count">{{counter.total_requests}}</div>
                  <div class="col-md-12 count-description mg-t-5">Property requests</div>
                </div>
              </div>
            </div>


            <div class="col-6  mg-t-10">
              <div class="card">
                <div class="card-header pd-y-20 d-md-flex align-items-center justify-content-between">
                  <h6 class="mg-b-0"><span style="font-weight:bold">{{this_year}}</span> Enquiries flows</h6>

                </div><!-- card-header -->
                <div class="card-body pos-relative pd-0">


                  <div class="chart-one">
                    <!-- <div id="flotChart" class="flot-chart"></div> -->
                     <bar-chart :height="250" :chartData="counter.enquiries_chart"></bar-chart>
                  </div><!-- chart-one -->
                </div><!-- card-body -->
              </div><!-- card -->
            </div>
            <div class="col-6  mg-t-10">
              <div class="card">
                <div class="card-header pd-y-20 d-md-flex align-items-center justify-content-between">
                  <h6 class="mg-b-0"><span style="font-weight:bold">{{this_year}}</span> Requests flows</h6>

                </div><!-- card-header -->
                <div class="card-body pos-relative pd-0">


                  <div class="chart-one">
                    <!-- <div id="flotChart" class="flot-chart"></div> -->
                     <bar-chart :height="250" :chartData="counter.request_chart"></bar-chart>
                  </div><!-- chart-one -->
                </div><!-- card-body -->
              </div><!-- card -->
            </div>


          </div>

	</div><!-- container -->

</template>
<script>
import Linechart from '../components/Linechart'
import Barchart from '../components/Barchart'
export default {
    components:{'line-chart':Linechart,'bar-chart':Barchart},
    data(){
        return{
            this_year:new Date().getFullYear()
        }
    },
	computed:{
      // checkuser_verification(){
      //   let verified = this.$store.state.authmodule.user.email_verified;
      //   if(verified != 0){
      //     return true;
      //   }
      //   return false;
      // },
			counter(){
				return this.$store.state.dashboardmodule.info;
			},
            username(){
                return this.$store.state.authmodule.user.name;
            }
	},
    methods:{
        dashboardInfo(){
            this.$store.dispatch("dashboardInfoAction")
            this.$store.dispatch("developeSusbcriptionStatusAction")
        },
        homeProperty(){
            this.$router.push({name:'hometable'})
        },
        plotProperty(){
            this.$router.push({name:'plottable'})
        },
        requestList(){
            this.$router.push({name:'request'})
        },
        enquiryList(){
            this.$router.push({name:'enquiry'})
        }
    },
	created(){
        this.dashboardInfo();


		// this.$router.go();
	}
}
</script>
<style  scoped>
    .touch{
        cursor:pointer;
    }
.tile .icon { position: absolute; top: 10px; right: 10px; font-size: 48px; line-height: 1; color: #ccc; }
.overview-wrapper { background: #fff; padding: 10px; box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.16); }
.overview-wrapper .tab-container { display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; -webkit-align-items:
center; -webkit-box-align: center; -ms-flex-align: center; align-items: center; }

.overview-wrapper .tab-container .tab-icon { height: calc(25px + 30px); }

.overview-wrapper .text-container { text-align: right; }

.overview-wrapper .text-container .action-title { font-size: 12px; line-height: 1.5; font-weight: 400; text-transform: capitalize; letter-spacing: 0.2px;}

.overview-wrapper .text-container .action-count { color: #0075F1; font-size: 2.9rem; font-weight: 900; }

.overview-wrapper .text-container .action-link { font-size: 10px; }



/* .dashboard-wrapper { padding: 15px; margin: 15px 0; } */

.dashboard-section { margin-bottom: 30px; }

.dashboard-section .chart-row .padding-right5px { padding-right: 5px; }

.Dashboardwrapperv1 { background:#0059B8; padding: 10px; color: #fff; }

.Dashboardwrapperv2 { background:#37A9FA; padding: 10px; color: #fff; }

.Dashboardwrapperv3 { background:#ffff; padding: 10px; color: #555555; }

.colorv1 { background: linear-gradient(129deg,#37A9FA,50%,#0075F1); padding: 10px; color: #fff; }

.colorv1 .chart-title { font-size: 1.1rem; font-weight: 900; }

.colorv1 .chart-area { height: 70px; }

.colorv1 .chart-footer .chart-count { font-size: 2.5rem; font-weight: 900; display: -webkit-box; display: -webkit-flex; display:
-ms-flexbox; display: flex; line-height: 1; -webkit-align-items: flex-end; -webkit-box-align: flex-end; -ms-flex-align: flex-end;
align-items: flex-end; }

.colorv1 .chart-footer .chart-description { font-size: 1.1rem; text-align: right; display: -webkit-box; display: -webkit-flex;
display: -ms-flexbox; display: flex; -webkit-align-items: flex-end; -webkit-box-align: flex-end; -ms-flex-align: flex-end;
align-items: flex-end; }

.dashboard-wrapper .dashboard-section .chart-row .padding-left5px { padding-left: 5px; }
#line-chart{
    height: 200px;
}
.subscription-content {
/* color: #0075F1; */
background: #fff;
padding: 5px;
border: 1px solid #f2f2f2;
/* background: linear-gradient(129deg,#0059B8,50%,#0075F1);
color: #fff;
padding: 15px; */
/* background-image: url('https://cdn.iconscout.com/icon/free/png-256/graph-131-81702.png');  */
}
.count{
    color: #0075F1;
}
.subscription-content2 {
background: #37A9FA;
color: #fff;
padding: 15px;
/* background-image: url('https://cdn.iconscout.com/icon/free/png-256/graph-131-81702.png');  */
}

.subscription-content .chart-header .chart-title { font-size: 1.1rem; }

.subscription-content .chart-header .chart-dropdown { font-size: 1.1rem; text-align: right; position: relative;}

.subscription-content .chart-content { text-align: center; padding: 14px 0; }

.subscription-content .chart-content .count { font-size: 3rem; font-weight: 900; line-height: 1; }

.subscription-content .chart-footer .footer-select-title { font-size: 1.2rem; font-weight: 900; }

.subscription-content .chart-footer .footer-select-text-content { font-size: 1.1rem; }
.count-description{
    color:#031d6b;
    font-weight: bold;
}
</style>
