<template>
  <div >
		<component :is="layout"></component>
		<!-- <div v-if="backend">
				<backend-layout></backend-layout>
		</div>
		<div v-if="!backend">
			<frontend-layout></frontend-layout>
		</div> -->
  </div>
</template>
<script>

// import './assets/lib/datatables.net-dt/js/dataTables.dataTables.min.js';
// import './assets/lib/datatables.net-responsive/js/dataTables.responsive.min.js';
// import './assets/lib/datatables.net-responsive-dt/js/responsive.dataTables.min.js';

const frontend = "frontend"
export default {
	inject:["eventBus","restApi",'apiService'],
	computed:{
		backend(){
				if(this.$route.meta.layout == 'backend'){
					this.$route.meta.layout+"-layout"
					return true;
				}
				this.$route.meta.layout+"-layout"
				return false;
		},
		layout(){
			return (this.$route.meta.layout || frontend)+"-layout"
		}
	},
	created(){
		this.$store.dispatch('countryListAction');
		this.$store.dispatch('currencyListAction');
		this.$store.dispatch('amenityListAction');
		this.$store.dispatch('titleDocListAction');
		this.$store.dispatch('financeOptionListAction');
		this.$store.dispatch('hometypeListAction');
		this.$store.dispatch('paymentOptionListAction');
		this.$store.dispatch('propertystatusListAction');
        this.$store.dispatch('subscriptionListAction')
			// this.restApi.get_country_list().then((res)=>{
			// 	console.log('a: '+res.data)
			// 	let c = res.data.data;
			// 	let q = JSON.stringify(c)

			// 	this.$store.dispatch('countryListAction', c);
			// 	this.$store.getters.get_country_list
			// })
	}
}
</script>
<style lang="scss">
a{
    cursor: pointer;
}
	@import './assets/lib/@fortawesome/fontawesome-free/css/all.min.css';
	@import './assets/lib/typicons.font/typicons.css';
	@import './assets/lib/ionicons/css/ionicons.min.css';
	@import './assets/lib/quill/quill.core.css';
	@import './assets/lib/quill/quill.snow.css';
	@import './assets/lib/quill/quill.bubble.css';
	@import './assets/css/dashforge.css';
	@import './assets/css/dashforge.dashboard.css';
	@import './assets/css/dashforge.landing.css';
    	@import './assets/css/dashforge.profile.css';
	// @import './assets/lib/datatables/css/dataTables.bootstrap.min.css';
	@import './assets/lib/datatables.net-responsive-dt/css/responsive.dataTables.min.css';
   @import './assets/css/nh.css';
	@import url(http://fonts.googleapis.com/icon?family=Material+Icons);
    .text-bold{
    	font-weight:bold;
    }
    .text-italic{
        font-style: italic;
    }
    .content-body{
        background-color: #f4f5f8;
    }
.router-link-active{
	opacity: 1;
    color: #0168fa;
    font-weight: 500;
}
.router-link-exact-active{
	opacity: 1;
    color: #0168fa;
    font-weight: 500;
}
table{
	table-layout: inherit !important;
}
.mytable{
    border-collapse: seperate;
    border-spacing: 0 15px !important;
    /* border-spacing:5em; */
}
tr{
background: #fff;
box-shadow: 10px;
}
.table thead th {
font-weight: bold;
}
.set-height td{
position: relative;
overflow: hidden;
height: 2em;
}



#nprogress .bar {
 background: #0168fa !important;
 height: 3px !important;

}

#nprogress .spinner-icon {
	// border
  // border-left-color: green !important;
  // border-top-color: yellow  !important;
  border-left-color: green !important;
  border-top-color: #0168fa  !important;
	width:30px !important;
	height:30px !important;

}
.link-url{
    color:#042893 !important;
}

@media (max-width: 991px) {
	.step-title{
		display:none;
	}
}

</style>
