<template >
<div id="body">
	<div v-if="verifiedDeveloper">
		<app-sidebar :developer="developer" ></app-sidebar>
		<div class="content ht-100v pd-0">
			<app-header></app-header>
			<div class="content-body">
				<router-view></router-view>
			</div>
		</div>
	</div>
	<div v-if="!verifiedDeveloper">
			<app-fronthead></app-fronthead>
			<div class="content content-fixed content-auth-alt">
				<div class="container ht-150p">
					<div class="ht-100p d-flex flex-column align-items-center justify-content-center">
						<div class="wd-150 wd-sm-250 mg-b-30"><img src="../../assets/img/img15.png" class="img-fluid" alt=""></div>
						<h4 class="tx-20 tx-sm-24">Congrat {{developer_name}}</h4>
						<h4 class="tx-20 tx-sm-24">Pending</h4>
						<p class="tx-color-03 mg-b-20">Your Account creation was successful but pending verification and approval</p>
						<div class="tx-13 tx-lg-14 mg-b-40">
						<!-- <p>Didn't received OTP mail <a href="#" class="align-items-center">Resend Verification</a></p> -->
						</div>
					</div>

        		</div>
      		</div>
	</div>
	
</div>

</template>
<script>
import Fronthead from '../templates/frontend/Fronthead'
import Header from '../templates/Header'
import Sidebar from '../templates/Sidebar'
import Footer from '../templates/Footer'
import Errordisplay from '../Errordisplay'

// import '../../assets/lib/ionicons/ionicons.js'
export default {
	inject:["restApi"],
	data(){
			return{
				developer:'Developer',
				developer_name:''
			}

	},
	components:{'app-header':Header,'app-sidebar': Sidebar, 'app-footer' :Footer,'app-err':ErrorEvent,'app-fronthead':Fronthead},
	beforeRouteEnter (to, from, next) {
			this.$store.dispatch("dashboardInfoAction").then((res)=>{
				next();
			}).catch((err)=>{

			})
    // getPost(to.params.id, (err, post) => {
    //   next(vm => vm.setData(err, post))
    // })
  },
  computed:{
	  verifiedDeveloper(){
		  let dev = this.$store.state.authmodule.developerprofile;
		  this.developer_name = dev.developer_name
		  if(dev.developer_published == 1){
			  return true;
		  }
		  return false;
	  }
  },
  methods:{
	  developerInfo(){
		  
	  }
  },
  created(){
	  this.$store.dispatch("userProfileAction")
	  this.$store.dispatch("developerInfoAction")
  }

}
</script>
<style lang="scss" scoped>
	.az-sidebar-indigo-dark {
    background: linear-gradient(180deg,#0c1929 0,#000 92%,#000);
    border-right-width: 0;
}
.active {
    font-weight: 500 !important;
    color: #fff !important;
}
</style>
