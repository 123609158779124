import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
window.$ = window.jQuery = require('jquery');
import 'bootstrap'
import moment from 'moment'
import ApiService from './services/apiService'
import { ApiSource } from "./services/apiService";
import {TokenService} from './services/tokenService'
import VeeValidate from 'vee-validate';
import VueBootstrap4Table from "vue-bootstrap4-table";
import VueFormWizard from "vue-form-wizard";
import { Form, HasError, AlertError } from "vform";
import VueProgressBar from "vue-progressbar";
import Vuelidate from "vuelidate";
import V2Table from "v2-table";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import VueSweetalert2 from "vue-sweetalert2";
import CKEditor from '@ckeditor/ckeditor5-vue';
import Lingallery from 'lingallery';
import VueGallery from 'vue-gallery';
import PictureInput from 'vue-picture-input'
import Carousel3d from 'vue-carousel-3d';
import Paystack, { setGlobalConfig } from "paystack-simple";
import ToggleButton from 'vue-js-toggle-button'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import carousel from 'vue-owl-carousel'


setGlobalConfig({ key: 'pk_test_89710b77b2df8b41c7991ebd487146efce5dc7bb' }) // Key has been set globally
const paymentInstance = Paystack();
paymentInstance.init()

let feather = require("./assets/lib/feather-icons/feather.min.js");
let dashforge = require("./assets/js/dashforge.js");
let dashforge_aside = require("./assets/js/dashforge.aside.js");
let cus = require('./assets/js/custom.js');






// window.swal = swal;
// const toast = swal.mixin({
//   toast: true,
//   position: "top-end",
//   showConfirmButton: false,
//   timer: 5000
// });
// window.toast = toast;
window.VForm = Form;
window.PerfectScrollbar = require('perfect-scrollbar');
// import the styles
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import "beautify-scrollbar/dist/index.css";
import "v2-table/dist/index.css";
import "../node_modules/nprogress/nprogress.css";




// import 'bootstrap/dist/css/bootstrap.min.css'
Vue.use(VeeValidate);
Vue.use(VueFormWizard);
Vue.use(VueProgressBar, {
  color: "green",
  failedColor: "red",
  height: "5px"
});
Vue.use(Vuelidate);
Vue.use(V2Table);
const sweetAlertOptions = {
  confirmButtonColor: "#41b882",
  cancelButtonColor: "#ff7674"
};

Vue.use(VueSweetalert2, sweetAlertOptions);
Vue.use(CKEditor);
Vue.component('lingallery', Lingallery);
Vue.use(Carousel3d);
Vue.use(ToggleButton);





Vue.filter('currency',function(value){
let formatter = Intl.NumberFormat("NGN", {
	// style: "currency",
	minimumFractionDigits: 0
});//'₦ '+
return '₦ '+formatter.format(value);

});
Vue.filter('formatDate', function(value) {

    return moment(value).format('Do MMMM  YYYY , h:mm:ss a');

});
Vue.filter('firstToUpper',function(v){
  if (typeof v !== "string") return "";
  return v.toUpperCase();

})
// Vue.filter('uppercase',function(v){
//     return v.toUpperCase();
// })

// Vue.filetr('firstLetterToUpper',function(word){
//     return word.charAt(0).toUpperCase();
// });

  // Set the base URL of the API
	// ApiService.init(process.env.VUE_APP_ROOT_API)
	// const imageUrl = "http://newhomesdashboard.test/"
  // ApiService.init("http://newhomesdashboard.test/api/")
  const imageUrl = "https://account.newhomes.ng/"
  ApiService.init("https://account.newhomes.ng/api/")

  // If token exists set header
  if (TokenService.getToken()) {
  		ApiService.setHeader()
  }


import Frontend from './components/layouts/Frontend.vue'
import Backend from './components/layouts/Backend.vue'
import Page404 from './views/Page404.vue'
import Breadcrum from './components/templates/Breadcrum.vue'
import Mymodal from './components/Mymodal.vue'
import generalMixin from './mixins/general'

Vue.config.productionTip = false

Vue.component(HasError.name, HasError);
Vue.component(AlertError.name, AlertError);
Vue.component('frontend-layout',Frontend)
Vue.component('backend-layout',Backend)
Vue.component('special-layout',Page404)
Vue.component("vue-bootstrap4-table", VueBootstrap4Table);
Vue.component("breadcrum", Breadcrum);
Vue.component("vue-upload-multiple-image", VueUploadMultipleImage);
Vue.component('gallery',VueGallery);
Vue.component('picture-input',PictureInput);
Vue.component('my-modal',Mymodal);
Vue.component('vue-google-autocomplete',VueGoogleAutocomplete)
Vue.component('carousel',carousel)

new Vue({
  router,
  store,
  render: h => h(App),

  data: {
    eventBusFirst: new Vue(),
		componentKey: 0,
		toastOption:{
				toast: true,
				position: "top-end",
				showConfirmButton: false,
				timer: 5000
			}
  },
  provide: function() {
    return {
      apiService: ApiService,
      eventBus: this.eventBusFirst,
      restApi: new ApiSource(this.eventBus),
      imgUrl:imageUrl,
      paystack:paymentInstance,
      forceRender: function() {
        this.componentKey += 1;
      },
      toast: this.toastOption
    };
  }
}).$mount("#app");
