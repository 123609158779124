import { ApiSource } from "../services/apiService";
const api = new ApiSource();
const state = {
	requests: [],
	enquiries:[],
	request_pagination_data:'',
    enquiry_pagination_data:'',


};
const mutations = {

  GET_UPDATED_REQUEST(state, payload) {
		state.requests = payload.data;
		state.request_pagination_data = payload;

	},
    GET_UPDATED_ENQUIRY(state, payload) {
       state.enquiries = payload.data;
       state.enquiry_pagination_data = payload;

   }
};
const actions = {
  requestListAction({ commit },main_url="developer/requests") {

        api.get_developer_request(main_url).then(res => {
          commit("GET_UPDATED_REQUEST", res.data.data);
        });
    },
  requestListAction2({ commit },page) {
		let main_url = "developer/requests?page="+page;
        api.get_developer_request2(main_url).then((res) => {
           commit("GET_UPDATED_REQUEST", res.data.data);
        });
    //
	},
    enquiryListAction({ commit },main_url="developer/enquiries") {

          api.get_property_enquiries(main_url).then(res => {
            commit("GET_UPDATED_ENQUIRY", res.data.data);
          });
      },
    enquiryListAction2({ commit },page) {
          let main_url = "developer/enquiries?page="+page;
          api.get_property_enquiries2(main_url).then((res) => {
             commit("GET_UPDATED_ENQUIRY", res.data.data);
          });
      },


};
const getters = {

};
const leadmodule = {
  state,
  mutations,
  actions,
  getters
};
export default leadmodule;
