

   export default function guest ({ next, store }){
    if(!store.state.authmodule.isLoggedIn){
        return next({
           name: 'login'
        })
    }

    return next()
   }
