<template lang="html">
    <div class="">
         <a class="btn btn-success text-white btn-xs" data-toggle="modal" data-animation="effect-slide-in-right" @click="showModal()" ><i class="fa fa-eye"></i></a>

         <!-- <div
             class="modal fade"
             id="subdiv"
             tabindex="-1"
             role="dialog"
             aria-labelledby="exampleModalLabel6"
             aria-hidden="true">
             <div  class="modal-dialog modal-dialog-centered" role="document">
                 <div class="modal-content tx-14">
                     <div class="modal-header">
                         <h6 class="modal-title" id="exampleModalLabel6">{{contact.name}}</h6>

                         <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                             <span aria-hidden="true">&times;</span>
                         </button>
                     </div>
                     <div class="modal-body">

                     </div>

                 </div>
             </div>
         </div> -->
         <my-modal :id="id" :header="contact.subject">
             <div class="" slot="modal-body">
                 <h4>Name : {{contact.name}}</h4>
                <h4>Email : {{contact.email}}</h4>
                <h4>Phone : {{contact.phone}}</h4>
                <p>{{contact.messages}}</p>
             </div>
         </my-modal>
    </div>
</template>

<script>
export default {
    props:["contact"],
    data(){
        return{
            id:"subdiv"
        }
    },
    methods:{
            showModal(){
                $('#subdiv').modal("show");
            },
    }
}
</script>

<style lang="css" scoped>
</style>
