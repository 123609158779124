import { ApiSource } from "../services/apiService";
const api = new ApiSource();
const state = {
	properties: [],
	property:'',
	pagination_data:''

};
const mutations = {

  GET_UPDATED_HOME(state, payload) {
		state.properties = payload.data;
		state.pagination_data = payload;

	}
	,
	SHOW_HOME_DETAIL_SLUG(state, payload){
			state.property = payload;
				// state.properties.findIndex(function(p) {

        //   if ((p.slug = payload)) {
				// 		state.property = p;
				// 			console.table(p)
        //   }
        // });
	}
};
const actions = {
  propertyListAction({ commit },main_url="developer/properties") {

    api.developer_properties(main_url).then(res => {
      commit("GET_UPDATED_HOME", res.data.data);
    });
    // commit("GET_UPDATED_PROPERTIES", list);
    //
  },
  propertyListAction2({ commit },	page) {
		let main_url = "developer/properties?page="+page;
    api.developer_properties(main_url).then((res) => {
      commit("GET_UPDATED_HOME", res.data.data);
    });
    // commit("GET_UPDATED_PROPERTIES", list);
    //
	},
	addPropertyAction({commit},formdata){
		return new Promise(function (resolve, reject) {
			 api.addProperty(formdata).then((res) => {

				 resolve(res.data.data);
       }).catch((err)=>{
				 reject(err.data);
			 });
		})
	},
	deletePropertyAction({commit}, slug){
			return new Promise(function (resolve, reject) {
			 api.deleteProperty(slug).then((res) => {
				 resolve(res.data.data);
       }).catch((err)=>{
				 reject(err);
			 });
		})
	},
  showPropertyBySlugAction({ commit }, slug) {
		return new Promise(function (resolve, reject) {
			api.showProperty(slug).then((res) => {
				resolve(res.data.data);
				commit("SHOW_HOME_DETAIL_SLUG", res.data.data);
			}).catch((err)=>{
				reject(err);
			});
	 })
    // commit("SHOW_HOME_DETAIL_SLUG", slug);
	},
	updatePropertyInfoAction({commit},data){
		let formdata = data.form;
		let slug = data.slug;
		return new Promise((resolve,reject)=>{
			api.updatePropertyInfo(formdata,slug).then((res)=>{
				let data = res.data.data;
				let msg = res.data.message;
				let result = {
						response : data,
						message : msg
				}
				resolve(result);
				commit("SHOW_HOME_DETAIL_SLUG", res.data.data);
			}).catch((err)=>{
					reject(err.data.message);
			})
		})
	},
	addHometypeAction({commit},data){
		return new Promise((resolve, reject)=>{
			api.addPropertyType(data).then((res)=>{
				let message = res.data.data;
				console.table(message);
				resolve(message)
			}).catch((err)=>{
				reject(err.data.message)
			})
		})

	},
	updateHometypeAction({commit},data){
			let formdata = data.form;
		let id = data.id;
				return new Promise((resolve, reject)=>{
			api.updatePropertyType(formdata,id).then((res)=>{
				let message = res.data.message;
				resolve(message)
			}).catch((err)=>{
				reject(err.data.message)
			})
		})
	},
	deleteHometypeAction({commit},id){
		return new Promise((resolve,reject)=>{
			api.deletePropertyType(id).then((res)=>{
				let msg = res.data.message;
				resolve(msg)

			}).catch((err)=>{
				reject(err.data.message);
			})
		})
	},
	updateHomeAmenityAction({commit},data){
			let formdata ={"amenities":data.form};
		let propertyid = data.id;
		return new Promise((resolve,reject)=>{
			api.updatePropertyAmenity(formdata,propertyid).then((res)=>{
				let msg = res.data.res;
				resolve(msg)

			}).catch((err)=>{
				reject(err.data.message);
			})
		})
	},
	addPropertyImageAction({commit}, data){
		return new Promise((resolve,reject)=>{
			api.addPropertyPhoto(data).then((res)=>{
				// console.table(res.data.data);
				let msg = res.data.message;
				resolve(msg)

			}).catch((err)=>{
				reject(err.data.message);
			})
	})
},
deletePropertyImage({commit},id){
	return new Promise((resolve,reject)=>{
		api.deletePropertyPhoto(id).then((res)=>{
			// console.table(res.data.data);
			let msg = res.data.message;
			resolve(msg)

		}).catch((err)=>{
			reject(err.data.message);
		})
})
}
};
const getters = {
		get_home_details(state){
			return state.property;
		}
};
const homemodule = {
  state,
  mutations,
  actions,
  getters
};
export default homemodule;
