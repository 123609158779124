<template lang="html">
<div>
    <a class="btn btn-info text-white btn-xs" data-toggle="modal" data-animation="effect-slide-in-right" @click.prevent="showModal()">
        <i class="fa fa-cloud "></i> {{info}}
    </a>
    <my-modal :id="modalid" :header="modalheader">
        <div class="" slot="modal-body">
            <form>
                <div class="row justify-content-center">
                    <div class="switch-field">
                        <span class="s_text">Annually</span> &nbsp;
                        <input type="radio" id="radio-one" name="swicth" v-model="subscriptionform.billing_type" value="Annually" @change="onChangeEventHandler" />
                        <label for="radio-one" class="label"></label>
                        <input type="radio" id="radio-two" name="swicth" v-model="subscriptionform.billing_type" value="Monthly" checked
                        @change="onChangeEventHandler"/>
                        <label for="radio-two" class="label"></label>&nbsp; <span class="s_text">Monthly</span>
                    </div>
                    <!-- <toggle-button @change="onChangeEventHandler" :value="billing_type" :width="150" id="billing"
                         :color="{checked: '#3B5322', unchecked: '#0168fa'}"
                         :labels="{checked: 'Annually', unchecked: 'Monthly'}">

                     </toggle-button> -->
                </div>
                <div class="row justify-content-center">
                      <div class="col-10 col-sm-6 col-md-6  d-flex flex-column text-center " v-for="(sub, index) in subscriptionlist" :key="index">
                          <div class="tx-100 lh-1 text-center"><i class="icon ion-ios-car"></i></div>
                          <h4 class="mg-b-25 text-center">{{sub.name}}</h4>
                          <p class="tx-color-03 mg-b-25 text-center">Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum.</p>
                          <h3 class="tx-rubik tx-normal mg-b-30 mg-t-auto text-center" >{{showPrice(sub.price) | currency}}</h3>
                          <button type="submit" class="btn btn-primary btn-block" @click.prevent="choosePlan(sub)">Choose Plan</button>
                      </div>
                  </div><!-- row -->
          </form>
        </div>
    </my-modal>
    
</div>
</template>

<script>
export default {
    inject:['paystack'],
    props:['info'],
    data(){
        return{
            modalid:"subdiv",
            modalheader:"Upgrade Account",
            subscriptionform:{
                subscriptiontypeid:'',
                amount:'',
                billing_type:'Monthly',
                ref:'',
                trans:''
            },
            usermail:this.$store.state.authmodule.user.email,
            billing_type:false,
            amount:''
        }
    },
    computed:{
        subscriptionlist(){
            return this.$store.state.generalmodule.subscription_list;
        },
    },
    methods:{
        showModal(){
            $('#subdiv').modal("show");
        },
        showPrice(p){
            if(this.billing_type){
                this.subscriptionform.billing_type = 'Annually'
                this.subscriptionform.amount = p *12;
                // console.log(this.subscriptionform.amount )
                return p * 12;
            }
            this.subscriptionform.amount = p
            // this.subscriptionform.amount = amount;
            return p;
        },
        onChangeEventHandler(){
            this.billing_type = !this.billing_type;
        },
        choosePlan(plan){
            this.subscriptionform.subscriptiontypeid = plan.id;

            if(plan.name == 'Free'){
                this.subscriptionform.amount = plan.price;
                this.$store.dispatch("developerMakeSubscriptionAction",this.subscriptionform);
            }else{
                this.paystack.addOptions({ // No need to add it here
                  amount: this.subscriptionform.amount * 100,
                  email: this.usermail,
                  currency: "NGN",
                  ref:Math.floor((Math.random() * 1000000000) + 1),
                  callback: (res) => {
                      let ref_number = res.reference;
                      let trans = res.trans;
                      this.subscriptionform.ref = ref_number;
                      this.subscriptionform.trans = trans;
                      this.$store.dispatch("developerMakeSubscriptionAction",this.subscriptionform);

                      console.log(res);
                    // act on response
                        //get the payment href
                        //submit the form to save
                  }
                })

                this.paystack.pay() // Pay
            }
        }

    }
}
</script>

<style lang="css" scoped>
.switch-field {
display: flex;
margin-bottom: 36px;
overflow: hidden;
}

.switch-field input {
position: absolute !important;
clip: rect(0, 0, 0, 0);
height: 1px;
width: 1px;
border: 0;
overflow: hidden;
}

.switch-field label {
/* background-color: #0158d4; */
color: rgba(0, 0, 0, 0.6);
font-size: 14px;
line-height: 1;
text-align: center;
padding: 8px 16px;
margin-right: -1px;
border: 1px solid rgba(0, 0, 0, 0.2);
box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
transition: all 0.1s ease-in-out;
}

.switch-field label:hover {
cursor: pointer;
}

.switch-field input:checked + label {
background-color: #0168fa;
box-shadow: none;
}

.switch-field label:first-of-type {
border-radius: 4px 0 0 4px;
}

.switch-field label:last-of-type {
border-radius: 0 4px 4px 0;
}
.s_text{
    font-weight:bold;
    margin: 5px;
}
.label{
    width:80px;
    height: 30px;
}

/* This is just for CodePen. */

.form {
max-width: 600px;
font-family: "Lucida Grande", Tahoma, Verdana, sans-serif;
font-weight: normal;
line-height: 1.625;
margin: 8px auto;
padding: 16px;
}

h2 {
font-size: 18px;
margin-bottom: 8px;
}
</style>
