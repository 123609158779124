import { UserService, AuthenticationError } from "../services/userService";
import { TokenService } from "../services/tokenService";
import ApiService from "../services/apiService";
import { ApiSource } from "../services/apiService";
const api = new ApiSource();
import router from "../router";
const baseUrl = "http://newhomesdashboard.test/api/";
const state = {
  authenticating: false,
  accessToken: TokenService.getToken(),
  authenticationErrorCode: 0,
  authenticationError: "",
  user: {
      name:'',
      email:'',
      phone:'',
      usertype:'',
      email_verified:0,
  },
  developerprofile:'',
  isLoggedIn:false,
  developersubscription:'',


};
const mutations = {

    UPDATED_USER_DATA(state,payload){
      console.log(payload)
        state.user.name = payload.firstname;
        state.user.email = payload.email;
        state.user.phone = payload.phonenumber;
        state.user.usertype = payload.usertype;
        state.user.email_verified = payload.email_verified;
    },
    DEVELOPER_INFO_DATA(state, payload) {
       state.developerprofile = payload
    },
    GET_DEVELOPER_SUBSCRIPTION_INFO(state,payload){
        state.developersubscription = payload
    },


  loginRequest(state) {
    state.authenticating = true;
    state.authenticationError = "";
    state.authenticationErrorCode = 0;
  },


  loginSuccess(state, data) {
    state.accessToken = data.token;
    state.authenticating = false;
    state.isLoggedIn = true;

    // console.log(state.user);
  },


  loginError(state, { errorCode, errorMessage }) {
    state.authenticating = false;
    state.authenticationErrorCode = errorCode;
    state.authenticationError = errorMessage;
  },

  logoutSuccess(state) {
    state.accessToken = "";
    state.isLoggedIn = false;
  }
};
const actions = {

  async register({commit}, data){
		commit("loginRequest");
		return await UserService.register(data).then((res)=>{
					 commit("loginSuccess", res.data);
                     commit("UPDATED_USER_DATA", res.data.data);
                     router.push("/verify");
(router.history.current.query.redirect || "/verify");
					 // router.push("/verify");

			 // return true
		})

	},
  async login({ commit }, data) {
		commit("loginRequest");
       await UserService.login(data).then((res)=>{
           commit("loginSuccess", res.data);
           // console.log(res.data.data.user);
           commit("UPDATED_USER_DATA", res.data.data.user);
           // window.location.href="/dashboard"
           router.go(router.history.current.query.redirect || "/dashboard");
           return true;
       })

    },
  logout({ commit }) {
    UserService.logout();
    commit("logoutSuccess");
    router.push({name:'login'});
},
verifyusercodeAction({commit}, data){
    return new Promise((resolve, reject)=>{
        api.verifycode(data).then((res)=>{
            commit("UPDATED_USER_DATA", res.data.data);
            router.push(router.history.current.query.redirect || "/dashboard");
            resolve(res.data.data);
        })
    })
},
  userProfileAction({commit}){
      api.get_user_info().then((res)=>{
          commit("UPDATED_USER_DATA",res.data.data);
      })
  },
  developerInfoAction({ commit }) {
   api.get_developer_info().then((res)=>{
         commit("DEVELOPER_INFO_DATA", res.data.data);
     })
 },
 developeSusbcriptionStatusAction({commit}){
     api.get_developer_subscription_info().then((res)=>{
         commit("GET_DEVELOPER_SUBSCRIPTION_INFO",res.data.data)
     })
 },
 developerMakeSubscriptionAction({commit},form){
     let status = "fail";
     return new Promise((resolve,reject)=>{
         api.developer_subscribe(form).then((res)=>{
             commit("GET_DEVELOPER_SUBSCRIPTION_INFO",res.data.data)
         });
         status = "success";
            router.push(router.history.current.query.redirect ||"/dashboard");
            // router.go("/dashboard");
         resolve(status);
         // router.push("/dashboard");
     }).catch((err)=>{
         status = "fail";
         reject(status);
     })

 },
 changePasswordAction({commit},form){
      return new Promise((resolve,reject)=>{
        api.changepassword(form).then((res)=>{
          let data = res.data.data;
            // commit("GET_DEVELOPER_SUBSCRIPTION_INFO",res.data.data)
            resolve(data)
        });
    }).catch((err)=>{
        let status = "fail";
        reject(status);
    })
 },
 updateProfileAction({commit},form){
  return new Promise((resolve,reject)=>{
    api.updateprofile(form).then((res)=>{
      let data = res.data.data;
      commit("DEVELOPER_INFO_DATA", data);
        resolve(data)
    });
}).catch((err)=>{
    let status = "fail";
    reject(status);
})
}

};
const getters = {
  getUser:state => {state.user;
  },
  loggedIn: state => {
    return state.accessToken ? true : false;
  },

  authenticationErrorCode: state => {
    return state.authenticationErrorCode;
  },

  authenticationError: state => {
    return state.authenticationError;
  },

  authenticating: state => {
    return state.authenticating;
  }
};
const authmodule = {
  state,
  mutations,
  actions,
  getters
};

export default authmodule;
