<template>
	<div>
			<div class="az-footer ht-40">
          <div class="container ht-100p pd-t-0-f">
            <span>&copy;  Newhomes Nigeria</span>
            <span>Designed with <i class="typcn typcn-heart btn-danger"></i> by 707 Digital</span>
          </div><!-- container -->
    </div>
	</div>
</template>
<script>
export default {
	
}
</script>
