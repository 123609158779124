<template lang="html">
    <div class="">
        <!-- <app-fronthead></app-fronthead> -->
        <div class="content content-fixed content-auth-alt">
          <div class="container d-flex justify-content-center ht-100p">
            <div class="mx-wd-300 wd-sm-450 ht-100p d-flex flex-column align-items-center justify-content-center">
              <div class="wd-80p wd-sm-300 mg-b-15"><img src="../assets/img/img18.png" class="img-fluid" alt=""></div>
              <h4 class="tx-20 tx-sm-24">Reset your password</h4>
              <p class="tx-color-03 mg-b-30 tx-center">Enter your email address and we will send you a link to reset your password.</p>
              <div class="wd-100p d-flex flex-column flex-sm-row mg-b-40">
                  <!-- <form class="" @submit.prevent="resetPassword()" method="post"> -->

                      <input type="text" v-model="form.email"  class="form-control wd-sm-250 flex-fill" placeholder="Enter username or email address" :class="{ 'is-invalid': $v.form.email.$error }">
                      <div v-if="!$v.form.email.required" class="invalid-feedback">valid email is required</div>
                      <button type="submit" @click.prevent="resetPassword()"  class="btn btn-brand-02 mg-sm-l-10 mg-t-10 mg-sm-t-0">Reset Password</button>


                  <!-- </form> -->

              </div>

            </div>
          </div><!-- container -->
        </div><!-- content -->
    </div>

</template>

<script>
import { required, email, minLength, sameAs,requiredUnless,requiredIf } from "vuelidate/lib/validators";
export default {
    inject:["eventBus","restApi"],
    data(){
        return{
            form:{
                email:''
            }
        }
    },
    computed:{
        rule(){
            return{
                email:{required,email}
            }
        }
    },
    validations() {
        return{
            form:this.rule
        }
    },
    methods:{
        resetPassword(){
            this.$v.form.$touch();
    		// if its still pending or an error is returned do not submit
    		if (this.$v.form.$pending || this.$v.form.$error){
    			return;
    		}
            this.restApi.forgotpassword(this.form).then((res)=>{
                this.$swal({
                   type: "success",
               title: 'Success',
               text: res.data.message,
               })
               this.form={}
           });
        }
    }

}
</script>

<style lang="css" scoped>
</style>
