<template>
	<div>
		<div v-if="mainPage">
			<!-- table list -->
			<div v-if="showList" class="container pd-x-0">
				<div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
					<div>
						<breadcrum first="Properties" second="Home"></breadcrum>
						<h4 class="mg-b-0 tx-spacing--1">Home Properties</h4>
					</div>
					<div class="d-none d-block">
						<router-link tag="button" :to="{name:'homenew'}"  class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
							<i  class="fa fa-plus wd-10 mg-r-5"></i> Add New
						</router-link>
					</div>
				</div>
				<div class="row">
					<div class="col-md-4">
							<div class="form-group">
								<input type="text" class="form-control" placeholder="Search by title" v-model="search">
							</div>
					</div>
				</div>
				<div class="table-responsive">
									<table class="table table-sm mytable">
										<thead class="text-center" >
											<th >Status</th>
											<th >Date</th>
											<th >Property Title</th>
											<th >Location</th>
											<th >Price</th>
											<!-- <th >Featured Status</th> -->
											<th >Action</th>
										</thead>
										<tbody class="text-center">
											<tr v-for="(property,index) in filterProperties" :key="index" class="set-height leftBorder">
												<td style="width:10%"><label
													class="badge"
													:class="[property.property_published == 1?'badge-success':'badge-danger']"
												>{{(property.property_published == 1)?'Published':'Pending'}}</label></td>
												<td>{{property.created_at}}</td>
												<td style="width:15%"><div class="ct">{{property.property_name}}</div></td>
												<td style="width:25%">{{property.property_full_address}}</td>
												<td style="width:15%">{{property.property_price_raw | currency}}</td>
											<!-- <td style="width:10%">{{property.featured_status}}</td> -->
												<td style="width:10%">
													<router-link tag="a" class="" :to="{name:'homeview',params:{slug:property.slug}}" > <i class="fa fa-eye text-success" style="font-size:20px"></i></router-link>&nbsp;&nbsp;
															<!-- <i class="btn btn-success text-white btn-xs" @click="editProperty(property.slug)"><i class="fa fa-eye"></i></a> -->
												<a class="" @click="deleteProperty(property)">
													<i class="fa fa-trash text-danger"  style="font-size:20px"></i>
												</a>
												</td>
											</tr>

										</tbody>
									</table>
									<nav aria-label="Page navigation example">
										<span> Page {{pagination.current_page}} of {{pagination.last_page}}</span>
										<ul class="pagination pagination-circle mg-b-0 justify-content-end">
											<li class="page-item " :class="[!pagination.prev_page_url?'disabled':'']"><a class="page-link page-link-icon" @click="fetchPropertiesByPagination(pagination.prev_page_url)"><i class="fa fa-chevron-left"></i></a></li>

											<li class="page-item " :class="[pagination.current_page==p?'active':'']" v-for="p in pagination.last_page" :key="p"><a class="page-link" @click="fetchPropertiesByPaginationTwo(p)">{{p}}</a></li>
											<li class="page-item" :class="[!pagination.next_page_url?'disabled':'']"><a class="page-link page-link-icon" @click="fetchPropertiesByPagination(pagination.next_page_url)"><i class="fa fa-chevron-right"></i></a></li>
										</ul>
									</nav>

								</div>


				 <!-- <my-table :data="pagi_data" :action_one="action_one" :action_two="action_two" :pagination="pagination">
					<div slot="table-body">
						<thead>
							<th >Status</th>
							<th >Date</th>
							<th >Property Title</th>
							<th >Location</th>
							<th >Price</th>
							<th >Featured Status</th>
							<th >Action</th>
						</thead>
						<tbody>
							<tr v-for="(property,index) in filterProperties" :key="index" class="set-height">
								<td><label
									class="badge"
									:class="[property.property_published == 1?'badge-success':'badge-danger']"
								>{{(property.property_published == 1)?'Published':'Pending'}}</label></td>
								<td>{{property.created_at}}</td>
								<td><div class="ct">{{property.property_name}}</div></td>
								<td>{{property.property_full_address}}</td>
								<td>{{property.property_price_raw | currency}}</td>
							<td>{{property.featured_status}}</td>
								<td>
									<router-link tag="a" class="btn btn-success text-white btn-xs" :to="{name:'homeview',params:{slug:property.slug}}" > <i class="fa fa-eye"></i></router-link>&nbsp;

								<a class="btn btn-danger text-white btn-xs" @click="deleteProperty(property)">
									<i class="fa fa-trash "></i>
								</a>
								</td>
							</tr>

						</tbody>
					</div>
				</my-table> -->

			</div>
		</div>
	</div>
</template>
<script>
import Mytable from '../../../components/Mytable'
export default {
	inject: [ "eventBus", "restApi"],
	components:{'my-table':Mytable},

	data() {
		return {
			loadingWizard: false,
			showList: true,
			editPage: false,
			mainPage: true,
			property: {},
			search:'',
			pagination:[],
			page_row:1,
			thead:['Status','Date','Property Title','Location','Price','Featured Status','Action'],
			pagi_data:'', action_one:'propertyListAction',action_two:'propertyListAction2'

		};
	},

	computed: {
		// properties() {
		// 	// console.log("ho: " + this.$store.state.homemodule.properties);
		// 	this.properties = this.$store.state.homemodule.properties;
		// 	// return this.$store.state.homemodule.properties;
		// },
		filterProperties(){
				this.getPaginatedData(this.$store.state.homemodule.pagination_data);
				this.pagi_data = this.$store.state.homemodule.pagination_data;

			return this.$store.state.homemodule.properties.filter((property)=>{
				return this.capitalizeFirst(property.property_name).match(this.search);
			})

		},
		hometypeName(name){
			let n = this.name.split("+");
			let real = n[0];
			return real
		},
	},
	methods: {
		editProperty(slug) {
			// console.log(property.property_name)
			this.$store.dispatch("showPropertyAction",slug);
			this.$router.push({name:'homeview',params:{slug:property.slug}})
		},
		deleteProperty(property){
				this.$swal({
						title: 'Confirmation',
						text: "Are you sure ?",
						type: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Yes, delete it!'
					}).then((result) => {
						if (result.value) {
							this.$store.dispatch("deletePropertyAction",property.slug).then((res)=>{
								this.$store.dispatch("propertyListAction");
									this.$swal(
								'Deleted!',
								'Your file has been deleted.',
								'success'
							)

							})

						}
					})

		},
		onComplete: function(){
      alert('Yay. Done!');
   },
   setLoading: function(value) {
       this.loadingWizard = value
   },
   handleValidation: function(isValid, tabIndex){
      console.log('Tab: '+tabIndex+ ' valid: '+isValid)
   },
   validateAsync:function() {
     return new Promise((resolve, reject) => {
       setTimeout(() => {
         resolve(true)
       }, 1000)
     })
		},
		capitalizeFirst(s){
						  if (typeof s !== "string") return "";
              return s.toLowerCase();
		},

		getPaginatedData(data){
			let pagination = {
				current_page : data.current_page,
				last_page : data.last_page,
				next_page_url : data.next_page_url,
				prev_page_url : data.prev_page_url,
				total:data.total,
				par_page : data.per_page
			}

			this.pagination = pagination;

				this.page_row = this.pagination.total/this.pagination.per_page;
				console.log(this.pagination.total/this.pagination.per_page)
					// this.$store.dispatch("propertyListAction",page);
		},
		fetchPropertiesByPagination( url){
				console.log(url)
			this.$store.dispatch("propertyListAction", url);
		},
		fetchPropertiesByPaginationTwo( page){
			this.$store.dispatch("propertyListAction2", page);
		}
	},
	created() {
		this.$store.dispatch("propertyListAction");

	}
};
</script>
<style media="screen" scope>


.leftBorder{
	border-left: 2px solid green;
}
/* .ct{
	font-size:12px; height:20px; vertical-align:middle;
	width: 300px;
    max-height:1.5em;
	overflow:hidden;
} */
/* td.container > div {
    width: 100%;
    height: 100%;
    overflow:hidden;
} */
</style>
