<template>
  <div>
     <div class="home-slider">
			<div class="home-lead">
					<div class="sign-wrapper ">
						<div class="wd-100p" >
								<h3 class="tx-color-01 mg-b-5">Sign In</h3>
								<p class="tx-color-03 tx-16 mg-b-20">Welcome back! Please signin to continue.</p>
								<form @submit.prevent="login()">
										<div class="form-group">
											<input type="email" class="form-control" placeholder="yourname@yourmail.com" v-model="loginform.email" name="email" :class="{ 'is-invalid': $v.loginform.email.$error }">
                                            <div v-if="!$v.loginform.email.required" class="invalid-feedback">Valid email is required</div>
										</div>
										<div class="form-group">
											<input type="password" class="form-control" placeholder="Enter your password"  name="password" v-model="loginform.password" :class="{ 'is-invalid': $v.loginform.password.$error }">
                                            <div v-if="!$v.loginform.password.required" class="invalid-feedback">Password is required</div>

										</div>
                                        <div class="row">
                                            <a  @click="goForgot()" class="link-url forgot-link">Forgot password ?</a>
                                        </div>
										<button type="submit" class="btn btn-brand-02 btn-block"><i class="fa fa-spinner" v-if="isLoading"></i> <span v-if="!isLoading">Sign In</span></button>
								</form>

								<div class="tx-13 mg-t-20 tx-center">Don't have an account? <a  @click="goRegister()" class="link-url">Create an Account</a></div>

						</div>

                    </div><!-- sign-wrapper -->

			</div>
				<div class="home-slider-img">
						<div><img src="../../assets/img/ill.jpg" alt=""></div>
                  <div><img src="../../assets/img/ill.jpg" alt=""></div>
                  <div><img src="../../assets/img/ill.jpg" alt=""></div>
						<!-- <div><img src="../../assets/img/home-2.png" alt=""></div>
						<div><img src="../../assets/img/home-2.png" alt=""></div> -->
				</div>
      	<!-- <div class="home-slider-bg-one"></div> -->
	</div>

  </div>

</template>

<script>
// @ is an alias to /src
import { required, email, minLength, sameAs,requiredUnless,requiredIf } from "vuelidate/lib/validators";
import {mapGetters, mapState} from 'vuex'
export default {
    inject:["eventBus","restApi"],
  name: 'Login',
  components: {
    // HelloWorld
  },
  data(){
    return{
      showLogin:true,
      isLoading:false,
        loginform:{
            email:'',
            password:''
        }

        }
	},

	computed:{
			 loginrule(){
                 return{
                     email:{required},
                     password:{required,minLength: minLength(6)}
                }
             },

	},
    validations() {
        return{
            loginform:this.loginrule
        }
    },

  methods:{
    toggleForm(){
      this.showLogin = !this.showLogin;
    },
    goRegister(){
        this.$router.push({name:'register'})
    },
    goForgot(){
        this.$router.push({name:'forgot'});
    },
	login(){
        this.$v.loginform.$touch();
		// if its still pending or an error is returned do not submit
		if (this.$v.loginform.$pending || this.$v.loginform.$error){
			return;
		}
        // this.isLoading = true;
        this.$store.dispatch('login',this.loginform).then((r)=>{
            this.isLoading = false;
                // window.location.href="/dashboard"

            // }

    }).catch((err)=>{
        // this.isLoading = false;
        if(err == false){
            this.$swal({
               type: "error",
               title: 'Oop...',
               text: 'Invalid email or password',
           })
        }
    })

	},
		created(){

		},
		mounted() {

		},

  }
}
</script>
<style scoped>
.forgot-link{
    margin:1px 10px 10px 20px;
}

</style>
