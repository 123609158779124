<template lang="html">
    <div class="">
         <breadcrum first="Account" second="Profile"></breadcrum>
        <div class="row">
            <div class="col-md-8">
                <div class="card info_Card">
                    <div class="row b_border">
                        <div class="col-md-8">
                           <img :src="imageUrl+profile.developer_logo" alt=""
                           :style="{width:'150px',height:'150px',borderRadius:'50%'}" >
                            <!-- <div class="dev_logo" :style="{backgroundImage:'url('+imageUrl+profile.developer_logo+')'}"> -->

                            <!-- </div> -->
                        </div>
                        <div class="col-md-4">
                            <h6>{{profile.developer_license}}</h6>
                        </div>
                    </div>
                    <div class="coy_info">
                        <div class="row">
                            <div class="pull-left" style="width:80%">
                                <h6>Company Info</h6>
                            </div>
                            <div class="label bg-success text-success pull-right text-center" style="width:20%" v-if="dev_status == 1">
                                <span style="color:#fff;">Active</span>
                            </div>
                            <div class="label bg-danger text-success pull-right text-center" style="width:20%" v-if="dev_status == 0">
                                <span style="color:#fff;">Pending</span>
                            </div>
                        </div>
                        <form @submit.prevent="updateProfile()" data-vv-scope="profile">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="">Developer Name</label>
                                        <input type="text" name="name" class="form-control" v-model="form.developer_name" v-validate="'required'" data-vv-scope="profile"
                                        :class="{'form-control': true, 'is-invalid': errors.has('profile.name') }">
                                        <p v-if="errors.has('profile.name')" class="invalid-feedback">{{ errors.first('profile.name') }} </p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="">Developer Email</label>
                                        <input type="text" name="email" class="form-control" v-model="form.developer_email" v-validate="'required'" data-vv-scope="profile"
                                        :class="{'form-control': true, 'is-invalid': errors.has('profile.email') }">
                                        <p v-if="errors.has('profile.email')" class="invalid-feedback">{{ errors.first('profile.email') }} </p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="">Developer Phone</label>
                                        <input type="text" name="phone" class="form-control" v-model="form.developer_phone" v-validate="'required'" data-vv-scope="profile"
                                        :class="{'form-control': true, 'is-invalid': errors.has('profile.phone') }">
                                        <p v-if="errors.has('profile.phone')" class="invalid-feedback">{{ errors.first('profile.phone') }} </p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="">Developer Country</label>
                                        <input type="text" name="country" class="form-control" v-model="form.developer_country" 
                                        v-validate="'required'" data-vv-scope="profile":class="{'form-control': true, 'is-invalid': errors.has('profile.country') }">
                                        <p v-if="errors.has('profile.country')" class="invalid-feedback">{{ errors.first('profile.country') }} </p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="">Developer Address</label>
                                        <vue-google-autocomplete ref="address" id="map" classname="form-control" placeholder="Please type your address" v-on:placechanged="getAddressData" >
            
                                                    </vue-google-autocomplete>
                                        <p v-if="errors.has('address')" class="invalid-feedback">{{ errors.first('address') }} </p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="">Developer State</label>
                                        <input type="text" name="state" class="form-control" v-model="form.developer_state" v-validate="'required'" data-vv-scope="profile"
                                        :class="{'form-control': true, 'is-invalid': errors.has('profile.state') }">
                                            <p v-if="errors.has('profile.state')" class="invalid-feedback">{{ errors.first('profile.state') }} </p>
                                    </div>
                                </div>
                          
                            </div>
                            <div class="row">
                                <div class="col-md-4 col-md-offset-4">
                                    <button type="submit" class="btn btn-primary btn-block" > Update</button>
                                </div>
                            </div>
                        </form>
                        
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card other_info_Card">
                        <div class="row">
                            <div class="col-md-10 col-md-offset-1 text-center">
                                <span style="font-weight:bold">Change Password</span>
                            </div>
                        </div>
                        <form>
                            <div class="row">
                                 <div class="col-12">
                                    <div class="form-group">
                                         <label for="">Old Password</label>
                                        <input type="password" name="password" class="form-control" v-model="password.oldpassword" v-validate="'required'" data-vv-scope="password" :class="{'form-control': true, 'is-invalid': errors.has('password.password') }"
                                             >
                                             <p v-if="errors.has('password.password')" class="invalid-feedback">{{ errors.first('password.password') }} </p>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                         <label for="">New Password</label>
                                        <input type="password" name="newpassword" class="form-control" v-model="password.newpassword" v-validate="'required'" data-vv-scope="password" :class="{'form-control': true, 'is-invalid': errors.has('password.newpassword') }"
                                             >
                                             <p v-if="errors.has('password.newpassword')" class="invalid-feedback">{{ errors.first('password.newpassword') }} </p>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <p v-if="notmatch" style="color:red;">Password not match</p>
                                         <label for="">Confirm Password</label>
                                        <input type="password" name="confirmpassword" class="form-control" v-model="confirm" v-validate="'required'" data-vv-scope="password" :class="{'form-control': true, 'is-invalid': errors.has('password.confirmpassword') }">
                                         <p v-if="errors.has('password.confirmpassword')" class="invalid-feedback">{{ errors.first('password.confirmpassword') }} </p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                       <div class="col-md-6 col-md-offset-3">
                          <button class="btn btn-primary btn-block" @click.prevent="changePassword()"> Update</button>
                       </div>
                    </div>
                        </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required, email, minLength, sameAs, requiredUnless, requiredIf } from "vuelidate/lib/validators";
export default {
    inject:['imgUrl'],
    data(){
      return{
         form:{
            developer_name:'',
            developer_email:'',
            developer_phone:'',
            developer_address:'',
            developer_state:'',
            developer_country:''
         },
         password:{
             newpassword:'',
             oldpassword:'',
             email:''
         },
         confirm:'',
         notmatch:false
      }
   },
    computed:{
      imageUrl(){
			return this.imgUrl;
		},
        profile(){
           let info =this.$store.state.authmodule.developerprofile;
           this.form.developer_name = info.developer_name
            this.form.developer_email = info.developer_email
             this.form.developer_phone = info.developer_phone
              this.form.developer_country = info.developer_country
                this.form.developer_state = info.developer_state
                  this.form.developer_address = info.developer_address
                  
                  this.password.email = info.developer_email
            return info;
        },
        dev_status(){
            let info =this.$store.state.authmodule.developerprofile;
            if(info.developer_published == 1){
                return true;
            }
            return false;
        }
    },
    methods:{
        getProfileInfo(){
            this.$store.dispatch("developerInfoAction")
        },
        getAddressData: function(addressData, placeResultData, id) {
			// this.address = addressData;locality  route street_number

            this.form.developer_state = addressData.locality
            this.form.developer_address = placeResultData.name;
			// console.log('result', placeResultData)
			// 	console.log('street', placeResultData.name)
			// console.log('neighbor', placeResultData.vicinity)
			// console.log('address',addressData.route)
			// console.log('state',addressData.locality)
        },
        updateProfile(){
            this.$validator.validateAll("profile").then(valid => {
                if (valid) {
                    this.$store.dispatch("updateProfileAction",this.form).then((r)=>{
                            this.$swal({
                                title: 'Success',
                                text: "Profile updated successfully",
                                type: 'success',
                                confirmButtonText: 'Ok'
                            }).then((result) => {
                                    if (result.value) {}
                                
                            });
                        });
                }
            });
        },
        changePassword(){
            if(this.confirm != this.password.newpassword){
                this.notmatch = true;
            }else{
                  this.notmatch = false;
                this.$validator.validateAll("password").then(valid => {
                    if (valid) {
                        this.$store.dispatch("changePasswordAction",this.password).then((r)=>{
                            this.$swal({
                                title: 'Success',
                                text: "Password change successfully",
                                type: 'success',
                                confirmButtonText: 'Ok'
                            }).then((result) => {
                                    if (result.value) {}
                                
                            });
                        });
                    }
                });
            }
        }
    }
}
</script>

<style lang="css" scoped>
.coy_info{
    margin:10px 0;
    padding:10px;
}
.card{
    border-radius: 5px;
}
    .info_Card{
        width:100%;
        padding:20px;
    }
    .other_info_Card{
        width:100%;
        padding:20px;
    }
    .b_border{
        border-bottom: 1px dotted #ddd;
    }
    .dev_logo{
        width: 150px;
        height: 150px;
        border-radius:50%;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
    }
</style>
