<template>
		<div class="bg-danger text-white text-center p-3 h3">
			An Error has occurred
			<h6>{{message}}</h6>
			<a href="/" class="btn btn-secondary" >Ok</a>
		</div>
</template>
<script>
export default {
	 data(){
		 return{
			 error:false,
			 message:''
		 }
	 },
	 inject:['eventBus'],
	 methods:{
		 handleError(err){
			 this.error = true;
			 this.message = err;
		 }
	 },
	 created(){
		 this.eventBus.$on("HttpError", this.handleError);
	 }
}
</script>
<style scoped>
	
</style>
