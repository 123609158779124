<template>
	<div>
		<!-- add form -->
		<div class="container pd-x-0">
			<div class="d-sm-flex align-items-center justify-content-between mg-b-10 mg-lg-b-10 mg-xl-b-20">
				<div>
					<breadcrum first="Properties" second="New Plot"></breadcrum>
					<h4 class="mg-b-0 tx-spacing--1">Add New Plot</h4>
				</div>
				<div class="d-none d-block">
					<router-link
						tag="button"
						:to="{name:'plottable'}"
						class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"
					>
						<i class="fa fa-eye wd-10 mg-r-5"></i> View List
					</router-link>
				</div>
			</div>
			<!-- step indictaiors -->
			<div class="row">
				<div class="col-1"></div>
				<div class="col-10 align-content-center">
					<div class="align-items-center">
						<ul class="steps">
							<li class="step-item" :class="[step==1?'active':'',isCompleted?'complete':'']">
								<a @click="goToStep(1)" class="step-link">
									<span class="step-number">1</span>
									<span class="step-title">Plot Info</span>
								</a>
							</li>
							<li class="step-item" :class="[step==2?'active':'',isCompleted?'complete':'']">
								<a @click="goToStep(2)" class="step-link">
									<span class="step-number">2</span>
									<span class="step-title">Plot Location</span>
								</a>
							</li>
							<li class="step-item" :class="[step==3?'active':'',isCompleted?'complete':'']">
								<a @click="goToStep(3)" class="step-link">
									<span class="step-number">3</span>
									<span class="step-title">Plot Description</span>
								</a>
							</li>
							<li class="step-item" :class="[step==4?'active':'',isCompleted?'complete':'']">
								<a @click="goToStep(4)" class="step-link">
									<span class="step-number">4</span>
									<span class="step-title">Plot Gallery</span>
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div class="col-1"></div>
			</div>
			<hr />
			<div class="row ">
				<div class="col-md-10 offset-md-1">
					<form  enctype="multipart/form-data">
						<div class="form_area">
								<section v-if="step==1">
										<div class="row">
											<div class="col-md-4">
												<div class="form-group">
													<label for="name">Plot Name</label>
													<input type="text" class="form-control" placeholder="property name" v-model="form.plot_name"
													:class="{ 'is-invalid': $v.form.plot_name.$error }">
														<div v-if="!$v.form.plot_name.required" class="invalid-feedback">Plot name is required</div>
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<label for="t">select currency</label>
													<div class="input-group">
														<div class="input-group-prepend">

														</div>
														<select name id="currencyid" class="form-control" v-model="selected_currency" @change="updateSymbol()" :class="{ 'is-invalid': $v.form.base_currency.$error }" >
																<option label="Choose one" ></option>
																<option :value="c.currency_code+'+'+c.currency_symbol" v-for="(c , index) in currency" :key="index"  >{{c.currency_code}}</option>
															</select>
														<input type="text"  v-model="form.currency_symbol" class="form-control" disabled id="currency_symbol" :class="{ 'is-invalid': $v.form.base_currency.$error }"/>
														<div v-if="!$v.form.base_currency.required" class="invalid-feedback">select plot base currency</div>
													</div>
												</div>
											</div>
											<div class="col-md-4">
													<div class="form-group">
													<label for="name">Price</label>
													<input type="text" class="form-control" placeholder="property square metre" v-model="form.plot_price_raw"
														:class="{ 'is-invalid': $v.form.plot_price_raw.$error }">
													<div v-if="!$v.form.plot_price_raw.required" class="invalid-feedback">Priceis required</div>
													</div>
											</div>
											<div class="col-md-4">
													<div class="form-group">
													<label for="name">SQM</label>
													<input type="text" class="form-control" placeholder="property square metre" v-model="form.plot_size"
														:class="{ 'is-invalid': $v.form.plot_size.$error }">
													<div v-if="!$v.form.plot_size.required" class="invalid-feedback">Plot sizeis required</div>
													</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<label class="form-control-label">Payment plan<span class="tx-danger">*</span></label>
													<select class="form-control"  name=" payment_option" required v-model="form.payment_option"
													:class="{ 'is-invalid':is_payment_plan&& $v.form.payment_option.$error }">
														<option label="Choose one" ></option>
														<option value="1 year" >One year</option>
														<option value="6 months" >Six Month</option>

													</select>
													<div v-if="!$v.form.payment_option.required " class="invalid-feedback">select property finance option</div>
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<label class="form-control-label">Building Permit<span class="tx-danger">*</span></label>
													<select class="form-control" name="building_permit" required v-model="form.building_permit"
														:class="{ 'is-invalid': $v.form.building_permit.$error }">
														<option label="Choose one" ></option>
														<option value="1">Yes</option>
														<option value="0">No</option>
													</select>
														<div v-if="!$v.form.building_permit.required" class="invalid-feedback">selection is required</div>
												</div>
											</div>
											<div class="col-md-12">
													<label class="form-control-label">Property documents<span class="tx-danger">*</span></label>
													<div class="row">
														<div class="col-md-4" v-for="(a, index) in titledoc" :key="index">
																<div class="custom-control custom-radio">
																	<input type="radio" :id="a.title" name="customRadio" class="custom-control-input" v-model="form.title_docs" :value="a.title" :class="{ 'is-invalid': $v.form.title_docs.$error }">
																	<label class="custom-control-label" :for="a.title" >{{a.title}}</label>
																</div>
																<div v-if="!$v.form.title_docs.required" class="invalid-feedback">selection is required</div>
														</div>
													</div>
											</div>

										</div>
								</section>
								<section v-if="step==2">
										<div class="row">
											<div class="col-md-4">
													<div class="form-group">
															<label for="country" class="form-control-label">Country <span class="tx-danger">*</span></label>
												<select class="form-control  cloneme" name="property_country" id="country"  v-model="form.plot_country" 	:class="{ 'is-invalid': $v.form.plot_country.$error }">
													<option label="Choose one"></option>
													<option v-for="(c, index) in country" :key="index" :value="c.name">{{c.name}}</option>
												</select>
												<div v-if="!$v.form.plot_country.required" class="invalid-feedback">plot country is required</div>
													</div>
											</div>
											<div  class="col-md-4">
												<div class="form-group">
													<label class="form-control-label">Plot Full Address: <span class="tx-danger">*</span></label>
												<!-- <input id="autocomplete" class="form-control" name="property_address" placeholder="Enter plot address"  type="text" required v-model="form.plot_address" :class="{ 'is-invalid': $v.form.plot_address.$error }"> -->
												<vue-google-autocomplete
													ref="address"
													id="map"
													classname="form-control"
													placeholder="Please type your address"
													v-on:placechanged="getAddressData"
											  >
											  </vue-google-autocomplete>
												<div v-if="!$v.form.plot_address.required" class="invalid-feedback">plot full address is required</div>
												</div>

											</div>
											<div class="col-md-4">
												<div class="form-group">
													<label class="form-control-label">Property City<span class="tx-danger">*</span></label>
												<input class="form-control" name="property_city" type="text" id="locality" placeholder="Enter property city" required v-model="form.plot_city" :class="{ 'is-invalid': $v.form.plot_city.$error }">
												<div v-if="!$v.form.plot_city.required" class="invalid-feedback">plot city is required</div>
												</div>
											</div>
											<div class="col-md-4">
													<div class="form-group">
															<label class="form-control-label">Plot State<span class="tx-danger">*</span></label>
												<input class="form-control" name="property_state" type="text" id="administrative_area_level_1" placeholder="Enter property state" required v-model="form.plot_state" :class="{ 'is-invalid': $v.form.plot_state.$error }">
												<div v-if="!$v.form.plot_state.required" class="invalid-feedback">plot state is required</div>
													</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
															<label class="form-control-label">Plot Neighborhood <span class="tx-danger">*</span></label>
												<input class="form-control" name="property_neighborhood" placeholder="eg VI" type="text" v-model="form.plot_neighborhood" :class="{ 'is-invalid': $v.form.plot_neighborhood.$error }" >
												<div v-if="!$v.form.plot_neighborhood.required" class="invalid-feedback">property neighborhood is required</div>

												</div>

											</div>
										</div>
								</section>
								<section v-if="step==3">
										<div class="row">
											<div class=" col-md-12 ">
												<label class="form-control-label">Plot Description <span class="tx-danger">*</span></label>
														<ckeditor :editor="editor" v-model="form.plot_description" :config="editorConfig" :class="{ 'is-invalid': $v.form.plot_description.$error }"></ckeditor>
												<div v-if="!$v.form.plot_description.required" class="invalid-feedback">plot description is required</div>
											</div>
											<div class="col-md-12 az-content-label  mg-t-40">Featured Amenities</div>
											<div class=" col-md-3" v-for="(a, index) in amenity" :key="index">
												<div class="form-group">
													<div class="custom-control custom-checkbox" >
															<input type="checkbox" class="custom-control-input" name="amenities[]" :id="a.id" :value="a.id" v-model="form.amenities" >
															<label class="custom-control-label" :for="a.id">{{a.name}}</label>
													</div>
												</div>
											</div>
										</div>

								</section>

								<section v-if="step==4">
									<div class="row">
										<div class="col-3"></div>
										<div class="col-6">
											<vue-upload-multiple-image
												@upload-success="uploadImageSuccess"
												@before-remove="beforeRemove"
												@edit-image="editImage"
												dragText="Click to upload"
												:browseText="browseText"
												primaryText="Active"
    										popupText="Your file upload should not exceed 5"
												markIsPrimaryText="File"
												accept="image/jpeg,image/png,image/jpg"
												:max-image="maxImage"

												@limit-exceeded="exceedImageLimit"></vue-upload-multiple-image>

												<!-- <button type="button" class="btn btn-primary" @click.prevent="openFileWindow" >Uplaod</button>
												<input type="file" class="form-control" id="customFile" multiple @change="onFileChange" style="display:none" ref="windowOpen" accept="image/*"/>
												<div >
														<div v-for="(image, index) in images" :key="index">
																<img class="preview" :src="image" />
														</div>
												</div> -->
										</div>
										<div class="col-3"></div>

									</div>
								</section>
						</div>

						<!-- step button -->
						<div class="row ">
							<div class="mg-l-auto  ">
									<button
											v-if="step !=1"
											type="button"
											class="btn btn-primary step_buttons"
											@click.prevent="prevBtn"
										>Back</button>
										<button
											v-if="step != totalStep"
											type="button"
											class="btn btn-primary step_buttons"
											@click.prevent="nextBtn"
										>Next</button>
										<button
											v-if="step == totalStep"
											type="submit"
											class="btn btn-primary step_buttons"
											@click.prevent="submitBtn"
										>Submit</button>
							</div>



						</div>

					</form>

				</div>
			</div>
		</div>
	</div>
</template>
<script>
import {propertyMixin} from '../../../mixins/properties'
import {plotMixin} from '../../../mixins/plot'
 import { required, email, minLength, sameAs,requiredUnless,requiredIf } from "vuelidate/lib/validators";
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
	inject: [ "eventBus", "restApi","toast"],
	mixins:[propertyMixin,plotMixin],
	data() {
		return {
			 editor: ClassicEditor,
			editorConfig: { },
			isActive: false,
			isCompleted: false,
			submitted: false,
			editHometype:false,
			maxImage:parseInt(6),
			images:[],
			selectedFile:'',
			browseText:'Maximum of 6 files',
			selected_currency:'',
			form:{
				base_currency:'',
				currency_symbol:'',
				plot_name:'',
				plot_size:'',
				plot_country:'',
				plot_state:'',
				plot_city:'',
				plot_neigborhood:'',
				plot_address:'',
				plot_description:'',
				plot_price_raw:'',
				plot_issold:'',
				title_docs:'',
				payment_option:'',
				bulding_permit:'',
				amenities:[],
				photo:[],
				property_type:[]
			},

		};
	},
	computed:{
		rules(){
			switch(this.step){
				case 1:
					return {	base_currency:{ required },
								plot_name:{ required },
								plot_size:{ required },
								plot_price_raw:{required},
								payment_option:{required},
								title_docs:{required},
								payment_option:{required},
								building_permit:{required}


					}
					break;
				case 2:
					return {
						plot_country: { required },
						plot_state: { required },
						plot_city: { required },
						plot_neighborhood: { required },
						plot_address: { required }
					}
					break;
				case 3:
					return {
						plot_description: { required }
					}
					break;
				case 4:
					return{
					photo:{required}


				}
				break;

			}
		},


		},
	validations() {
		return{
					form:this.rules,
		}

	},

	methods: {

		updateSymbol(){
			let s = this.selected_currency.split("+");
			this.form.base_currency = s[0];
			this.form.currency_symbol=s[1];

		},
		onFileChange(e){
			var selectedFiles = e.target.files;
					for (var i=0; i < selectedFiles.length; i++){

						let filename = selectedFiles[i].name;
						if(filename.lastIndexOf('.') <=0){
							return alert("one of your image is not valid");
						}
						const reader = new FileReader();
						reader.addEventListener('load', ()=>{
								this.images.push(reader.result);
								reader.readAsDataURL(selectedFiles[i]);
						})

						this.form.photo.push(selectedFiles[i]);
						// let p = URL.createObjectURL(selectedFiles[i]);


					}
					console.log(this.form.photo)
		},
		submitBtn() {
			if (this.$v.form.$pending || this.$v.form.$error){
				this.$swal({
				   type: "error",
				   	title: 'Oop...',
			   		text: 'Some required fields are empty, '
			   })
 				return;
		  	}
			this.$store.dispatch("addPlotAction",this.form).then((res)=>{
				console.log(res);
				this.$store.dispatch("plotListAction");
					this.$router.replace({name:'plottable'})
				this.form
					this.$swal({
						type: "success",
					title: 'Congratulation',
  					text: res+'. It will be reviewed for approval '
					}).then((result) => {
					if (result.value) {

					}
					})
					// this.form
			}).catch((err)=>{
					 this.$swal({
						type: "error",
					title: 'Oop...',
  			text: err,
					})
			})
		},
		showModal() {
					$("#property_type").modal("show");


		},
		addPropertyType(){

			let id = this.property_type_form.id +=1;
			this.form.property_type.push(this.property_type_form)

			$("#property_type").modal("hide");
			this.property_type_form = {}
			// if(this.form.is_estate != 1){
			// 	this.showTypeButton = false;
			// }else{
			// 	this.showTypeButton = true;
			// }
		},
		updatePropertyType(){
			$("#property_type").modal("hide");
			this.property_type_form = {}
			console.table(this.form.property_type);
			this.editHometype=false;
		},
		removePropertyType(index){
			this.form.property_type.splice(index,1);
		},
		editPropertyType(index){
			console.table(	this.form.property_type[index])
			this.editHometype = true;
			this.property_type_form = this.form.property_type[index]
				$("#property_type").modal("show");

		},
		openFileWindow(){
			this.$refs.windowOpen.click();
		},
		//  onFileChange(e) {
    //   const file = e.target.files[0];
    //   this.form.property_image = URL.createObjectURL(file);
		// },
		 uploadImageSuccess(formData, index, fileList) {

			fileList.forEach((r)=>{
				// console.log(r);
				this.form.photo.push(r);

			});
			// this.form.photo.pop();
    },
    beforeRemove (index, done, fileList) {
      console.log('index', index, fileList)
      var r = confirm("remove image")
      if (r == true) {
        done()
      } else {
      }
    },
    editImage (formData, index, fileList) {
      console.log('edit data', formData, index, fileList)
		},
		exceedImageLimit(amount){
			alert("you selected "+amount+"files which exceed the limit")
		},
		getAddressData: function (addressData, placeResultData, id) {
			this.form.plot_city = placeResultData.vicinity;
			this.form.plot_address = placeResultData.name;
			this.form.plot_state = addressData.locality
			this.form.plot_neigborhood = placeResultData.vicinity
		},
	created() {
		this.$store.dispatch("propertyListAction");
	},
	mounted() {
		 // this.$refs.address.focus();
  		}
	}
}
</script>
<style scoped>
#currency_symbol{
	font-weight: bold;
	align-content: center;
	/* font-size:1.5rem; */
}
.form_area{
	/* height: 20.5rem; */

	padding:1rem;
	margin-bottom: 2rem
}
.step_buttons{
	border-top:1px solid #f9f9f9;
	width:8rem;
	margin-right: 1.5rem;
}
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 200px;
}

</style>
