<template lang="html">
    <div class="">
        <breadcrum first="Leads" second="Enquiries"></breadcrum>
        <h4 class="mg-b-20 tx-spacing--1">Property Enquiries</h4>

        <div class="table-responsive">
            <table class="table mytable">
                <thead class="text-left">

                    <th >Date</th>
                    <th >Property Title</th>
                    <th >Name</th>
                    <th >Email</th>
                    <th >Phone</th>
                    <th >Action</th>
                </thead>
                <tbody>
                    <tr v-for="(property,index) in filterEnquiry" :key="index">
                        <!-- <td><label
                    class="badge"
                    :class="[property.property_published == 1?'badge-success':'badge-danger']"
                >{{(property.property_published == 1)?'Published':'Pending'}}</label></td> -->
                        <td >{{property.created_at | formatDate}}</td>
                        <td v-if="property.property !=null">{{property.property['property_name']}}</td>
                        <td v-if="property.property==null"></td>
                        <td>{{property.name}}</td>
                        <td>{{property.email}}</td>
                        <td>{{property.phone}}</td>
                        <!-- <td>{{maskData || index <= 5?property.email:'XXXXXXXXXX'}}</td>
                        <td>{{maskData || index <= 5?property.phone:'XXXXXXXXXX'}}</td> -->
                        <td>
                             <view-contact :contact="property" ></view-contact>
                              <!-- <template v-if="index <= 5">

                              </template>
                             <view-contact :contact="property" v-if="maskData || index < 5"></view-contact>
                             <sub-modal :info="Upgrade" v-if="!maskData && index >= 5"></sub-modal> -->

                        </td>
                    </tr>

                </tbody>
            </table>
            <nav aria-label="Page navigation example">
                <span> Page {{pagination.current_page}} of {{pagination.last_page}}</span>
                <ul class="pagination pagination-circle mg-b-0 justify-content-end">
                    <li class="page-item " :class="[!pagination.prev_page_url?'disabled':'']"><a class="page-link page-link-icon" @click="fetchPropertiesByPagination(pagination.prev_page_url)"><i class="fa fa-chevron-left"></i></a></li>

                    <li class="page-item " :class="[pagination.current_page==p?'active':'']" v-for="p in pagination.last_page" :key="p"><a class="page-link" @click="fetchPropertiesByPaginationTwo(p)">{{p}}</a></li>
                    <li class="page-item" :class="[!pagination.next_page_url?'disabled':'']"><a class="page-link page-link-icon" @click="fetchPropertiesByPagination(pagination.next_page_url)"><i class="fa fa-chevron-right"></i></a></li>
                </ul>
            </nav>
        </div>
        <!-- modal -->
        <div
            class="modal fade"
            id="viewinfo"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel6"
            aria-hidden="true">
            <div  class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content tx-14">
                    <div class="modal-header">
                        <h6 class="modal-title" id="exampleModalLabel6">Add Property Type</h6>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Subscribemodal from '../../../components/Subscribemodal'
import Viewcontactmodal from '../../../components/Viewcontactmodal'
export default {
    components:{'sub-modal':Subscribemodal,'view-contact':Viewcontactmodal},
    data(){
        return{
            pagination:[],
            page_row:1,
            Upgrade:"Upgrade",
            contact_info:{

            }
        }
    },
    computed:{
        filterEnquiry(){
            this.getPaginatedData(this.$store.state.leadmodule.enquiry_pagination_data);
            return this.$store.state.leadmodule.enquiries;
        },
        maskData(){
            if(this.$store.state.authmodule.developersubscription['status'] == 1){
                return true;
            }
            return false;
        }

    },
    methods:{
        getPaginatedData(data){
            let pagination = {
                current_page : data.current_page,
                last_page : data.last_page,
                next_page_url : data.next_page_url,
                prev_page_url : data.prev_page_url,
                total:data.total,
                par_page : data.per_page
            }
            this.pagination = pagination;
            this.page_row = this.pagination.total/this.pagination.per_page;
        },
        fetchPropertiesByPagination( url){
            this.$store.dispatch("enquiryListAction", url);
        },
        fetchPropertiesByPaginationTwo( page){
            this.$store.dispatch("enquiryListAction2", page);
        },
        getLatest(){
            this.$store.dispatch("enquiryListAction");
            this.$store.dispatch("developeSusbcriptionStatusAction")
        }
    },
    created() {
        this.getLatest()

	}
}
</script>

<style lang="css" scoped>
</style>
