<template lang="html">
    <div class="">
        <breadcrum first="Home" second="Account"></breadcrum>
        <div class="col-md-6 offset-md-3 parent">
            <div class="borderme">
                <p>Name: {{userinfo['name']}}</p>
                <p>Email: {{userinfo['email']}}</p>
            </div>
            <form @submit.prevent="changepassword()"  method="post">
                <label for=""> Change password</label>
                <div class="form-group">
                    <label for="">New Password</label>
                    <input type="password" v-model="form.password" class="form-control">
                </div>
                <div class="form-group">
                    <label for="">Confirm Password</label>
                    <input type="password" v-model="form.confirm_password" class="form-control">
                </div>
                <button type="submit" name="button">save</button>
            </form>
            {{userinfo}}
        </div>
    </div>
</template>

<script>
export default {
    inject: [ "eventBus", "restApi","imgUrl"],
    data(){
        return{
            form:{
                password:'',
                confirm_password:'',
            }
        }
    },
    computed:{
        userinfo(){
            let info =this.$store.state.authmodule.user;

            return this.$store.state.authmodule.user;
        },
    },
    methods:{
        getuserinfo(){
            return this.$store.state.authmodule.user;
        },
        updateProfile(){

        },
        changepassword(){
            this.restApi.changepassword(this.form).then((res)=>{
                console.log(res.data.data);
                this.$swal({
                    type: "success",
                title: 'Congratulation',
        text: res.data.data.message
                }).then((result) => {
                if (result.value) {
                    this.form = {}

                }
                })
            }).catch((err)=>{
                this.$swal({
                   type: "error",
               title: 'Oop...',
                   text: err.data.data.message,
               })
            })
        }
    },
    created(){
        this.getuserinfo()
    }
}
</script>

<style lang="css" scoped>
    .parent{
        padding:10px;

    }
</style>
