
export default function auth ({ next, store}){
    if(store.state.authmodule.isLoggedIn){
        return next({
           name: 'dashboard'
        })
    }

    return next()
   
}
