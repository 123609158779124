<template>
		<div class="content-header">
        <!-- <div class="content-search"> -->
          <!-- <i data-feather="search"></i>
          <input type="search" class="form-control" placeholder="Search...">
			</div> -->
			<h5 class="today_date">{{getHumanDate}}</h5>
				<div class="pull-right mg-l-30 clear-fix">
					<!-- <div class=" mg-b-0" role="alert" v-if="!maskData">
					  <sub-modal :info="upgrade_btn_info"></sub-modal>

					</div> -->
					 <!-- <h3>You are on Free plan  <span class="badge badge-pill badge-dark" >Upgrade</span></h3> -->
				</div>

			<!-- </div> -->

		</div>
</template>
<script>
import moment from 'moment';
import Subscribemodal from '../Subscribemodal'
export default {
	components:{'sub-modal':Subscribemodal},
	data(){
		return{
			upgrade_btn_info:"Upgrade to enjoy premium services"
		}
	},
	computed:{
		getHumanDate(){
			let date = new Date();
			 return moment(date).format('dddd,  Do MMMM, YYYY');
			  // return moment(date).format('dddd,  Do MMMM, YYYY, h:mm:ss a');
		},
		maskData(){
            if(this.$store.state.authmodule.developersubscription['status'] == 1){
                return true;
            }
            return false;
        }
	}
}
</script>
<style media="screen">
	.today_date{
		font-size:15px;
		font-style:italic;
	}
</style>
