export const stepFormMixin = {
		data() {
			return {
				step: 1,
				totalStep: 5,
				inValidStep:false,
				typeError:false
			};
		},

		methods: {
			goToStep(step){

				this.step = step;
			},
			nextBtn() {
				if (this.step === this.totalStep) {
				}else{
					this.$v.form.$touch();
		          // if its still pending or an error is returned do not submit
		          if (this.$v.form.$pending || this.$v.form.$error ||this.form.property_type.length <=0 ){
						 		if(this.form.property_type.length <= 0){
									this.typeError = true;
								}else{
									this.typeError = false;
								}
						return;
					}
					if(this.form.is_estate != 1 && this.form.property_type.length >1){
							this.form.property_type.splice(1);
							this.$swal({
							  type: "error",
						  		title: 'multiple Home Type',
				  				text: 'Single property cannot have multiple home type',
						  })
							return;
					}
					this.step++;
				}
			},
			prevBtn() {
				if (this.step == 1) {
					this.step = 1;
				} else {
					this.step--;
				}
			},
			validateStep(){
					this.$v.$touch();
          if (this.$v.$invalid) {
						this.inValidStep = true;
            return false;
          }
						this.inValidStep = false;
						return true;



			}
		}
};
