<template>
	<div>
		<header class="navbar navbar-header navbar-header-fixed">
      <a  id="mainMenuOpen" class="burger-menu"><i data-feather="menu"></i></a>
      <div class="navbar-brand">
        <!-- <a  class="df-logo">New<span>homes</span></a> -->
		   <a  class="df-logo"><img src="../../../assets/nhlogo2.png"></a>
      </div><!-- navbar-brand -->
      <div id="navbarMenu" class="navbar-menu-wrapper ">
        <div class="navbar-menu-header">
          <a  class="df-logo"><img src="../../../assets/nhlogo2.png"></a>
          <a id="mainMenuClose" href="#"><i data-feather="x"></i></a>
        </div><!-- navbar-menu-header -->
        <ul class="nav navbar-menu takeme-right ">
          <li class="nav-label pd-l-20 pd-lg-l-25 d-lg-none">Main Navigation</li>

          <!-- <li class="nav-item"><a  class="nav-link"><i data-feather="box"></i> Components</a></li>
          <li class="nav-item"><a  class="nav-link"><i data-feather="archive"></i> Collections</a></li> -->
        </ul>
      </div><!-- navbar-menu-wrapper -->
      <div class="navbar-right">
        <router-link tag="a" :to="{ name: 'login'}" class="btn btn-buy" v-if="!user_status"><i data-feather="log-in"  ></i> <span>Login</span></router-link>
		<router-link tag="a" :to="{ name: 'register'}"  class="btn btn-buy" v-if="!user_status"><i data-feather="user-plus" ></i> <span>Register</span></router-link>
		<a @click="logOut()" class="btn btn-buy" v-if="user_status"><i data-feather="log-out" ></i> <span>Logout</span></a>
      </div><!-- navbar-right -->
    </header>
	</div>
</template>
<script>
export default {

	computed:{
		user_status(){
			console.log(this.$store.state.authmodule.isLoggedIn)
			return this.$store.state.authmodule.isLoggedIn;
		}
	},
	methods:{
		logOut(){
			this.$store.dispatch("logout");
		}
	}

}
</script>
<style media="screen">
	#navbarMenu{
		float:right;
	}
	.navbar-header .takeme-right {
    padding: 0 15px;
    margin-left: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
}
</style>
