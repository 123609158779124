<template>
  <div class="home-body" id="body">

		<app-fronthead></app-fronthead>
			<router-view></router-view>


  </div>
</template>
<script>
import Fronthead from '../templates/frontend/Fronthead'
import Errordisplay from '../Errordisplay'
export default {
	components:{'app-fronthead':Fronthead,'app-err':Errordisplay}
}
</script>
<style lang="scss" >
  .btn-az-primary {
    color: #fff;
    background-color: #5b47fb;
    border-color: #452efa;
  }
</style>
