import { ApiSource } from "../services/apiService";
const api = new ApiSource();
const state={
	country_list :[],
	currency_list:[],
	amenity_list:[],
	titledoc_list:[],
	financeoption_list:[],
	paymentoption_list:[],
	hometype_list:[],
	propertystatus_list:[],
	subscription_list:[]

}
const mutations = {
	GET_COUNTRY_LIST(state, payload){

			state.country_list = payload
	},
	GET_CURRENCY_LIST(state, payload){
			state.currency_list = payload
	},
	GET_AMENITY_LIST(state, payload){
			state.amenity_list = payload
	},
	GET_TITLEDOC_LIST(state, payload){
			state.titledoc_list = payload
	},
	GET_FINANCEOPTION_LIST(state, payload){
			state.financeoption_list = payload
	},
	GET_PAYMENTOPTION_LIST(state, payload){
			state.paymentoption_list = payload
	},
	GET_HOMETYPE_LIST(state, payload){
			state.hometype_list = payload
	},
	GET_PROPERTYSTATUS_LIST(state, payload){
			state.propertystatus_list = payload
	},
	GET_SUBSCRIPTION_LIST(state,payload){
		state.subscription_list = payload;
	}

}
const actions = {
	countryListAction({commit}){
			api.get_country_list().then((res)=>{
				let c = res.data.data;

				// this.$store.dispatch('countryListAction', c);
				commit('GET_COUNTRY_LIST', c)
				// this.$store.getters.get_country_list
			})

	},
	currencyListAction({commit}){
		api.get_currency_list().then((res)=>{
				let c = res.data.data;
				commit('GET_CURRENCY_LIST', c)
			})
	},
	amenityListAction({commit}){
		api.get_amenity_list().then((res)=>{
				let c = res.data.data;
				commit('GET_AMENITY_LIST', c)
			})
	},
	titleDocListAction({commit}){
			api.get_titledoc_list().then(res => {
        let c = res.data.data;
        commit("GET_TITLEDOC_LIST", c);
      });

	},
	financeOptionListAction({commit}){
			api.get_financeoption_list().then(res => {
        let c = res.data.data;
        commit("GET_FINANCEOPTION_LIST", c);
      });
	},
	paymentOptionListAction({commit}){
			api.get_paymentoption_list().then(res => {
        let c = res.data.data;
        commit("GET_PAYMENTOPTION_LIST", c);
      });
	},
	hometypeListAction({commit}){
			api.get_hometype_list().then(res => {
        let c = res.data.data;
        commit("GET_HOMETYPE_LIST", c);
      });
	},
	propertystatusListAction({commit}){
			api.get_propertystatus_list().then(res => {
        let c = res.data.data;
        commit("GET_PROPERTYSTATUS_LIST", c);
      });
	},
	subscriptionListAction({commit}){
		api.get_subscription_list().then(res => {
	let c = res.data.data;
	commit("GET_SUBSCRIPTION_LIST", c);
  });
	}

}
const getters = {
	get_country_list(state){
		return state.country_list;
	},
	get_currency_list(state){
		return state.currency_list;
	},
	get_amenity_list(state){
		return state.amenity_list;
	},
	get_titledoc_list(state){
		return state.titledoc_list;
	},
	get_financeoption_list(state){
		return state.financeoption_list;
	},
	get_paymentoption_list(state){
		return state.paymentoption_list;
	},
	get_hometype_list(state){
		return state.hometype_list;
	},
	get_propertystatus_list(state){
		return state.propertystatus_list;
	},

}
const generalmodule = {
	state,mutations,actions,getters
}
export default generalmodule
