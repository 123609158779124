<template>
	<div>
		<nav aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-style1 mg-b-10">
                  <li class="breadcrumb-item"><a href="#">{{first}}</a></li>
                  <li class="breadcrumb-item active" aria-current="page">{{second}}</li>
                </ol>
              </nav>
	</div>
</template>
<script>
export default {
	
	props:["first","second"]
}
</script>