import { ApiSource } from "../services/apiService";
const api = new ApiSource();
const state = {
  plots: [],
  plot: "",
  pagination_data: ""
};
const mutations = {
  GET_UPDATED_PLOT(state, payload) {
    state.plots = payload.data;
    state.pagination_data = payload;
  },
  SHOW_PLOT_DETAIL_SLUG(state, payload) {
		state.plot = payload;
    // state.properties.findIndex(function(p) {
    //   if ((p.slug = payload)) {
    //     state.property = p;
    //     console.table(p);
    //   }
    // });
  }
};
const actions = {
  plotListAction({ commit }, main_url = "developer/plots") {
    api.developer_plots(main_url).then(res => {
      commit("GET_UPDATED_PLOT", res.data.data);
    });
    // commit("GET_UPDATED_PROPERTIES", list);
    //
  },
  propertyListAction2({ commit }, page) {
    let main_url = "developer/plots?page=" + page;
    api.developer_plots2(main_url).then(res => {
      commit("GET_UPDATED_PLOT", res.data.data);
    });
    // commit("GET_UPDATED_PROPERTIES", list);
    //
  },
  addPlotAction({ commit }, formdata) {
    return new Promise(function(resolve, reject) {
      api
        .addPlot(formdata)
        .then(res => {
          resolve(res.data.data);
        })
        .catch(err => {
          reject(err.data);
        });
    });
  },
  deletePlotAction({ commit }, slug) {
    return new Promise(function(resolve, reject) {
      api
        .deletePlot(slug)
        .then(res => {
          resolve(res.data.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  showPlotBySlugAction({ commit }, slug) {
		return new Promise(function (resolve, reject) {
			api.showPlot(slug).then((res) => {
				resolve(res.data.data);
				commit("SHOW_PLOT_DETAIL_SLUG", res.data.data);
			}).catch((err)=>{
				reject(err);
			});
	 })
    // commit("SHOW_PLOT_DETAIL_SLUG", slug);
    },
  updatePlotInfoAction({commit},data){
		let formdata = data.form;
		let slug = data.slug;
		return new Promise((resolve,reject)=>{
			api.updatePlotInfo(formdata,slug).then((res)=>{
				let data = res.data.data;
				let msg = res.data.message;
				let result = {
						response : data,
						message : msg
				}
				resolve(result);
				commit("SHOW_HOME_DETAIL_SLUG", res.data.data);
			}).catch((err)=>{
					reject(err.data.message);
			})
		})
	},
  addPlotImageAction({commit}, data){
		return new Promise((resolve,reject)=>{
			api.addPlotPhoto(data).then((res)=>{
				// console.table(res.data.data);
				let msg = res.data.data;
				resolve(msg)

			}).catch((err)=>{
				reject(err.data.message);
			})
	})
},

    deletePlotImage({commit},id){
        return new Promise((resolve,reject)=>{
            api.deletePlotPhoto(id).then((res)=>{
                let msg = res.data.data;
				resolve(msg)
            }).catch((err)=>{
                reject(err.data.message);
            })
        })

    }
};
const getters = {
  get_plot_details(state) {
    return state.plot;
  }
};
const plotmodule = {
  state,
  mutations,
  actions,
  getters
};
export default plotmodule;
