export const propertyMixin = {
         computed: {
           properties() {
             return this.$store.state.homemodule.properties;
           },
           propertyStatus: function() {
             return st => this.propertyStatusMethod(st);
           },

					 showTable(){
						if( this.form.property_type.length >= 1){
								return true;
						}
						return false;
					 },
					 typeError2(){
                   let status = false;
						 if (this.form.property_type.length <= 0 ) {
                        status = true
                      }
						return status
					 },
					 showTypeButton(){
						 	if(this.form.is_estate == 1){
								 return true;
							 }
                     	
							 return false;
					 },
           is_payment_plan() {
             if (this.form.finance_option == "Payment") {
               return true;
             }
             return false;
           },
           changeCurrency(v) {
             this.form.currency_symbol = v;
           },
           country() {
             return this.$store.getters.get_country_list;
           },
           currency() {
             return this.$store.getters.get_currency_list;
           },
           amenity() {
             return this.$store.getters.get_amenity_list;
           },
           finance() {
             return this.$store.getters.get_financeoption_list;
           },
           titledoc() {
             return this.$store.getters.get_titledoc_list;
           },
           hometype() {
             return this.$store.getters.get_hometype_list;
           },
           paymentoption() {
             return this.$store.getters.get_paymentoption_list;
           },
           propertystatus() {
             return this.$store.getters.get_propertystatus_list;
           }
				 },
				 methods: {
					 capitalizeFirst(s){
						  if (typeof s !== "string") return "";
              return s.charAt(0).toUpperCase() + s.slice(1);
           },
           changeHometypeName(name){
             let n = name.split("+");
   			let real = n[0];
   			return real
          }
				 },
       };
