<template>
 <div class="content content-fixed content-auth-alt">
      <div class="container ht-100p">
        <div class="ht-100p d-flex flex-column align-items-center justify-content-center">
          <div class="wd-150 wd-sm-250 mg-b-30"><img src="../../assets/img/img17.png" class="img-fluid" alt=""></div>
          <h4 class="tx-20 tx-sm-24">Welcome {{name}}</h4>
          <h4 class="tx-20 tx-sm-24">Verify your email address</h4>
          <p class="tx-color-03 mg-b-20">Please check your email and click the verify button or link to verify your account.</p>
					<span v-if="validCode" class="span_error">Invalid OTP, Enter the correct code sent to your mail or click resend below for new code</span>
                    <form class="" @submit.prevent="verify()" method="post">
                        <div id="divOuter" class="mg-b-20">
                                <div id="divInner">
                                    <input id="partitioned" type="text" maxlength="4" :class="{ error: validCode, 'is-invalid': $v.form.code.$error  }" v-model="form.code" />
                                        <div v-if="!$v.form.code.required" class="invalid-feedback">Enter the correct code sent to your mail</div>
                                </div>
                        </div>
                        <div class="tx-13 tx-lg-14 mg-b-10">
                          <button id="v_btn" type="submit" class="btn btn-block btn-brand-02 align-items-center" >Verify</button>
                        </div>
                    </form>




          <div class="tx-13 tx-lg-14 mg-b-40">
           <p>Didn't received OTP mail <a @click="resend()" class="align-items-center">Resend Verification</a></p>
          </div>

        </div>
      </div><!-- container -->
</div>

</template>

<script>
import { required, email, minLength, sameAs,requiredUnless,requiredIf } from "vuelidate/lib/validators";
    export default {
        name: "Verify",
        data(){
            return{
                form:{
                    code:''
                },
				validCode:false,
            }
				},
				computed:{
						name(){
							return this.$store.state.authmodule.user['name']
						},
                        rule(){
                            return{
                                code:{required}
                            }
                        }

				},
                validations() {
                    return{
                        form:this.rule
                    }
                },
        methods:{
            goHome(){
                if(this.code != 1234){
                        this.validCode = true;
                }else{
                    this.validCode = false;
                    this.$router.push('/dashboard')
                }
            },
            verify(){
                this.$v.form.$touch();
                // if its still pending or an error is returned do not submit
                if (this.$v.form.$pending || this.$v.form.$error){
                    return;
                }
                this.$store.dispatch("verifyusercodeAction",this.form).then((res)=>{
                    // this.$router.go('/dashboard')
                }).catch((err)=>{
                    console.log(err)
                })
            },
            resend(){
                 this.$store.dispatch("verifyusercodeAction",this.form).then((res)=>{
                    // this.$router.go('/dashboard')
                }).catch((err)=>{
                    console.log(err)
                })
            }
		}

    }
</script>

<style scoped>
    .error{
        border:1px solid red;
    }
    .span_error{
        color:red;
    }
		.no-padding{
			/* padding:2px !important; */
		}
		#v-btn{
			width: 250px;
		}
		#partitioned {
			padding-left: 15px;
			letter-spacing: 42px;
			border: 0;
			background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
			background-position: bottom;
			background-size: 50px 1px;
			background-repeat: repeat-x;
			background-position-x: 35px;
			width: 220px;
			min-width: 220px;
			font-size: 15px;
			font-weight: bold;
		}
		#divInner{
				left: 0;
				position: sticky;
			}

			#divOuter{
				width: 190px;
				overflow: hidden;
			}
</style>
