<template>
	<div>

		<!-- add form -->

		<div class="container pd-x-0">

			<div class="d-sm-flex align-items-center justify-content-between mg-b-10 mg-lg-b-10 mg-xl-b-20">

				<div>

					<breadcrum first="Properties" second="New Home"></breadcrum>

					<h4 class="mg-b-0 tx-spacing--1">Add New Property</h4>

				</div>

				<div class="d-none d-block">

					<router-link tag="button" :to="{name:'hometable'}" class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">

						<i class="fa fa-eye wd-10 mg-r-5"></i> View List

					</router-link>

				</div>

			</div>

			<!-- step indictaiors -->

			<div class="row">

				<div class="col-1"></div>

				<div class="col-10 align-content-center">

					<div class="align-items-center">

						<ul class="steps">

							<li class="step-item" :class="[step==1?'active':'',isCompleted?'complete':'']">

								<a @click="goToStep(1)" class="step-link">

										<span class="step-number">1</span>

										<span class="step-title">Property Info</span>

									</a>

							</li>

							<li class="step-item" :class="[step==2?'active':'',isCompleted?'complete':'']">

								<a @click="goToStep(2)" class="step-link">

										<span class="step-number">2</span>

										<span class="step-title">Property Location</span>

									</a>

							</li>

							<li class="step-item" :class="[step==3?'active':'',isCompleted?'complete':'']">

								<a @click="goToStep(3)" class="step-link">

										<span class="step-number">3</span>

										<span class="step-title">Property Description</span>

									</a>

							</li>

							<li class="step-item" :class="[step==4?'active':'',isCompleted?'complete':'']">

								<a @click="goToStep(4)" class="step-link">

										<span class="step-number">4</span>

										<span class="step-title">Property Finance </span>

									</a>

							</li>

							<li class="step-item" :class="[step==5?'active':'',isCompleted?'complete':'']">

								<a @click="goToStep(5)" class="step-link">

										<span class="step-number">5</span>

										<span class="step-title">Property Gallery</span>

									</a>

							</li>

						</ul>

					</div>

				</div>

				<div class="col-1"></div>

			</div>

			<hr />

			<div class="row ">

				<div class="col-md-10 offset-md-1">

					<form enctype="multipart/form-data">

						<div class="form_area">

							<section v-if="step==1">

								<div class="row" v-if="typeError && typeError2">

									<div class="col-md-4 offset-md-4">

										<div class="alert alert-primary alert-dismissible">

											<button type="button" class="close" data-dismiss="alert">&times;</button>

											<strong>Property type is required</strong> click on its button to add

										</div>

									</div>

								</div>

								<div class="row">

									<div :class="[is_estate?'col-md-4':'col-md-6']">

										<div class="form-group">

											<label for="t">Is this an estate or a single property ?</label>

											<select name id="t" class="custom-select" v-model="form.is_estate" :class="{ 'is-invalid': $v.form.is_estate.$error }">

															<option label="Choose one" ></option>

															<option value="0">Single Property</option>

															<option value="1">Estate Property</option>

														</select>

											<div v-if="!$v.form.is_estate.required" class="invalid-feedback">selection is required</div>

										</div>

									</div>

									<div :class="[is_estate?'col-md-4':'col-md-6']">

										<div class="form-group">

											<label for="t">select property currency</label>

											<div class="input-group">

												<div class="input-group-prepend">



												</div>

												<select name id="currencyid" class="form-control" v-model="selected_currency" @change="updateSymbol()" :class="{ 'is-invalid': $v.form.base_currency.$error }">

																	<option label="Choose one" ></option>

																	<option :value="c.currency_code+'+'+c.currency_symbol" v-for="(c , index) in currency" :key="index"  >{{c.currency_code}}</option>

																</select>

												<input type="text" v-model="form.currency_symbol" class="form-control" disabled id="currency_symbol" :class="{ 'is-invalid': $v.form.base_currency.$error }" />

												<div v-if="!$v.form.base_currency.required" class="invalid-feedback">select property base currency</div>

											</div>

										</div>

									</div>

									<div :class="[is_estate?'col-md-4':'']">

										<!-- <div class="col-md-4"> -->

										<div class="form-group mg-t-10" v-if="is_estate">

											<label for></label>

											<button class="btn btn-block btn-primary" data-toggle="modal" data-animation="effect-slide-in-right" @click.prevent="showModal()">Click to add Property Type</button>

										</div>

									</div>

								</div>

								<div class="row" v-if="showTable">

									<div class="table-responsive">

										<div class="table table-bordered table-striped table-primary table-sm">

											<thead>

												<th>s/n</th>

												<th style="width:20%">Property type</th>

												<th>Price</th>

												<th>Bedroom</th>

												<th>Bathroom</th>

												<th>Garage</th>

												<th>Property Status</th>

												<th>Sale Status</th>

												<th>Action</th>

											</thead>

											<tbody>

												<tr v-for="(type,index) in form.property_type" :key="index">

													<td>{{index+1}}</td>

													<td>{{changeHometypeName(type.hometype)}}</td>

													<td>{{type.price}}</td>

													<td>{{type.bedroom}}</td>

													<td>{{type.bathroom}}</td>

													<td>{{type.garage}}</td>

													<td>{{type.property_status}}</td>

													<td><span v-if="type.sale_status == 0">Available</span><span v-else>Sold</span></td>

													<td>

														<button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-animation="effect-slide-in-right" @click.prevent="editPropertyType(index)"><i class="fa fa-edit" ></i></button>&nbsp;

														<button type="button" class="btn btn-primary btn-sm"><i class="fa fa-minus" @click.prevent="removePropertyType(index)"></i></button>

													</td>

												</tr>

											</tbody>

										</div>

									</div>



								</div>



								<div class="row">

									<div class="col-md-6">

										<div class="form-group">

											<label for="name">Property name</label>

											<input type="text" class="form-control" placeholder="property name" v-model="form.property_name" :class="{ 'is-invalid': $v.form.property_name.$error }">

											<div v-if="!$v.form.property_name.required" class="invalid-feedback">Property name is required</div>

										</div>

									</div>

									<div class="col-md-6">

										<div class="form-group">

											<label for="name">SQM</label>

											<input type="text" class="form-control" placeholder="property square metre" v-model="form.property_size" :class="{ 'is-invalid': $v.form.property_size.$error }">

											<div v-if="!$v.form.property_size.required" class="invalid-feedback">Property name is required</div>

										</div>

									</div>

								</div>

							</section>

							<section v-if="step==2">

								<div class="row">

									<div class="col-md-4">

										<div class="form-group">

											<label for="country" class="form-control-label">Country <span class="tx-danger">*</span></label>

											<select class="form-control  cloneme" name="property_country" id="country" v-model="form.property_country" :class="{ 'is-invalid': $v.form.property_country.$error }">

																	<option label="Choose one"></option>

																	<option v-for="(c, index) in country" :key="index" :value="c.name">{{c.name}}</option>

																</select>

											<div v-if="!$v.form.property_country.required" class="invalid-feedback">property country is required</div>

										</div>

									</div>

									<!-- Address -->

									<div id="locationField" class="col-md-4">

										<div class="form-group">

											<label class="form-control-label">Property Full Address: <span class="tx-danger">*</span></label>

											<!-- <input id="autocomplete"  ref="autocomplete" class="form-control" name="property_address" placeholder="Enter property address" onfocus="value = ''"  type="text" required v-model="form.property_full_address" :class="{ 'is-invalid': $v.form.property_full_address.$error }">  -->

											<vue-google-autocomplete ref="address" id="map" classname="form-control" placeholder="Please type your address" v-on:placechanged="getAddressData">

											</vue-google-autocomplete>

											<div v-if="!$v.form.property_full_address.required" class="invalid-feedback">property full address is required</div>



										</div>



									</div>

									<div class="col-md-4">

										<div class="form-group">

											<label class="form-control-label">Property City<span class="tx-danger">*</span></label>

											<input class="form-control" name="property_city" type="text" id="locality" placeholder="Enter property city" required v-model="form.property_city" :class="{ 'is-invalid': $v.form.property_city.$error }">

											<div v-if="!$v.form.property_city.required" class="invalid-feedback">property city is required</div>

										</div>

									</div>

									<div class="col-md-4">

										<div class="form-group">

											<label class="form-control-label">Property State<span class="tx-danger">*</span></label>

											<input class="form-control" name="property_state" type="text" id="administrative_area_level_1" placeholder="Enter property state" required v-model="form.property_state" :class="{ 'is-invalid': $v.form.property_state.$error }">

											<div v-if="!$v.form.property_state.required" class="invalid-feedback">property state is required</div>

										</div>

									</div>

									<div class="col-md-4">

										<div class="form-group">

											<label class="form-control-label">Property Neighborhood <span class="tx-danger">*</span></label>

											<input class="form-control" name="property_neighborhood" placeholder="eg VI" type="text" v-model="form.property_neighborhood" :class="{ 'is-invalid': $v.form.property_neighborhood.$error }">

											<div v-if="!$v.form.property_neighborhood.required" class="invalid-feedback">property neighborhood is required</div>



										</div>



									</div>

								</div>

							</section>

							<section v-if="step==3">

								<div class="row">

									<div class=" col-md-12 ">

										<label class="form-control-label">Property Description <span class="tx-danger">*</span></label>

										<ckeditor :editor="editor" v-model="form.property_description" :config="editorConfig" :class="{ 'is-invalid': $v.form.property_description.$error }"></ckeditor>

										<div v-if="!$v.form.property_description.required" class="invalid-feedback">property description is required</div>

									</div>

									<div class="col-md-12 az-content-label  mg-t-40">Featured Amenities</div>

									<div class=" col-md-3" v-for="(a, index) in amenity" :key="index">

										<div class="form-group">

											<div class="custom-control custom-checkbox">

												<input type="checkbox" class="custom-control-input" name="amenities[]" :id="a.id" :value="a.id" v-model="form.amenities">

												<label class="custom-control-label" :for="a.id">{{a.name}}</label>

											</div>

										</div>

									</div>

								</div>



							</section>

							<section v-if="step==4">

								<div class="row">

									<div :class="[is_payment_plan?'col-md-4':'col-md-6']">

										<div class="form-group">

											<label class="form-control-label">Finance plan<span class="tx-danger">*</span></label>

											<select class="form-control" name="finance_option" required v-model="form.finance_option" :class="{ 'is-invalid': $v.form.finance_option.$error }">

																			<option label="Choose one" ></option>

																			<option :value="c.option" v-for="(c , index) in finance" :key="index" >{{c.option}}</option>

																		</select>

											<div v-if="!$v.form.finance_option.required" class="invalid-feedback">select property finance option</div>

										</div>



									</div>

									<div class="col-md-4 " id="payment_option_div" v-if="is_payment_plan">

										<div class="form-group">

											<label class="form-control-label">Payment plan<span class="tx-danger">*</span></label>

											<select class="form-control" name=" payment_option" required v-model="form.payment_option" :class="{ 'is-invalid':is_payment_plan&& $v.form.payment_option.$error }">

																			<option label="Choose one" ></option>

																			<option value="1 year" >One year</option>

																			<option value="6 months" >Six Month</option>



																		</select>

											<div v-if="!$v.form.payment_option.required &&is_payment_option" class="invalid-feedback">select property finance option</div>

										</div>



									</div>

									<div :class="[is_payment_plan?'col-md-4':'col-md-6']">

										<div class="form-group">

											<label class="form-control-label">Building Permit<span class="tx-danger">*</span></label>

											<select class="form-control" name="building_permit" required v-model="form.building_permit" :class="{ 'is-invalid': $v.form.building_permit.$error }">

																			<option label="Choose one" ></option>

																			<option value="1">Yes</option>

																			<option value="0">No</option>

																		</select>

											<div v-if="!$v.form.building_permit.required" class="invalid-feedback">selection is required</div>

										</div>



									</div>

									<div class="col-md-12">

										<label class="form-control-label">Property documents<span class="tx-danger">*</span></label>

										<div class="col-md-4" v-for="(a, index) in titledoc" :key="index">

											<div class="custom-control custom-radio">

												<input type="radio" :id="a.title" name="customRadio" class="custom-control-input" v-model="form.title_doc" :value="a.title" :class="{ 'is-invalid': $v.form.title_doc.$error }">

												<label class="custom-control-label" :for="a.title">{{a.title}}</label>

											</div>

											<div v-if="!$v.form.title_doc.required" class="invalid-feedback">selection is required</div>

										</div>

									</div>

								</div>

							</section>

							<section v-if="step==5">

								<div class="row">

									<div class="col-3"></div>

									<div class="col-6">

										<vue-upload-multiple-image @upload-success="uploadImageSuccess" @before-remove="beforeRemove" @edit-image="editImage" dragText="Click to upload" :browseText="browseText" primaryText="Active" popupText="Your file upload should not exceed 5" markIsPrimaryText="File"

										    accept="image/jpeg,image/png,image/jpg" :max-image="maxImage" @limit-exceeded="exceedImageLimit"></vue-upload-multiple-image>



										<!-- <button type="button" class="btn btn-primary" @click.prevent="openFileWindow" >Uplaod</button>

													<input type="file" class="form-control" id="customFile" multiple @change="onFileChange" style="display:none" ref="windowOpen" accept="image/*"/>

													<div >

															<div v-for="(image, index) in images" :key="index">

																	<img class="preview" :src="image" />

															</div>

													</div> -->

									</div>

									<div class="col-3"></div>



								</div>

							</section>

						</div>



						<!-- step button -->

						<div class="row ">

							<div class="mg-l-auto  ">

								<button v-if="step !=1" type="button" class="btn btn-primary step_buttons" @click.prevent="prevBtn">Back</button>

								<button v-if="step != totalStep" type="button" class="btn btn-primary step_buttons" @click.prevent="nextBtn">Next</button>

								<button v-if="step == totalStep" type="submit" class="btn btn-primary step_buttons" @click.prevent="submitBtn">Submit</button>

							</div>







						</div>



					</form>

					<!-- modal -->

					<div class="modal fade" id="property_type" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel6" aria-hidden="true">

						<div class="modal-dialog modal-dialog-centered" role="document">

							<div class="modal-content tx-14">

								<div class="modal-header">

									<h6 class="modal-title" id="exampleModalLabel6">Add Property Type</h6>

									<button type="button" class="close" data-dismiss="modal" aria-label="Close">

											<span aria-hidden="true">&times;</span>

										</button>

								</div>

								<form id="form" method="POST">



									<div class="modal-body">

										<input type="text" v-model="property_type_form.id" hidden id="id">

										<div class="row">

											<div class="col-md-6">

												<div class="form-group">

													<label for="status" class="form-control-label">Property Type <span class="tx-danger">*</span></label>

													<select class="form-control" name="hometype" id="hometype" v-model="property_type_form.hometype" :class="{ 'is-invalid': $v.property_type_form.hometype.$error }">

																	<option label="Choose one"></option>

																	<option v-for="(h, index) in hometype" :key="index" :value="h.name+'+'+h.icon">{{h.name}}</option>

																</select>

													<div v-if="!$v.property_type_form.hometype.required" class="invalid-feedback">selection is required</div>

												</div>

											</div>

											<div class="col-md-6">

												<div class="form-group">

													<label for="price" class="form-control-label">Type Price <span class="tx-danger">*</span></label>

													<input type="text" id="price" name="price" class="form-control currency_input" v-model="property_type_form.price" placeholder="eg 15,000,000" required :class="{ 'is-invalid': $v.property_type_form.price.$error }">

													<div v-if="!$v.property_type_form.price.required" class="invalid-feedback">Price is required</div>

												</div>

											</div>

											<div class="col-md-6">

												<div class="form-group">

													<label for="status" class="form-control-label">Property Status <span class="tx-danger">*</span></label>

													<select class="form-control " name="status" id="status" required v-model="property_type_form.property_status" :class="{ 'is-invalid': $v.property_type_form.property_status.$error }">

																	<option label="Choose one"></option>

																	<option v-for="(p,index) in propertystatus" :key="index" :value="p.id">{{p.name}}</option>

																</select>

													<div v-if="!$v.property_type_form.property_status.required" class="invalid-feedback">selection is required</div>

												</div>

											</div>

											<div class="col-md-6">

												<div class="form-group">

													<label for="price" class="form-control-label">Bathroom<span class="tx-danger">*</span></label>

													<input type="number" id="bathroom" name="bathroom" class="form-control " placeholder="" v-model="property_type_form.bathroom" :class="{ 'is-invalid': $v.property_type_form.bathroom.$error }">

													<div v-if="!$v.property_type_form.bathroom.required" class="invalid-feedback">Bathroom is required</div>

												</div>

											</div>

											<div class="col-md-6">

												<div class="form-group">

													<label for="price" class="form-control-label">Bedroom<span class="tx-danger">*</span></label>

													<input type="number" id="bedroom" name="bedroom" class="form-control " placeholder="" v-model="property_type_form.bedroom" :class="{ 'is-invalid': $v.property_type_form.bedroom.$error }">

													<div v-if="!$v.property_type_form.bedroom.required" class="invalid-feedback">Bedroom is required</div>

												</div>

											</div>

											<div class="col-md-6">

												<div class="form-group">

													<label for="price" class="form-control-label">Garage<span class="tx-danger">*</span></label>

													<input type="number" id="garage" name="garage" class="form-control" placeholder="" v-model="property_type_form.garage" :class="{ 'is-invalid': $v.property_type_form.garage.$error }">

													<div v-if="!$v.property_type_form.garage.required" class="invalid-feedback">Garage is required</div>

												</div>

											</div>

											<div class="col-md-6">

												<div class="form-group">

													<label for="status" class="form-control-label">Sales Status <span class="tx-danger">*</span></label>

													<select class="form-control" name="is_sold" id="issold" v-model="property_type_form.sale_status" :class="{ 'is-invalid': $v.property_type_form.sale_status.$error }">

																	<option label="Choose one"></option>

																	<option value="0">Available</option>

																	<option value="1">Sold</option>



																</select>

													<div v-if="!$v.property_type_form.sale_status.required" class="invalid-feedback">selection is required</div>

												</div>

											</div>

										</div>





									</div>

									<div class="modal-footer">

										<button type="button" class="btn btn-secondary tx-13" data-dismiss="modal">Close</button>

										<button type="submit" class="btn btn-primary tx-13" @click.prevent="editHometype ?updatePropertyType():addPropertyType()">{{editHometype?'Update':'Add'}}</button>

									</div>

								</form>

							</div>

						</div>

					</div>

				</div>

			</div>

		</div>

	</div>
</template>

<script>
import { propertyMixin } from '../../../mixins/properties'
import { stepFormMixin } from '../../../mixins/stepform'
import { required, email, minLength, sameAs, requiredUnless, requiredIf } from "vuelidate/lib/validators";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
	inject: ["eventBus", "restApi", "toast"],
	mixins: [propertyMixin, stepFormMixin],
	data() {
		return {
			editor: ClassicEditor,
			editorConfig: {},
			isActive: false,
			isCompleted: false,
			submitted: false,
			editHometype: false,
			maxImage: parseInt(6),
			country_sort: '',
			images: [],
			selectedFile: '',
			browseText: 'Maximum of 6 files',
			selected_currency: '',
			modal_count: 1,
			autocomplete: '',
			form: {
				base_currency: '',
				currency_symbol: '',
				is_estate: '',
				property_type: [],
				property_name: '',
				property_size: '',
				property_country: '',
				property_state: '',
				property_city: '',
				property_neighborhood: '',
				property_full_address: '',
				property_description: '',
				finance_option: '',
				payment_option: '',
				building_permit: '',
				title_doc: '',
				property_image: '',
				amenities: [],
				photo: []
			},
			property_type_form: {
				id: 1,
				hometype: '',
				price: '',
				bedroom: '',
				bathroom: '',
				garage: '',
				property_status: '',
				sale_status: ''
			},
			typeform: [],
			formstep: 5

		};
	},
	computed: {

		is_estate() {

			if (this.form.is_estate == 1) {
				return true;
			} else if (this.form.property_type.length >= 1 && this.form.is_estate != 1) {

				return false;
			} else {
				return true;
			}

		},
		checkTypeLength() {
			if (this.form.is_estate != 1 && this.form.property_type.length > 1) {
				this.form.property_type.splice(1);
			}
		},
		rules() {
			switch (this.step) {
				case 1:
					return {
						base_currency: { required },
						is_estate: { required },
						property_name: { required },
						property_size: { required },


					}
					break;
				case 2:
					return {
						property_country: { required },
						property_state: { required },
						property_city: { required },
						property_neighborhood: { required },
						property_full_address: { required }
					}
					break;
				case 3:
					return {
						property_description: { required }
					}
					break;
				case 4:
					return {
						finance_option: { required },
						building_permit: { required },
						title_doc: { required },
						payment_option: { required: requiredIf(this.is_payment_plan) }


					}
					break;
				case 5:
					return {
						photo: { required }
					}
			}
		},
		typeformrule() {
			return {
				hometype: { required },
				price: { required },
				bedroom: { required },
				bathroom: { required },
				garage: { required },
				property_status: { required },
				sale_status: { required }

			}
		},
		hometypeName(name) {
			let n = this.name.split("+");
			let real = n[0];
			return real
		},

	},
	validations() {
		return {
			form: this.rules,
			property_type_form: this.typeformrule
		}

	},

	methods: {
		// showCountryName(c){
		// 	console.log(c.name)
		// 	// return c.name;
		// },
		updateSymbol() {
			let s = this.selected_currency.split("+");
			this.form.base_currency = s[0];
			this.form.currency_symbol = s[1];

		},
		onFileChange(e) {
			var selectedFiles = e.target.files;
			for (var i = 0; i < selectedFiles.length; i++) {

				let filename = selectedFiles[i].name;
				if (filename.lastIndexOf('.') <= 0) {
					return alert("one of your image is not valid");
				}
				const reader = new FileReader();
				reader.addEventListener('load', () => {
					this.images.push(reader.result);
					reader.readAsDataURL(selectedFiles[i]);
				})

				this.form.photo.push(selectedFiles[i]);
				// let p = URL.createObjectURL(selectedFiles[i]);


			}
			console.log(this.form.photo)
		},
		submitBtn() {
			if (this.form.is_estate == 0) {
				this.form.property_type.push(this.property_type_form)

			}
			// console.table(this.form)
			// return;
			this.$store.dispatch("addPropertyAction", this.form).then((res) => {

				this.$store.dispatch("propertyListAction");
				this.$router.replace({ name: 'hometable' })
				// this.form
				this.$swal({
					type: "success",
					title: 'Congratulation',
					text: res + '. It will be reviewed for approval '
				}).then((result) => {
					if (result.value) {

					}
				})
				// this.form
			}).catch((err) => {
				this.$swal({
					type: "error",
					title: 'Oop...',
					text: err,
				})
			})
		},
		showModal() {
			$("#property_type").modal("show");
		},
		addPropertyType() {

			let id = this.property_type_form.id += 1;
			this.$v.property_type_form.$touch();
			// if its still pending or an error is returned do not submit
			if (this.$v.property_type_form.$pending || this.$v.property_type_form.$error) {

				return;
			}
				console.table(this.property_type_form);
			this.form.property_type.push(this.property_type_form)
			this.property_type_form = {}
			$("#property_type").modal("hide");

		},
		updatePropertyType() {
			$("#property_type").modal("hide");
			this.property_type_form = {}
			console.table(this.form.property_type);
			this.editHometype = false;
		},
		removePropertyType(index) {
			this.form.property_type.splice(index, 1);
		},
		editPropertyType(index) {
			console.table(this.form.property_type[index])
			this.editHometype = true;
			this.property_type_form = this.form.property_type[index]
			$("#property_type").modal("show");

		},
		openFileWindow() {
			this.$refs.windowOpen.click();
		},
		//  onFileChange(e) {
		//   const file = e.target.files[0];
		//   this.form.property_image = URL.createObjectURL(file);
		// },
		uploadImageSuccess(formData, index, fileList) {

			fileList.forEach((r) => {
				// console.log(r);
				this.form.photo.push(r);

			});
			// this.form.photo.pop();
		},
		beforeRemove(index, done, fileList) {
			console.log('index', index, fileList)
			var r = confirm("remove image")
			if (r == true) {
				done()
			} else {}
		},
		editImage(formData, index, fileList) {
			console.log('edit data', formData, index, fileList)
		},
		exceedImageLimit(amount) {
			alert("you selected " + amount + "files which exceed the limit")
		},
		getAddressData: function(addressData, placeResultData, id) {
			// this.address = addressData;locality  route street_number

			this.form.property_city = placeResultData.vicinity;
			this.form.property_full_address = placeResultData.name;
			this.form.property_state = addressData.locality
			this.form.property_neighborhood = placeResultData.vicinity
			// console.log('result', placeResultData)
			// 	console.log('street', placeResultData.name)
			// console.log('neighbor', placeResultData.vicinity)
			// console.log('address',addressData.route)
			// console.log('state',addressData.locality)
		}


	},
	created() {
		this.$store.dispatch("propertyListAction");
	},
	mounted() {
		// var inpu = document.getElementById('autocomplete')
		// this.autocomplete = new google.maps.places.Autocomplete((this.$refs.autocomplete),{types: ['geocode']});
		// //
		// 	autocomplete.addListener('place_changed', function() {
		//  var place = autocomplete.getPlace();
		//   console.log(place)
		// 	 document.getElementById('location-snap').innerHTML = place.formatted_address;
		// 	 document.getElementById('lat-span').innerHTML = place.geometry.location.lat();
		// 	 document.getElementById('lon-span').innerHTML = place.geometry.location.lng();
		// });
		// this.$refs.address.focus();
	}
};
</script>

<style scoped>
#currency_symbol {
	font-weight: bold;
	align-content: center;
	/* font-size:1.5rem; */
}

.form_area {
	/* height: 20.5rem; */
	padding: 1rem;
	margin-bottom: 2rem
}

.step_buttons {
	border-top: 1px solid #f9f9f9;
	width: 8rem;
	margin-right: 1.5rem;
}

#preview {
	display: flex;
	justify-content: center;
	align-items: center;
}

#preview img {
	max-width: 100%;
	max-height: 200px;
}
</style>
