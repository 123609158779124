import axios from 'axios'
import NProgress from "nprogress";
import {	TokenService} from './tokenService'
import toast from './toast'

axios.interceptors.request.use(config => {
  NProgress.start();
  return config;
});

// before a response is returned stop nprogress
axios.interceptors.response.use(
    function (response) {
        NProgress.done();
    return response;
    },
    function(error) {
        NProgress.done();
        // check for errorHandle config
        if( error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false ) {
            return Promise.reject(error);
        }

        if (error.response) {
            console.log(error.response.data);
            toast.error(error.response.data.data);
        }
    }
)
const ApiService = {
			init(baseURL) {
				axios.defaults.baseURL = baseURL;
			},

			setHeader() {
				axios.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getToken()}`
			},

			removeHeader() {
				axios.defaults.headers.common = {}
			},
			get(resource) {
				return axios.get(resource)
			},

			post(resource, data) {
				return axios.post(resource, data)
			},

			put(resource, data) {
				return axios.put(resource, data)
			},

			delete(resource) {
				return axios.delete(resource)
			},

			/**
			 * Perform a custom Axios request.
			 *
			 * data is an object containing the following properties:
			 *  - method
			 *  - url
			 *  - data ... request payload
			 *  - auth (optional)
			 *    - username
			 *    - password
			 **/
			customRequest(data) {
				return axios(data)
			}
}
export class ApiSource {
         constructor(bus) {
           this.eventBus = bus;
         }

         async sendrequest(httpmethod, url, data) {
             return await axios.request({
               method: httpmethod,
               url: url,
               data: data
             });
           // } catch (err) {
           //   if (err.response) {
			// 				NProgress.done();
           //   //   // this.eventBusFirst.$emit(
           //   //   //   "http error",
           //   //   //   `${err.response.statusText} - ${err.response.status}`
           //   //   // );
           //   // } else {
           //   //   this.eventBus.$emit("HttpError", "HTTP Error");
           //   // }
           //   // throw err;
           //         }
        	// 			 }
             }
				//  authentication apis
         async login(authData) {
           return await this.sendrequest("post","developer/login",authData );
         }
         async register(authData) {
           return await this.sendrequest("post","developer/register",authData);
		 }
         async verifycode(authData) {
           return await this.sendrequest("post","developer/verifycode",authData );
         }
         async get_user_info() {
           return await this.sendrequest("get","developer/userinfo");
        }
        async forgotpassword(data){
             return await this.sendrequest("post","developer/forgotpassword", data);
        }
        async changepassword(data){
            return await this.sendrequest("post","developer/changepassword", data);

        }
        async updateprofile(data){
          return await this.sendrequest("post","developer/updateprofile", data);

      }
				//  general Apis
         async get_country_list() {
           return await this.sendrequest("get", "countrylist");
         }
         async get_currency_list() {
           return await this.sendrequest("get", "currencylist");
         }
         async get_amenity_list() {
           return await this.sendrequest("get", "amenitylist");
         }
         async get_titledoc_list() {
           return await this.sendrequest("get", "titledocs");
         }
         async get_financeoption_list() {
           return await this.sendrequest("get", "financeoption");
         }
         async get_hometype_list() {
           return await this.sendrequest("get", "hometypes");
         }
         async get_paymentoption_list() {
           return await this.sendrequest("get", "paymentoption");
         }
         async get_propertystatus_list() {
           return await this.sendrequest("get", "propertystatus");
		 }
         async get_subscription_list() {
           return await this.sendrequest("get", "subscriptionlist");
		 }
				//  developer apis
        async developer_subscribe(form) {
          return await this.sendrequest("post", "developer/subscribe",form);
        }
         async developer_dashboard() {
           return await this.sendrequest("get", "developer/dashboard");
         }
         async get_developer_info() {
           return await this.sendrequest("get", "developer/info");
         }
         async developer_properties(url) {
           return await this.sendrequest("get", url);
         }
         async developer_properties2(page) {
           return await this.sendrequest("get", page);
         }

         async upload(files) {
           return await this.sendrequest( "post","developer/addproperty",files);
				 }
				//  property api
         async showProperty(slug) {
           return await this.sendrequest("get","developer/property/details/"+slug);
         }
         async addProperty(files) {
           return await this.sendrequest("post","developer/property/new",files);
         }

         async updatePropertyInfo(formdata,slug) {
           return await this.sendrequest("post", "developer/property/update/" + slug, formdata);
         }
         async deleteProperty(slug) {
           return await this.sendrequest( "get","developer/property/delete/" + slug);
         }
         async addPropertyPhoto(files) {
           return await this.sendrequest( "post","developer/property/add/photo", files);
         }
         async deletePropertyPhoto(id) {
           return await this.sendrequest("get", "developer/property/delete/photo/" + id);
         }
         async updatePropertyAmenity(formdata,propertyid) {
           return await this.sendrequest("post","developer/property/update/amenity/" + propertyid,formdata );
         }
         async addPropertyType(files) {
           return await this.sendrequest(
             "post","developer/property/hometype/add",files);
         }
         async updatePropertyType(data,id) {
           return await this.sendrequest("post","developer/property/hometype/update/" + id, data);
         }
         async deletePropertyType(id) {
           return await this.sendrequest("get","developer/property/hometype/delete/" + id );
         }

         // service plots
         async showPlot(slug) {
           return await this.sendrequest( "get","developer/plot/details/"+slug );
				 }
         async addPlot(files) {
           return await this.sendrequest( "post","developer/plot/new",files );
				 }
				  async developer_plots(url) {
           return await this.sendrequest("get", url);
         }
         async developer_plots2(page) {
           return await this.sendrequest("get", page);
				 }
	   async updatePlotInfo(form,slug) {
           return await this.sendrequest( "post", "developer/plot/update/" + slug,form  );
         }
         async deletePlot(slug) {
           return await this.sendrequest( "get", "developer/plot/delete/" + slug );
         }
         async addPlotPhoto(files) {
           return await this.sendrequest( "post","developer/plot/add/photo", files);
         }
         async deletePlotPhoto(id) {
           return await this.sendrequest( "get","developer/plot/delete/photo/" + id);
         }
         async updatePlotAmenity(propertyid) {
           return await this.sendrequest("post","developer/plot/update/amenity/" + propertyid);
         }
         // Enquires and Request
         async get_property_enquiries(url){
             return await this.sendrequest("get",url);
         }
         async get_property_enquiries2(page){//"developer/enquiries"
             return await this.sendrequest("get",page);
         }
         async get_developer_request(url){//"developer/requests"
              return await this.sendrequest("get",url);
         }
         async get_developer_request2(page){//"developer/requests"
              return await this.sendrequest("get",page);
         }
         async get_developer_subscription_info(){//"developer/requests"
              return await this.sendrequest("get",'developer/subscription/status');
         }
         async get_developer_subscription_payment(url){//"developer/requests"
              return await this.sendrequest("get",url);
         }
         async get_developer_subscription_payment2(page){//"developer/requests"
              return await this.sendrequest("get",page);
         }
       }

export default ApiService
