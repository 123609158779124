<template lang="html">
    <div class="">
        <breadcrum first="Leads" second="Requests"></breadcrum>
        <h4 class="mg-b-20 tx-spacing--1">Property Requests</h4>

        <div class="table-responsive">
            <table class="table mytable">
                    <thead class="text-center">
                        <th >Requested for</th>
                        <th >Date</th>
                        <th >Location</th>
                        <th > Budget</th>
                        <th >Full name</th>
                        <th >Email</th>
                        <th >Phone</th>
                        <th >Action</th>
                    </thead>
                    <tbody>
                       <template v-if="filterRequests.length > 0">
                          <tr v-for="(property,index) in filterRequests" :key="index">
                         <td>{{property.request['request_type']}}</td>
                             <td>{{property.created_at | formatDate }}</td>
                             <td>{{property.request['location']}}</td>
                             <td>{{property.request['min_budget'] |currency}} - {{property.request['max_budget'] |currency}} </td>
                             <td>{{property.request['firstname']+' '+property.request['firstname']}}</td>
                             <td>{{property.request['email']}}</td>
                             <td>{{property.request['phone']}}</td>
                             <td>
                                  <a class="btn btn-success text-white btn-xs" ><i class="fa fa-eye"></i></a>
                                      <!-- <sub-modal :info="Upgrade" ></sub-modal> -->
                             </td>
                         </tr>
                       </template>
                       <template v-else>
                          <tr>
                             No Requests
                          </tr>
                       </template>

                    </tbody>
                </table>
            <nav aria-label="Page navigation example">
                <span> Page {{pagination.current_page}} of {{pagination.last_page}}</span>
                <ul class="pagination pagination-circle mg-b-0 justify-content-end">
                    <li class="page-item " :class="[!pagination.prev_page_url?'disabled':'']"><a class="page-link page-link-icon" @click="fetchPropertiesByPagination(pagination.prev_page_url)"><i class="fa fa-chevron-left"></i></a></li>

                    <li class="page-item " :class="[pagination.current_page==p?'active':'']" v-for="p in pagination.last_page" :key="p"><a class="page-link" @click="fetchPropertiesByPaginationTwo(p)">{{p}}</a></li>
                    <li class="page-item" :class="[!pagination.next_page_url?'disabled':'']"><a class="page-link page-link-icon" @click="fetchPropertiesByPagination(pagination.next_page_url)"><i class="fa fa-chevron-right"></i></a></li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
import Subscribemodal from '../../../components/Subscribemodal'
export default {
    components:{'sub-modal':Subscribemodal},
    data(){
        return{
            pagination:[],
			page_row:1,
            Upgrade:"Upgrade"
        }
    },
    computed:{
        filterRequests(){
            this.getPaginatedData(this.$store.state.leadmodule.request_pagination_data);
            return this.$store.state.leadmodule.requests;
        },
        maskData(){
            if(this.$store.state.authmodule.developersubscription['status'] == 1){
                return true;
            }
            return false;
        }

    },
    methods:{
        getPaginatedData(data){
			let pagination = {
				current_page : data.current_page,
				last_page : data.last_page,
				next_page_url : data.next_page_url,
				prev_page_url : data.prev_page_url,
				total:data.total,
				par_page : data.per_page
			}
			this.pagination = pagination;
			this.page_row = this.pagination.total/this.pagination.per_page;
		},
		fetchPropertiesByPagination( url){
			this.$store.dispatch("requestListAction", url);
		},
		fetchPropertiesByPaginationTwo( page){
			this.$store.dispatch("requestListAction2", page);
		},
        getLatest(){
            this.$store.dispatch("requestListAction");
            this.$store.dispatch("developeSusbcriptionStatusAction")
        }
    },
    created() {
		this.getLatest()

	}
}
</script>

<style lang="css" scoped>
</style>
