<template lang="html">
 <div class="home-slider">
    <div class="home-lead mg-t-50">
        <div class="sign-wrapper ">
            <div class="wd-100p" >
            <h3 class="tx-color-01 mg-b-5">Create New Account</h3>
            <p class="tx-color-03 tx-16 mg-b-5">It's free to signup and only takes a minute</p>
            <form @submit.prevent="register()" method="post">
                <!-- <input type="text" v-model="formData.usertype" hidden value=""> -->
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="company name" :class="{ 'is-invalid': $v.form.company.$error }" name="company" v-model="form.company">
                            <!-- <div v-if="!$v.form.company.required" class="invalid-feedback">Company name is required</div> -->
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="full name"  :class="{ 'is-invalid': $v.form.fullname.$error }" name="fullname" v-model="form.fullname">
                            <!-- <div v-if="!$v.form.fullname.required" class="invalid-feedback">Full name is required</div> -->
                    </div>
                    <div class="form-group">
                        <select  id="" class="form-control" v-validate="'required'" name="country" v-model="form.country"
                        :class="{ 'is-invalid': $v.form.country.$error }">
                            <option value="" selected>select country</option>
                            <option v-for="(c, index) in country" :key="index" :value="c.name">{{c.name}}</option>
                        </select>
                        <!-- <div v-if="!$v.form.country.required" class="invalid-feedback">selection is required</div> -->
                    </div>
                        <div class="form-group">
                        <input type="text" class="form-control" placeholder="Phone number" :class="{ 'is-invalid': $v.form.phone.$error }" name="phone" v-model="form.phone">
                        <!-- <div v-if="!$v.form.phone.required" class="invalid-feedback">phone number  is required</div> -->
                    </div>
                    <div class="form-group">
                        <input type="email" class="form-control" placeholder="yourname@yourmail.com"  v-validate="'required'" name="email" v-model="form.email" :class="{ 'is-invalid': $v.form.email.$error }">
                        <div v-if="!$v.form.email.required" class="invalid-feedback">Email address is required</div>
                    </div>
                    <div class="form-group">
                        <input type="password" class="form-control" placeholder="Enter your password"  v-validate="'required'" name="password" v-model="form.password" :class="{ 'is-invalid': $v.form.password.$error }">
                            <div v-if="!$v.form.password.required" class="invalid-feedback">password of minimum 6 character  is required</div>
                    </div>
                    <button type="submit" class="btn btn-brand-02 btn-block"><i class="fa fa-spinner" v-if="isLoading"></i> <span v-if="!isLoading">Sign Up</span></button>
            </form>

            <div class="tx-13 mg-t-20 tx-center">Already have an account?<a  @click="goLogin()" class="link-url">Sign In</a></div>

    </div>

        </div>
    </div>
    <div class="home-slider-img">
      <div><img src="../../assets/img/ill.jpg" alt=""></div>
      <div><img src="../../assets/img/ill.jpg" alt=""></div>
      <div><img src="../../assets/img/ill.jpg" alt=""></div>
    </div>
</div>
</template>

<script>
import { required, email, minLength, sameAs,requiredUnless,requiredIf } from "vuelidate/lib/validators";
export default {
    data(){
        return{
            isLoading:false,
            form:{
              email:'',
              password:'',
              phone:'',
              company:'',
              country:'',
              fullname:'',
              usertype:'developer'
                  },
        }
    },
    validations() {
        return{
            form:this.rule
        }
    },

    computed:{
			 country(){
				 return this.$store.getters.get_country_list
			 },
             rule(){
                 return{
                     email:{required,email},
                     phone:{required},
                     password:{required,minLength: minLength(6)},
                     company:{required},
                     country:{required},
                     fullname:{required}
                 }
             }
     },
     methods:{
         goLogin(){
             this.$router.push({name:'login'})
         },
         register(){
             this.$v.form.$touch();
             // if its still pending or an error is returned do not submit
             if (this.$v.form.$pending || this.$v.form.$error){
                 return;
             }
            this.isLoading = true;
            this.$store.dispatch('register',this.form).then((r)=>{

                    this.isLoading = false;

            })
            this.isLoading = false;
        }


     }
}
</script>

<style lang="css" scoped>
</style>
