<template>
	 <aside class="aside aside-fixed">
      <div class="aside-header">
		  <img src="../../assets/nhlogo2.png" alt="" >
        <!-- <a  class="aside-logo">New<span>Homes</span></a> -->
        <a href="#" class="aside-menu-link">
          <i data-feather="menu"></i>
          <i data-feather="x"></i>
        </a>
      </div>
      <div class="aside-body">
		  <div class="scroll_d_body">
			  <div class="aside-loggedin">
				<div class="d-flex align-items-center justify-content-start">
				  <a href="#" class="avatar"><img :src="imageUrl+developerinfo['developer_logo']"  class="rounded-circle" alt=""></a>

				  <div class="aside-alert-link">
					<!-- <a href="#" class="new" data-toggle="tooltip" title="You have 2 unread messages"><i data-feather="message-square"></i></a> -->
					 <a href="#" class=""  ></a>
					<a href="#" class="" </a>
					<a v-on:click="logout()"  data-toggle="tooltip" title="Sign out"><i data-feather="log-out"></i></a>
				  </div>
				</div>
				<div class="aside-loggedin-user">
				  <a href="#loggedinMenu" class="d-flex align-items-center justify-content-between mg-b-2" data-toggle="collapse">
					<h6 class="tx-semibold mg-b-0">{{userdata['name'].toUpperCase()}}</h6>
					<i data-feather="chevron-down"></i>
				  </a>
				  <p class="tx-color-03 tx-12 mg-b-0">{{developerinfo['developer_name'] }}</p>
				  <p class="tx-color-03 tx-10 text-italic mg-b-0 text-bold">{{userdata['usertype'] | firstToUpper }}</p>
				</div>
				<div class="collapse" id="loggedinMenu">
				  <ul class="nav nav-aside mg-b-0">
					<!-- <li class="nav-item"><a @click="developerprofile()" class="nav-link"><i data-feather="edit"></i> <span>Edit Profile</span></a></li> -->

					<li class="nav-item"><a  @click="developerprofile()" href="#" class="nav-link"><i data-feather="user"></i> <span>View Profile</span></a></li>
					<!-- <li class="nav-item"><a @click="goAccountPage()" class="nav-link"><i data-feather="settings"></i> <span>Account Settings</span></a></li>
					<li class="nav-item"><a href="#" class="nav-link"><i data-feather="help-circle"></i> <span>Help Center</span></a></li> -->
					<li class="nav-item"><a v-on:click="logout()"  class="nav-link"><i data-feather="log-out"></i> <span>Sign Out</span></a></li>
				  </ul>
				</div>
			  </div><!-- aside-loggedin -->
			  <ul class="nav nav-aside">
				  <li class="nav-item" :class="{active:isActive(['dashboard'])}"><router-link tag="a"  class="nav-link" :to="{name:'dashboard'}" exact><i data-feather="square"></i> <span> Dashboard</span></router-link></li>

							<!-- <router-link tag="li" active-class="active" class="nav-item" :to="{name:'dashboard'}" exact><a class="nav-label" >
								  <i data-feather="square"></i> <span> Dashboard</span>
							  </a>
							  </router-link> -->

				<li class="nav-label">Properties</li>
					  <li class="nav-item with-sub" :class="{active:isActive(['hometable','homenew'])}" ><a  class="nav-link"><i data-feather="home"></i>  <span>Homes</span></a>
					  <ul>
						<li><router-link tag="a" class="in_nav-link" active-class="active" :to="{name:'hometable'}" exact><a>Listed Homes</a></router-link></li>
						<li><router-link tag="a" class="in_nav-link"  active-class="active" :to="{name:'homenew'}" exact><a>List New</a></router-link></li>

					  </ul>
			  </li>
			  <li class="nav-item with-sub" :class="{active:isActive(['plottable','plotnew'])}" >
				  <a  class="nav-link "><i data-feather="layout"></i> <span>Plots</span></a>
				  <ul>
					<li><router-link tag="a" class="in_nav-link" active-class="active" :to="{name:'plottable'}" exact><a> Listed Plots</a></router-link></li>
					<li><router-link tag="a" class="in_nav-link"   active-class="active" :to="{name:'plotnew'}" exact><a>List New</a></router-link></li>

				  </ul>
				</li>

			   <li class="nav-label">Leads</li>
				 <li class="nav-item" :class="{active:isActive(['request'])}"><router-link tag="a"   class="nav-link"active-class="active"  :to="{name:'request'}" exact><i data-feather="shield"></i><span>Property Requests</span></router-link></li>

				 <li class="nav-item" :class="{active:isActive(['enquiry'])}"><router-link tag="a"    class="nav-link" active-class="active"  :to="{name:'enquiry'}" exact><i data-feather="target"></i>  <span>Property Enquiries</span></router-link></li>

				<!-- <li class="nav-item"><a @click="goRequest()" class="nav-link"><i data-feather="shield"></i> <span>Property Request</span></a></li>
				<li class="nav-item"><a @click="goEnquiry()"  class="nav-link"><i data-feather="target"></i> <span>Property Enquires</span></a></li> -->
				<!-- <li class="nav-label mg-t-25">Web Apps</li> -->


				<!-- <li class="nav-label mg-t-25">Pages</li>
				<li class="nav-item with-sub">
				  <a href="#" class="nav-link"><i data-feather="user"></i> <span>User Pages</span></a>
				  <ul>
					<li><a href="page-profile-view.html">View Profile</a></li>
					<li><a href="page-connections.html">Connections</a></li>
					<li><a href="page-groups.html">Groups</a></li>
					<li><a href="page-events.html">Events</a></li>
				  </ul>
				</li>
				<li class="nav-item with-sub">
				  <a href="#" class="nav-link"><i data-feather="file"></i> <span>Other Pages</span></a>
				  <ul>
					<li><a href="page-timeline.html">Timeline</a></li>
				  </ul>
				</li>
				<li class="nav-label mg-t-25">User Interface</li>
				<li class="nav-item"><a href="http://themepixels.me/dashforge/components" class="nav-link"><i data-feather="layers"></i> <span>Components</span></a></li>
				<li class="nav-item"><a href="http://themepixels.me/dashforge/collections" class="nav-link"><i data-feather="box"></i> <span>Collections</span></a></li> -->

			  </ul>
		  </div>




      </div>
	  <div class="bottom_me">
		  <a v-on:click="logout()"  class=""><i data-feather="log-out"></i>    Sign Out</a>
	  </div>
    </aside>


</template>
<script>
export default {
	props:["developer"],
	inject: [ "eventBus", "restApi","imgUrl"],
	data(){
		return{
			developer_logo:''
		}
	},
	computed:{
		imageUrl(){
			return this.imgUrl;
		},
		developerinfo(){
			// console.log(this.$store.state.authmodule.developerprofile)
			let info =this.$store.state.authmodule.developerprofile;
			this.developer_logo = this.imageUrl+info['developer_logo'];
			return info;
		},
			userdata(){
				return this.$store.state.authmodule.user;
			},

	},

	methods:{
		isActive(arr){
			let route_name = this.$route.name;
			let status = false;
			for(var i=0; i<arr.length; i++){
			   var name = arr[i];
			   if(name == route_name){
				 status = true
				 break;
			   }
			 }
			// console.log(this.$route.name);
			//
			// if(this.$router.linkActive || this.$router.linkExactActive){
			// 	return true
			// }
			return status;
		},
		goHomeTable(){

			this.$router.push({name:'hometable'})},
		goNewHome(){this.$router.push({name:'homenew'})},
		goPlotTable(){this.$router.push({name:'plottable'})},
		goNewPlot(){this.$router.push({name:'plotnew'})},
		logout(){
			this.$store.dispatch('logout');
		},
		developerprofile(){
			this.$router.push({name:'profile'})
		},
		goAccountPage(){
				this.$router.push({name:'account'})
		},
		goRequest(){
			this.$router.push({name:'request'})
		},
		goEnquiry(){
			this.$router.push({name:'enquiry'})
		}
	},
	created(){
		this.isActive
	}
}
</script>
<style scoped>
	/* .router-link-active{
		opacity: 1;
	    color: #fff;
	    font-weight: 500;
	    padding: 20px;
			background-color:#0168fa;
	}
		.router-link-exact-active{
			opacity: 1;
		    color: #fff;
		    font-weight: 500;
		    padding: 20px;
				background-color:#0168fa;
	} */
	.nav-correct {
    font-size: 10px;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
    letter-spacing: .5px;
    text-transform: uppercase;
    color: #001737;
    margin-bottom: 5px;
    display: block;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
}
.bottom_me{
	/* border-top: 2px solid #ddd; */
	bottom:0;
	position:fixed;
	height:  20px;
	width:100%;
	margin-bottom: 30px;
	padding:25px;
	border-top:2px solid #ddd;
}
.aside .nav-aside .nav-item {
    position: relative;
    /* min-height: 50px; */
    padding: 5px;
}
.aside-body{
	height: calc(100% - 120px) !important;
	padding:20px !important;

}
.nav-aside .nav-item ul .active a {
    color: #0168fa;
	font-weight: 500;
}

.nav-aside .nav-item.active .nav-link {
    opacity: 1;
    color: #fff !important;;
    font-weight: 500;
    padding: 20px;
		background-color:#0168fa;
}
.nav-aside .nav-item.active svg {
    color: #fff;
    fill: rgba(1, 104, 250, 0.2);
}
 .scroll_d_body{
 		height: calc(100% - 160px);
		position:fixed;
		overflow-y: scroll;
		overflow-x:hidden;
		padding:0 5px;
		width:90%;
 }
 .scroll_d_body::-webkit-scrollbar {
    width: 5px;
	/* background-color:#0168fa; */
}

.scroll_d_body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius:5px;
}

.scroll_d_body::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
/* .scroll_d_body::-webkit-scrollbar-button{
  width: 3px;
  height: 13px;
  background: url('https://pixabay.com/static/uploads/photo/2015/10/07/10/55/arrow-975992_960_720.png') no-repeat center center;
  background-size:cover;
} */
</style>
