<template lang="html">
    <div class="table-responsive">
        <table class="table  mytable">
            <slot name="table-body"></slot>
        </table>
        <!-- <slot name="pagination_nav"></slot> -->
        <nav aria-label="Page navigation example">
            <span> Page {{pagination.current_page}} of {{pagination.last_page}}</span>
            <ul class="pagination pagination-circle mg-b-0 justify-content-end">
                <li class="page-item " :class="[!pagination.prev_page_url?'disabled':'']"><a class="page-link page-link-icon" @click="fetchPropertiesByPagination(pagination.prev_page_url)"><i class="fa fa-chevron-left"></i></a></li>

                <li class="page-item " :class="[pagination.current_page==p?'active':'']" v-for="p in pagination.last_page" :key="p"><a class="page-link" @click="fetchPropertiesByPaginationTwo(p)">{{p}}</a></li>
                <li class="page-item" :class="[!pagination.next_page_url?'disabled':'']"><a class="page-link page-link-icon" @click="fetchPropertiesByPagination(pagination.next_page_url)"><i class="fa fa-chevron-right"></i></a></li>
            </ul>
        </nav>

    </div>
</template>

<script>
export default {
    props:['data','action_one','action_two','pagination'],
    data(){
        return{
            // pagination:[],
			page_row:1,
        }
    },

    methods:{
        // getPaginatedData(){
        //     let pagination = {
        //         current_page : this.data.current_page,
        //         last_page : this.data.last_page,
        //         next_page_url : this.data.next_page_url,
        //         prev_page_url : this.data.prev_page_url,
        //         total:this.data.total,
        //         par_page : this.data.per_page
        //     }
        //
        //     this.pagination = pagination;
        //
        //         this.page_row = this.pagination.total/this.pagination.per_page;
        //         console.log(this.pagination.total/this.pagination.per_page)
        //             // this.$store.dispatch("propertyListAction",page);
        // // },
        fetchPropertiesByPagination( url){
                console.log(url)
            this.$store.dispatch(this.action_one, url);
        },
        fetchPropertiesByPaginationTwo( page){
            this.$store.dispatch(this.action_two, page);
        }
    },
    created(){
        // this.getPaginatedData();
    }
}
</script>

<style lang="css" scoped>
table{
        border-collapse: seperate;
    border-spacing: 0 8px !important;
}
tr{
    background: #fff;
}
.spacer{
    height: 20px;
    width:100%;
}
</style>
