<template>
	<div>
			<!-- view and edit -->
		<div class="container  pd-x-0">
			<div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
				<div>
					<breadcrum first="Properties" second="View Home"></breadcrum>
					<h4 class="mg-b-0 tx-spacing--1"></h4>
				</div>
				<div class=" d-md-block">
					<router-link tag="button" :to="{name:'hometable'}"  class="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
						<i  class="fa fa-eye wd-10 mg-r-5"></i> View List
					</router-link>
					<button class="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5" v-if="vieworedit" @click.prevent="toggleView()"><i  class="fa fa-eye wd-10 mg-r-5"></i> View {{property.property_name}}</button>
					<button class="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5" v-if="!vieworedit" @click.prevent="toggleView()"><i  class="fa fa-eye wd-10 mg-r-5"></i> Edit {{property.property_name}}</button>
				</div>
			</div>
			<div  v-if="!vieworedit">

					<div class="card card-body">
						<div class="row">
							<div class="col-md-2 "></div>
							<div class="col-md-8 ">
								<lingallery :iid.sync="currentId" :width="width" :height="height" :items="photoitem"/>
							</div>
							<div class="col-md-2 "></div>
						</div>
						<div style="height:30px"></div>
						<hr>
						<div class="row">

								<div class="col-md-6">
									<div class="nh-single-page-property-name">{{property.property_name}}</div>
									<div class="nh-single-page-property-address mt-1"> <i class="fa fa-location"></i> {{property.property_full_address}}</div>
								</div>
								<div class="col-md-6">
									<div class="nh-single-page-price position-relative"><span id="nh-pick-price"> {{property.property_price_raw | currency}}</span></div>
									<!-- {{property.currency_symbol}} -->
								</div>


						</div>
						<!-- proeprty info -->
						<div class="row">
							<div class="col-md-6 nh-single-page-fixed-height-130px nh-property-details">
								<ul class="list-unstyled d-flex nh-single-page-property-features">
									<li><img src="../../../assets/images/beds.png" class="nh-single-page-property-features-icon">
										<span><strong>{{property.property_nonnumeric_bed}}</strong> Beds</span>
									</li>
									<li><img src="../../../assets/images/baths.png" class="nh-single-page-property-features-icon">
										<span><strong>{{property.property_nonnumeric_bath}}</strong> Bathss</span>
									</li>
									<li><img src="../../../assets/images/garage.png" class="nh-single-page-property-features-icon">
										<span><strong>{{property.property_nonnumeric_garage}}</strong> garages</span>
									</li>
									<li><img src="../../../assets/images/size.png" class="nh-single-page-property-features-icon">
										<span><strong>{{property.property_size}}</strong> SQM</span>
									</li>
								</ul>
							</div>
							<div class="col-md-6 nh-single-page-fixed-height-130px-no-border nh-property-status">
								<!-- property status -->
								<div class="row my-3">
									<div class="col-md-3 col-xs-4">
										<div class="title">Property Status</div>
										<p class="title p"><label class="badge badge-info">{{property.property_status}}</label></p>
									</div>
									<div class="col-md-3 col-xs-4">
										<div class="title">Finance Status</div>
										<p class="title p"><label class="badge badge-secondary">{{property.finance_option}}</label></p>

									</div>
									<div class="col-md-3 col-xs-4">
										<div class="title">Sale Status</div>
										<p class="title p"><label class="badge badge-danger" v-if="property.property_issold ==1">sold</label>
											<label class="badge badge-secondary" v-else>Available</label>
										</p>

									</div>
									<div class="col-md-3 col-xs-4">
										<div class="title">Property Title</div>
										<p class="title p"><label class="badge badge-secondary">{{property.finance_option}}</label></p>

									</div>
								</div>
							</div>
						</div>
						<!-- proeprty description -->
						<div class="row">
							<div class=" nh-property-description-container" style="max-height:300px;width:100%; overflow-y:scroll;padding:20px;">
								<div class="nh-property-description-title">Description</div>
								<div class="nh-property-description-content">
									<div v-html="property.property_description"></div>
								</div>
							</div>
						</div>
						<!-- property Amenities -->
						<div class="row">
							<section id="nh-property-amenities mt-2">
								<div class="nh-property-amenities-container">
									<div class="tab-content" id="nh-property-amenities-TabContent">
										<div class="tab-pane fade show active" id="nh-amenities" role="tabpanel" aria-labelledby="nh-amenities-tab">
											<div class="nh-amenities-title" >Amenities</div>
											<div class="nh-amenities-lists-container">
												<ul class="list-unstyled d-flex flex-wrap">
													<li v-for="(a, i) in currentAmenity" :key="i">
														<img class="nh-amenities-icon" :src="imageUrl+a.amenity_icon" :alt="a.amenity_name">{{a.amenity_name}}
													</li>
												</ul>
											</div>
										</div>
									</div>
							</div>
							</section>
						</div>
						<!-- proeprty type -->
						<div class="row">
							<div class="tab-pane fade show active" id="nh-property-types" role="tabpanel" aria-labelledby="nh-property-types" style="padding:30px;">
								<div class="d-flex">
									<div class="flex-fill nh-property-type-title">Property Types</div>
									<div class="d-flex nh-property-select-status-wrapper">
										<div class="flex-fill position-relative property-select-status">
											<span class="property-type-status status-available mr-2 rounded-1 px-2">&nbsp;</span>Available
										</div>
										<div class="flex-fill  position-relative property-select-status">
													<span class="property-type-status status-sold mr-2 rounded-1 px-2">&nbsp;
													</span>Sold Out
										</div>
									</div>

								</div>
									<div class="property-type-container">
											<div class="list" v-for="(p, i) in currentPropertyType" :key="i">
												<div class="property-type-wrapper d-flex mb-3">
													<div class="col-md-4">
														<div class="property-status-with-type">
															<span class="badge  type-status" :class="[p.is_sold == 0?'badge-success':'badge-danger']" >&nbsp;</span>
															{{p.hometype}}
														</div>
													</div>
													<div class="col-md-4 m-active"><div class="property-border-line"></div></div>

													<div class="col-md-4">
														<div class="property-type-price">{{p.price | currency}}</div>
													</div>
												</div>
											</div>
									</div>

							</div>
						</div>

					</div>


			</div>
			<div v-if="vieworedit">
				<ul class="nav nav-line nav-justified nav-line" id="myTab" role="tablist">
					<li class="nav-item">
						<a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Property Info</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Property Types</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Amenities</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" id="gallery-tab" data-toggle="tab" href="#gallery" role="tab" aria-controls="gallery" aria-selected="false">Gallery</a>
					</li>
				</ul>
				<div class="tab-content bd bd-gray-300 bd-t-0 pd-20" id="myTabContent">
					<div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
						<form method="POST" @submit.prevent="updateInfo()" >
							<div class="row">
									<div class="d-sm-flex align-items-center  mg-b-20 mg-lg-b-25 float-right">
										<button type="submit" class="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5 "><i  class="fa fa-cloud wd-10 mg-r-5"></i> Update info</button>
							</div>
							</div>
							<div class="row">
								<div class="col-md-4">
									<div class="form-group">
										<label for="t">Is this an estate or a single property ?</label>
										<select name id="t"  class="custom-select" v-model="property.is_estate" :class="{ 'is-invalid': $v.form.is_estate.$error }" >
											<option :value="property.is_estate" >{{property.is_estate ==1?'Estate Property':'Single Property'}}</option>
											<option value="0">Single Property</option>
											<option value="1">Estate Property</option>
										</select>
										<div v-if="!$v.form.is_estate.required" class="invalid-feedback">selection is required</div>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group">
										<label for="t">select property currency</label>
										<div class="input-group">
											<div class="input-group-prepend">

											</div>
											<select name id="currencyid" class="form-control" v-model="property.base_currency" @change="updateSymbol()" :class="{ 'is-invalid': $v.form.base_currency.$error }" >
													<option :value="property.base_currency" selected>{{property.base_currency}}</option>
													<option :value="c.currency_code+'+'+c.currency_symbol" v-for="(c , index) in currency" :key="index"  >{{c.currency_code}}</option>
												</select>
											<input type="text"  v-model="property.currency_symbol" class="form-control" disabled id="currency_symbol" :class="{ 'is-invalid': $v.form.base_currency.$error }"/>
											<div v-if="!$v.form.base_currency.required" class="invalid-feedback">select property base currency</div>
										</div>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group">
										<label for="name">Property name</label>
										<input type="text" class="form-control" placeholder="property name" v-model="property.property_name"
										:class="{ 'is-invalid': $v.form.property_name.$error }">
											<div v-if="!$v.form.property_name.required" class="invalid-feedback">Property name is required</div>
									</div>
								</div>
								<div class="col-md-4">
										<div class="form-group">
										<label for="name">SQM</label>
										<input type="text" class="form-control" placeholder="property square metre" v-model="property.property_size"
											:class="{ 'is-invalid': $v.form.property_size.$error }">
										<div v-if="!$v.form.property_size.required" class="invalid-feedback">Property size is required</div>
										</div>
								</div>
								<div class="col-md-4">
													<div class="form-group">
															<label for="country" class="form-control-label">Country <span class="tx-danger">*</span></label>
												<select class="form-control  cloneme" name="property_country" id="country"  v-model="property.property_country" 	:class="{ 'is-invalid': $v.form.property_country.$error }">
													<option :value="property.property_country" selected>{{property.property_country}}</option>
													<option v-for="(c, index) in country" :key="index" :value="c.name">{{c.name}}</option>
												</select>
												<div v-if="!$v.form.property_country.required" class="invalid-feedback">property country is required</div>
													</div>
											</div>
											<!-- Address -->
											<div id="locationField" class="col-md-4">
												<div class="form-group">
													<label class="form-control-label">Property Full Address: <span class="tx-danger">*</span></label>
												<input id="autocomplete" class="form-control" name="property_address" placeholder="Enter property address" onFocus="geolocate()" type="text" required v-model="property.property_full_address" :class="{ 'is-invalid': $v.form.property_full_address.$error }">
												<div v-if="!$v.form.property_full_address.required" class="invalid-feedback">property full address is required</div>
												</div>

											</div>
											<div class="col-md-4">
												<div class="form-group">
													<label class="form-control-label">Property City<span class="tx-danger">*</span></label>
												<input class="form-control" type="text" id="locality" placeholder="Enter property city" required v-model="property.property_city" :class="{ 'is-invalid': $v.form.property_city.$error }">
												<div v-if="!$v.form.property_city.required" class="invalid-feedback">property city is required</div>
												</div>
											</div>
											<div class="col-md-4">
													<div class="form-group">
															<label class="form-control-label">Property State<span class="tx-danger">*</span></label>
												<input class="form-control" name="property_state" type="text" id="administrative_area_level_1" placeholder="Enter property state" required v-model="property.property_state" :class="{ 'is-invalid': $v.form.property_state.$error }">
												<div v-if="!$v.form.property_state.required" class="invalid-feedback">property state is required</div>
													</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
															<label class="form-control-label">Property Neighborhood <span class="tx-danger">*</span></label>
												<input class="form-control"  placeholder="eg VI" type="text" v-model="property.property_neighborhood"
												:class="{ 'is-invalid': $v.form.property_neighborhood.$error }" >
												<div v-if="!$v.form.property_neighborhood.required" class="invalid-feedback">property neighborhood is required</div>

												</div>

											</div>
							</div>
							<div class="row">
								<div :class="[is_payment_plan?'col-md-4':'col-md-6']">
									<div class="form-group">
										<label class="form-control-label">Finance plan<span class="tx-danger">*</span></label>
										<select class="form-control" name="finance_option" required v-model="property.finance_option"
										:class="{ 'is-invalid': $v.form.finance_option.$error }">
											<option :value="property.finance_option" selected>{{property.finance_option}}</option>
											<option :value="c.option" v-for="(c , index) in finance" :key="index" >{{c.option}}</option>
										</select>
										<div v-if="!$v.form.finance_option.required" class="invalid-feedback">select property finance option</div>
									</div>

								</div>
								<div class="col-md-4 " id="payment_option_div" v-if="is_payment_plan">
									<div class="form-group">
										<label class="form-control-label">Payment plan<span class="tx-danger">*</span></label>
										<select class="form-control"  required v-model="property.payment_option"
										:class="{ 'is-invalid':is_payment_plan&& $v.form.payment_option.$error }">
											<option :value="property.payment_option">{{property.payment.option}}</option>
											<option value="1 year" >One year</option>
											<option value="6 months" >Six Month</option>

										</select>
										<div v-if="!$v.form.payment_option.required &&is_payment_option" class="invalid-feedback">select property finance option</div>
									</div>

								</div>
								<div :class="[is_payment_plan?'col-md-4':'col-md-6']">
									<div class="form-group">
										<label class="form-control-label">Building Permit<span class="tx-danger">*</span></label>
										<select class="form-control" name="building_permit" required v-model="property.property_has_permit"
											:class="{ 'is-invalid': $v.form.property_has_permit.$error }">
											<option :value="property.property_has_permit" selected>{{property.property_has_permit==1?'Yes':'No'}}</option>
											<option value="1">Yes</option>
											<option value="0">No</option>
										</select>
											<div v-if="!$v.form.property_has_permit.required" class="invalid-feedback">selection is required</div>
									</div>

								</div>
								<div class="col-md-12">
									<label class="form-control-label">Property documents<span class="tx-danger">*</span></label>
												<div class="col-md-4" v-for="(a, index) in titledoc" :key="index">
														<div class="custom-control custom-radio">
															<input type="radio" :id="a.title" name="customRadio" class="custom-control-input" v-model="property.title_docs" :value="a.title" :class="{ 'is-invalid': $v.form.title_doc.$error }" :checked="property.title_docs==a.title">
															<label class="custom-control-label" :for="a.title" >{{a.title}}</label>
														</div>
														<div v-if="!$v.form.title_doc.required" class="invalid-feedback">selection is required</div>
												</div>
								</div>
							</div>
							<div class="row">
											<div class=" col-md-12 ">
												<label class="form-control-label">Property Description <span class="tx-danger">*</span></label>
												<ckeditor :editor="editor" v-model="property.property_description" :config="editorConfig" :class="{ 'is-invalid': $v.form.property_description.$error }"></ckeditor>
												<div v-if="!$v.form.property_description.required" class="invalid-feedback">property description is required</div>
											</div>

									</div>


						</form>

					</div>
					<div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
						<div >
							<button class="btn btn-success float-right clearfix" data-toggle="modal" data-animation="effect-slide-in-right"
													@click.prevent="addPropertyTypeModalShow()"><i class="fa fa-plus"></i></button>
						</div>

								<div class="table-responsive" style="margin-top:20px;">
											<table class="table mytable">
												<thead>
													<th>s/n</th>
													<th style="width:20%" >Property type</th>
													<th >Price</th>
													<th >Bedroom</th>
													<th >Bathroom</th>
													<th >Garage</th>
													<th >Property Status</th>
													<th >Sale Status</th>
													<th>Action</th>
												</thead>
												<tbody>
													<tr v-for="(property, index) in property.homeprice" :key="index">
														<td>{{index+1}}</td>
														<td>{{property.hometype}}</td>
														<td>{{property.price | currency}}</td>
														<td>{{property.bedrooms}}</td>
														<td>{{property.bathrooms}}</td>
														<td>{{property.garages}}</td>
														<td>{{property.status_name}}</td>
														<td><span v-if="property.is_sold == 0">Available</span><span v-else>Sold</span></td>
														<td>
															<button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-animation="effect-slide-in-right" @click.prevent="editPropertyType(property)"><i class="fa fa-edit" ></i></button>&nbsp;
															<button type="button" class="btn btn-danger btn-sm"><i class="fa fa-trash" @click.prevent="deletePropertyType(property.id)"></i></button>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
					</div>
					<div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
						<form>
						<div class="row">
								<div class=" col-md-3" v-for="(a, index) in amenity" :key="index">
										<div class="form-group">
											<div class="custom-control custom-checkbox" >
													<input  :checked="checkedAmenity(a.id)"  type="checkbox" class="custom-control-input"  :id="a.id"  v-model="amenities"
											  :value="a.id">
													<label class="custom-control-label" :for="a.id">{{a.name}}</label>
											</div>
										</div>
								</div>
								<div class="row">
										<div class="d-sm-flex align-items-center  mg-b-20 mg-lg-b-25 float-right">
										<button type="submit" class="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5" @click.prevent="updateHomeAmenity(property.id)"><i  class="fa fa-cloud wd-10 mg-r-5"></i> Update info</button>
										</div>
								</div>
						</div>
						</form>

					</div>
					<div class="tab-pane fade" id="gallery" role="tabpanel" aria-labelledby="gallery-tab">
						<div class="row">
								<gallery :images="property.property_photo" :index="index" @close="index = null"></gallery>
									<div
										class="col-md-3 col-xs-12 image"
										v-for="(image, imageIndex) in property.property_photo"
										:key="imageIndex"
										@mouseover="mouseOver"
										:style="{ backgroundImage: 'url(' +imageUrl+ image.filename + ')', width: '100%', height: '150px', backgroundSize:'cover', BackgroundPosition:'50%', backgroundRepeat:'no-repeat'}"
									>
									<i class="fa fa-trash padme" @click="deletePropertyImage(image.id)" ></i>
										<!-- <i class="fa fa-trash padme" v-if="showDeleteIcon" ></i> -->
									</div>

									<div>
										<form>
										<vue-upload-multiple-image
											@upload-success="uploadImageSuccess"
											@before-remove="beforeRemove"
											@edit-image="editImage"
											dragText="Click to add images"
											:browseText="browseText"
											primaryText="Active"
										popupText="Your Total gallery files should not exceed 6"
											markIsPrimaryText="File"
											accept="image/jpeg,image/png,image/jpg"
											:max-image="maxImage"

											@limit-exceeded="exceedImageLimit"></vue-upload-multiple-image>

										<button type="submit" class="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-10 mg-r-10 mg-t-10 " @click.prevent="addPropertyImage"><i  class="fa fa-cloud wd-10 mg-r-5"></i> Upload Files</button>
										</form>
									</div>

						</div>
						<!-- <div class="row">
							<div v-for="(image, imageIndex) in property.property_photo" :key="imageIndex" class="col-md-3 pd-0">
								<div  class="p-image" :style="{ backgroundImage: 'url(' +imageUrl+ image.filename + ')'}">

										 <i class="fa fa-trash padme" @click="deletePropertyImage(image.id)" ></i>

								</div>
							</div>
						</div> -->

					</div>
				</div>
			</div>
		</div>
		<!-- modal -->
		<div class="modal fade" id="property_type" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel6" aria-hidden="true">
						<div  class="modal-dialog modal-dialog-centered" role="document">
							<div class="modal-content tx-14">
								<div class="modal-header">
									<h6 class="modal-title" id="exampleModalLabel6">Add Property Type</h6>
									<button type="button" class="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
									<form id="form" method="POST" >
										<div class="modal-body">
											<input type="text" v-model="property_type_form.propertyid"  hidden id="id">
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label for="status" class="form-control-label">Property Type <span class="tx-danger">*</span></label>
															<select class="form-control" name="hometype" id="hometype" v-model="property_type_form.hometype"
															:class="{ 'is-invalid': $v.property_type_form.hometype.$error }" >
																<option label="Choose one" v-if="!editHometype"></option>
																<option v-if="editHometype" :value="property_type_form.hometype+'+'+property_type_form.hometype_icon" selected >{{property_type_form.hometype}}</option>
																<option v-for="(h, index) in hometype" :key="index" :value="h.name+'+'+h.icon">{{h.name}}</option>
															</select>
															<div v-if="!$v.property_type_form.hometype.required" class="invalid-feedback">selection is required</div>
														</div>
													</div>
													<div class="col-md-6">
														<div class="form-group">
															<label for="price" class="form-control-label">Type Price <span class="tx-danger">*</span></label>
															<input type="text" id="price" name="price" class="form-control currency_input" v-model="property_type_form.price" placeholder="eg 15,000,000" required :class="{ 'is-invalid': $v.property_type_form.price.$error }">
															<div v-if="!$v.property_type_form.price.required" class="invalid-feedback">Price is required</div>
														</div>
													</div>
													<div class="col-md-6">
														<div class="form-group">
															<label for="status" class="form-control-label">Property Status <span class="tx-danger">*</span></label>
															<select class="form-control " name="status" id="status" required v-model="property_type_form.status" :class="{ 'is-invalid': $v.property_type_form.status.$error }">
															<option label="Choose one" v-if="!editHometype"></option>
																<option :value="property_type_form.status" selected v-if="editHometype">{{property_type_form.status_name}}</option>
																<option v-for="(p,index) in propertystatus" :key="index" :value="p.id">{{p.name}}</option>
															</select>
															<div v-if="!$v.property_type_form.status.required" class="invalid-feedback">selection is required</div>
														</div>
													</div>
													<div class="col-md-6">
														<div class="form-group">
															<label for="price" class="form-control-label">Bathroom<span class="tx-danger">*</span></label>
															<input type="number" id="bathroom" name="bathroom" class="form-control " placeholder="" v-model="property_type_form.bathrooms"  :class="{ 'is-invalid': $v.property_type_form.bathrooms.$error }">
															<div v-if="!$v.property_type_form.bathrooms.required" class="invalid-feedback">Bathroom is required</div>
														</div>
													</div>
													<div class="col-md-6">
														<div class="form-group">
															<label for="price" class="form-control-label">Bedroom<span class="tx-danger">*</span></label>
															<input type="number" id="bedroom" name="bedroom" class="form-control " placeholder="" v-model="property_type_form.bedrooms" :class="{ 'is-invalid': $v.property_type_form.bedrooms.$error }">
																<div v-if="!$v.property_type_form.bedrooms.required" class="invalid-feedback">Bedroom is required</div>
														</div>
													</div>
													<div class="col-md-6">
														<div class="form-group">
															<label for="price" class="form-control-label">Garage<span class="tx-danger">*</span></label>
															<input type="number" id="garage" name="garage" class="form-control e" placeholder="" v-model="property_type_form.garages" :class="{ 'is-invalid': $v.property_type_form.garages.$error }">
															<div v-if="!$v.property_type_form.garages.required" class="invalid-feedback">Garage is required</div>
														</div>
													</div>
													<div class="col-md-6">
														<div class="form-group">
															<label for="status" class="form-control-label">Sales Status <span class="tx-danger">*</span></label>
															<select class="form-control" name="is_sold" id="issold" v-model="property_type_form.is_sold"
															:class="{ 'is-invalid': $v.property_type_form.is_sold.$error }">
																<option label="Choose one"></option>
																<option value="0">Available</option>
																<option value="1">Sold</option>

															</select>
															<div v-if="!$v.property_type_form.is_sold.required" class="invalid-feedback">selection is required</div>
														</div>
													</div>
												</div>


										</div>
										<div class="modal-footer">
											<button type="button" class="btn btn-secondary tx-13" data-dismiss="modal">Close</button>
											<button type="submit" class="btn btn-primary tx-13" @click.prevent="addHometype()" v-if="!editHometype" >Add</button>
											<button type="submit" class="btn btn-primary tx-13" @click.prevent="updatePropertyType(property_type_form.id)" v-if="editHometype" >Update</button>
										</div>
								</form>
							</div>
						</div>
					</div>
	</div>
</template>
<script>
 import { required, email, minLength, sameAs,requiredUnless,requiredIf } from "vuelidate/lib/validators";

	import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
	import {propertyMixin} from '../../../mixins/properties'
export default {
	inject: [ "eventBus", "restApi","imgUrl"],
	mixins:[propertyMixin],
	components: {
 	},
	data() {
		return {
				width: 600,
			height: 300,
			currentId: null,
			imageitem:[],
			slickOptions: {
                slidesToShow: 3,
                // Any other options that can be got from plugin documentation
            },
			vieworedit:false,
			 editor: ClassicEditor,
			editorConfig: { },
			slug:this.$route.params.slug,
			selected_currency:'',
			editHometype:false,
			index: null,
			showDeleteIcon:false,
			amenities:[],
			maxImage:parseInt(6),
				images:[],
			selectedFile:'',
			browseText:'Maximum of 6 files',
			propertyid:'',
			form:{
				base_currency:'',
				currency_symbol:'',
				is_estate:'',
				property_name:'',
				property_size:'',
				property_country:'',
				property_state:'',
				property_city:'',
				property_neighborhood:'',
				property_full_address:'',
				property_description:'',
				finance_option:'',
				payment_option:'',
				property_has_permit:'',
				title_doc:'',
				property_image:'',
			},
				property_type_form:{
				propertyid:'',
				hometype:'',
				price:'',
				bedrooms:'',
				bathrooms:'',
				garages:'',
				status:'',
				status_name:'',
				is_sold:'',
				hometype_icon:''
			},
			currentAmenity:[],
			currentPropertyType:[]
		};
	},
	watch:{
		'$route'(to, from){
			this.slug = to.params.slug

		},
	},
	computed:{
		imageUrl(){
			console.log(this.imgUrl)
			return this.imgUrl;
		},
		property(){
					let info = this.$store.getters.get_home_details;
					this.propertyid = info.id;
					this.form.base_currency=info.base_currency;
					this.form.currency_symbol=info.currency_symbol;
					this.form.is_estate=info.is_estate;
					this.form.property_name=info.property_name;
					this.form.property_size=info.property_size;
					this.form.property_country=info.property_country;
					this.form.property_state=info.property_state;
					this.form.property_city=info.property_city;
					this.form.property_neighborhood=info.property_neighborhood;
					this.form.property_full_address=info.property_full_address;
					this.form.property_description=info.property_description;
					this.form.finance_option=info.finance_option;
					this.form.payment_option=info.payment_option;
					this.form.property_has_permit=info.property_has_permit;
					this.form.title_doc=info.title_docs
					this.form.property_image=info.property_image;
					this.property_type_form.propertyid = info.id;
					this.currentAmenity = info.amenity;
					this.currentPropertyType = info.homeprice;
					this.imageitem = info.property_photo;
// console.table(info.amenity)

			return info;
		},
		photoitem(){
			let i =[];
			let url = this.imgUrl;
			// console.log(url)
			this.imageitem.map(function(k){
				console.log(url+k.filename)
				let p =  {
				src:url+k.filename,
				thumbnail: url+k.filename,
				caption: '',
				id: ''
				}
				i.push(p);
			})
			return i;
		},
		propertyImages(){
			for(let i=0; i<=this.property.property_photo.length;i++){
				this.images.push(this.imageUrl+this.property.property_photo[i].filename)
			}
			return this.images;

		},

		rules(){
				return{
						base_currency:{ required },
						is_estate:{ required },
						property_name:{ required },
						property_size:{ required },
						property_country: { required },
						property_state: { required },
						property_city: { required },
						property_neighborhood: { required },
						property_full_address: { required },
						property_description: { required },
						finance_option:{ required },
						property_has_permit:{ required },
						title_doc:{ required },
				}
			},
			typeformrule(){
				return{
								hometype:{required},
								price:{required},
								bedrooms:{required},
								bathrooms:{required},
								garages:{required},
								status:{required},
								is_sold:{required}

				}
			},
		},
	validations() {
		return{
					form:this.rules,
					property_type_form:this.typeformrule
		}
	},
	methods: {
		next() {
            this.$refs.slick.next();
        },

        prev() {
            this.$refs.slick.prev();
        },

        reInit() {
            // Helpful if you have to deal with v-for to update dynamic lists
            this.$nextTick(() => {
                this.$refs.slick.reSlick();
            });
        },
			hometypeName(name){
			let n = this.name.split("+");
			let real = n[0];
			return real
		},
			mouseOver(){
				this.showDeleteIcon = !this.showDeleteIcon
			},
			toggleView(){
			this.vieworedit = !this.vieworedit
		},
			updateSymbol(){
			let s = this.property.base_currency.split("+");
			this.property.base_currency = s[0];
			this.property.currency_symbol=s[1];

		},
		editPropertyType(property){
			this.editHometype = true;
			this.property_type_form = property;
				this.property_type_form.propertyid=property.propertyid
				this.property_type_form.hometype=property.hometype
				this.property_type_form.price=property.price
				this.property_type_form.bedrooms=property.bedrooms
				this.property_type_form.bathrooms=property.bathrooms
				this.property_type_form.garages=property.garages
				this.property_type_form.status=property.status
				this.property_type_form.status_name=property.status_name
				this.property_type_form.is_sold=property.is_sold
				this.property_type_form.hometype_icon=property.hometype_icon
			console.log(this.property_type_form);
			$("#property_type").modal("show");

		},
		addPropertyTypeModalShow(){
			this.editHometype = false;
			$("#property_type").modal("show");

		},
		showModal() {

			$("#property_type").modal("show");

		},
		showProperty(slug){
			 this.$store.dispatch("showPropertyBySlugAction",slug);
		},
		onAmenityChange(value, $event){
			if (!this.property.amenities)
				this.property.amenities = []

			const index = this.property.amenities.findIndex(v => v == value)
			const checked = $event.target.checked

			if (checked && index < 0)
				this.property.amenities.push(value)
			if (!checked && index >= 0)
				this.property.amenities.splice(index, 1)
		},
		checkedAmenity(id) {

			return this.currentAmenity.findIndex((item) => item.amenityid  == id);


		},
		updateInfo(){
			let data = {
				form:this.form,
				slug:this.slug
			}
			this.$v.form.$touch();
          // if its still pending or an error is returned do not submit
          if (this.$v.form.$pending || this.$v.form.$error){

						return;
					}
			this.$store.dispatch("updatePropertyInfoAction", data).then((res)=>{
				this.showProperty(this.slug);
					this.$swal({
						title: 'Success',
						text: res.message,
						type: 'success',
						confirmButtonColor: '#10b759',
					}).then((result) => {
						if (result.value) {
								this.vieworedit = false;
						}
					})
			}).catch((err)=>{
							this.$swal({
						title: 'Oops',
						text: err,
						type: 'error',
					});
			})
		},
		addHometype(){
			this.editHometype = false;
			this.$v.property_type_form.$touch();
          // if its still pending or an error is returned do not submit
          if (this.$v.property_type_form.$pending || this.$v.property_type_form.$error){

						return;
					}
				this.$store.dispatch("addHometypeAction", this.property_type_form).then((res)=>{
					console.log(res);
					$("#property_type").modal("hide");
						this.showProperty(this.slug);
						this.$swal({title: 'Success',text: res,type: 'success',confirmButtonColor: '#10b759',}).then((result) => {
							if (result.value) {
									// this.vieworedit = false;
							}
						})

				}).catch((err)=>{
								this.$swal({
								title: 'Oops',
								text: err,
								type: 'error',
							});
				})



		},
		updatePropertyType(id){
					this.$v.property_type_form.$touch();
          // if its still pending or an error is returned do not submit
          if (this.$v.property_type_form.$pending || this.$v.property_type_form.$error){

						return;
					}
						let data = {
				form:this.property_type_form,
				id:id
			}
					this.$store.dispatch("updateHometypeAction", data).then((res)=>{
					$("#property_type").modal("hide");
							this.editHometype = false;
									this.showProperty(this.slug);
						this.$swal({title: 'Success',text: res,type: 'success',confirmButtonColor: '#10b759',}).then((result) => {
							if (result.value) {

							}
						})

				}).catch((err)=>{
								this.$swal({
								title: 'Oops',
								text: err,
								type: 'error',
							});
				})
		},
		deletePropertyType(id){
			console.log(id);
					this.$swal({
						title: 'Confirmation',
						text: "Are you sure ?",
						type: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Yes, delete it!'
					}).then((result) => {
						if (result.value) {
							this.$store.dispatch("deleteHometypeAction",id).then((res)=>{
									this.showProperty(this.slug);
									this.$swal(
								'Deleted!',
								res,
								'success'
							)

							})

						}
					})
		},
		updateHomeAmenity(id){
				let data = {
				form:this.amenities,
				id:id
			}
					this.$store.dispatch("updateHomeAmenityAction", data).then((res)=>{
									this.showProperty(this.slug);
						this.$swal({title: 'Success',text: res,type: 'success',confirmButtonColor: '#10b759',}).then((result) => {
							if (result.value) {

							}
						})

				}).catch((err)=>{
								this.$swal({
								title: 'Oops',
								text: err,
								type: 'error',
							});
				})
		},
		onFileChange(e){
			var selectedFiles = e.target.files;
			var local=[];
					for (var i=0; i < selectedFiles.length; i++){

						let filename = selectedFiles[i].name;
						if(filename.lastIndexOf('.') <=0){
							return alert("one of your image is not valid");
						}
						this.images.push(URL.createObjectURL(selectedFiles[i]))
						const reader = new FileReader();
						reader.addEventListener('load', ()=>{

								reader.readAsDataURL(selectedFiles[i]);
						})
						local.push(reader.result);
						console.log(local);




					}
		},
		addPropertyImage(){
			if(this.images.length > 0){
				let property_photo = {
					photo:this.images,
					propertyid:this.propertyid
				}
					this.$store.dispatch("addPropertyImageAction", property_photo).then((res)=>{
				this.showProperty(this.slug);
					this.$swal({
						title: 'Success',
						text: res.message,
						type: 'success',
						confirmButtonColor: '#10b759',
					}).then((result) => {
						if (result.value) {
								// this.vieworedit = false;
						}
					})
			}).catch((err)=>{
							this.$swal({
						title: 'Oops',
						text: err,
						type: 'error',
					});
			})
			}
				console.log(this.images)
				console.log(this.propertyid);

		},
		deletePropertyImage(id){
			this.$swal({
				title: 'Confirmation',
				text: "Are you sure ?" ,
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch("deletePropertyImage",id).then((res)=>{
							this.showProperty(this.slug);
							this.$swal(
						'Deleted!',
						res,
						'success'
					)

					})

				}
			})
		},
		onChanged() {
				console.log("New picture loaded");
				if (this.$refs.pictureInput.file) {
					this.selectedFile = this.$refs.pictureInput.file;
				} else {
					console.log("Old browser. No support for Filereader API");
				}
  },
  	onRemoved() {
    this.selectedFile = '';
	},
	 uploadImageSuccess(formData, index, fileList) {
			fileList.forEach((r)=>{
				this.images.push(r);
			});
			// this.images.pop();
    },
    beforeRemove (index, done, fileList) {
      console.log('index', index, fileList)
      var r = confirm("remove image")
      if (r == true) {
        done()
      } else {
      }
    },
    editImage (formData, index, fileList) {
      console.log('edit data', formData, index, fileList)
		},
		exceedImageLimit(amount){
			alert("you selected "+amount+"files which exceed the limit")
		},

	},

	created() {
		this.showProperty(this.slug);
		//  window.addEventListener('beforeunload', this.showProperty(this.slug))
	},
  //       },
};
</script>
<style scoped>
  .image {
    float: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid #ebebeb;
    margin: 5px;
  }
	.addmore {
		margin: 5px;
  width: 150px;
  height: 150px;
	cursor:pointer;
  /* border: 1px solid black; */
	background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addmore :hover{
	background-color:grey;
}

.icon_button {
  /* font-size:40px;
	 */
	 color:#fff;
}
#desc{
	height: 180px;
	overflow: scroll;
}
#d_p{
	border-top: 3px solid #0F52BA;
	padding:20px;
}
.text-bold{
	font-weight:bold;
}
.info-item-icon{
	width:50px;
	height: 50px;
}
.p-image{
	background-size: contain;
	background-repeat:no-repeat;
	background-position:50%;
	width:100%;
	height:150px;
}
.padme{
	z-index: 200;
	color:red;
	position:relative;
	top:10;
	left:10;
	width:20px;
	height: 20px;
	margin-left:10px;
	margin-top:10px;
	cursor: pointer;
}
.owl-theme .owl-nav [class*='owl-'] {
    /* color: #FFF;
    position: relative;
    top: -180px ;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: #D6D6D6;
    display: inline-block;
    cursor: pointer; */

}

</style>
